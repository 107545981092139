/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

// import config from 'react-global-configuration';

// import { AuthContext } from "../../context/authContext";
import i18next from 'i18next';

import { Component } from 'react'

import { Button, OrangeryPrice } from '../../components';

import { Calendar, People } from "react-iconly";
class MeetingRoomCard extends Component {
  handlePickATimeButtonClick = (selectedMeetingRoom) => {
    if (this.props.onPickATimeButtonClick)
      this.props.onPickATimeButtonClick(1, selectedMeetingRoom)
  }

  render() {
    let { meetingRoom } = this.props

    const available = meetingRoom.time_slots.filter((timeSlot) => timeSlot.available).length > 0

    return (
      <div
        key={this.props.key}
        className={`relative text-left flex flex-col w-full h-auto space-y-4 pt-3 pb-3 border ${this.props.noButton ? "pb-0 border-[#EDEEF0] bg-[#F9F9FA]" : "pb-4 border-[#55585C] bg-white"} text-black rounded-md overflow-hidden select-none ${this.props.className ? this.props.className : ""}`}
      >
        <div className="flex flex-row justify-between items-start px-3">
          <div className={`flex flex-col justify-start ${this.props.selectedDate ? "" : "gap-3"}`}>
            <h3 className={`text-base font-bold text-gray-900 ${this.props.selectedDate ? "h-[56px]" : ""}`}>
              {meetingRoom.name}
            </h3>
            <div className='flex flex-row space-x-2 items-center mt-auto'>
              <div className='text-[#55585C]'>
                <People set='light' size={20} />
              </div>
              <div className='text-[#55585C] font-medium'>
                {i18next.t("Capacity")}:&nbsp;{meetingRoom.capacity}
              </div>
            </div>
          </div>

          <div className='flex flex-col justify-end text-right gap-1'>
            <div className='flex flex-col justify-end text-right'>
              <span className="text-2xl font-extrabold">
                <OrangeryPrice euro={meetingRoom.price} germanFormat />
              </span>
              <span className='text-gray-500 text-sm'>
                {i18next.t("per hour")}
              </span>
            </div>

            {this.props.selectedDate &&
              <div className='flex flex-row justify-end text-right space-x-2 text-gray-500 items-center'>
                <Calendar set="light" size={18} />
                <span className="text-base font-medium">
                  {this.props.selectedDate}
                </span>
              </div>
            }
          </div>
        </div>

        {meetingRoom.description &&
          <div className="flex flex-row justify-between items-start pt-3 border-t-[1px] px-3">
            <p className="text-sm font-normal text-[#55585C] break-all">
              {meetingRoom.description}
            </p>
          </div>
        }

        {meetingRoom.tags && Array.isArray(meetingRoom.tags) && meetingRoom.tags.length > 0 &&
          <div className="flex flex-wrap gap-2 px-3 pt-3 border-t-[1px]">
            {meetingRoom.tags.map((tag) => (
              <div className="px-3 py-0.5 bg-[#EDEEF0] rounded-full">
                <p className="text-sm font-semibold text-[#55585C]">
                  {tag.name}
                </p>
              </div>
            ))}
          </div>
        }

        {!this.props.noButton
          ? <div className={`flex flex-wrap flex-row items-center px-3 ${meetingRoom.tags && Array.isArray(meetingRoom.tags) && meetingRoom.tags.length > 0 ? "" : "pt-3 border-t-[1px]"}`}>
            <Button
              onClick={(e) => this.handlePickATimeButtonClick(meetingRoom)}
              className={`ml-auto flex justify-end px-3 h-10 ${available ? "cusrsor-pointer text-black bg-brand hover:bg-brand-900" : "cursor-not-allowed text-white bg-gray-500 hover:bg-gray-500"} font-normal text-sm rounded items-baseline`}
              text={<div className='flex items-center space-x-2'><span>{i18next.t(available ? "pick a time" : "unavailable")}</span></div>}
              disabled={!available}
            />
          </div>
          : <></>
        }
      </div>
    )
  }
}

export default MeetingRoomCard;