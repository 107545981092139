import { useEffect, useState } from "react";
import axios from "axios";
import closeIcon from "../../assets/images/icons/close.svg";
import calendarIcon from "../../assets/images/icons/calendar.svg";
import arrowRightIcon from "../../assets/images/icons/arrowRight.svg";
import locationIcon from "../../assets/images/icons/location-2.svg";
import CopyIcon from "../../assets/images/icons/chain.svg";
import CheckIcon from "../../assets/images/icons/circleCheck.svg";
import { ProfileAvatar, ProfileAvatarGroup, Carousel, Img } from "..";
import OrangeryPrice from "../utilities/OrangeryPrice";
import config from "react-global-configuration";
import { useTranslation } from "react-i18next";
const generals = require("../../generals");

export default function NewModal({ event, open, setOpen, eventResult = false, urlData }) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [afterSubmit, setAfterSubmit] = useState({
    firstName: "",
    lastName: "",
    email: "",
    attendees: [],
  });
  const [showError, setShowError] = useState("");
  const [activeSlide, setActiveSlide] = useState(0);
  const [submitError, setSubmitError] = useState("");

  function onCarouselSlideChange(activeSlide) {
    ////////!!!!!!!! not working!
    setActiveSlide(activeSlide);
    if (
      document.querySelector("#thumbnailsContainer") &&
      document.querySelector("#thumbnail" + activeSlide)
    )
      document.querySelector("#thumbnailsContainer").scrollLeft =
        Number.parseInt(activeSlide) *
        Number.parseInt(document.querySelector("#thumbnail" + activeSlide).offsetWidth);
  }

  function handleSubmit(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const regex =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let valid = false;

    if (firstName === "") {
      setShowError("firstName");
      return;
    } else if (lastName === "") {
      setShowError("lastName");
      return;
    } else if (!regex.test(email)) {
      setShowError("email");
      return;
    } else if (email === "") {
      return;
    }
    valid = true;

    if (valid) {
      let url = `${config.get("osApiUrl")}/event/${event.uuid}`;

      let data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
      };

      axios
        .post(url, data, {})
        .then((res) => {
          if (res.data.success === true) {
            setSubmitted(true);
            setAfterSubmit({
              firstName: firstName,
              lastName: lastName,
              email: email,
              attendees: res.data.data.event.attendees,
            });
            setFirstName("");
            setLastName("");
            setEmail("");
            setShowError(false);
          }
        })
        .catch((err) => {
          setSubmitError(err.response.data.msg);
        });
    }
  }

  useEffect(() => {
    if (eventResult) {
      setSubmitted(true);
      setAfterSubmit({
        firstName: urlData.name,
        email: urlData.email,
        attendees: event.attendees,
      });
    } else {
      setSubmitted(false);
      setAfterSubmit({ firstName: "", lastName: "", email: "" });
    }
    // eslint-disable-next-line
  }, []);

  return open ? (
    <div className="flex justify-center bg-modalBackground items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[101] outline-none focus:outline-none">
      <div className="relative w-[300px] md:w-[900px] my-0 md:my-6 mx-auto z-[102] opacity-100">
        <div className="relative items-start flex flex-col w-full bg-white outline-none focus:outline-none rounded-[5px] px-[30px] pt-2 md:pt-[30px] pb-[10px]">
          <div className="flex w-full items-center justify-between mb-7">
            <h3 className="text-[15px] md:text-[21px] font-bold">{event?.title}</h3>
            <button
              onClick={(e) => {
                setOpen(false);
                setAfterSubmit({ firstName: "", lastName: "", email: "" });
                setSubmitted(false);
              }}
              className="rounded-full w-[32px] bg-fourthGray h-[32px] flex justify-center items-center"
            >
              <img className="h-3 w-3" src={closeIcon} alt="close Icon" />
            </button>
          </div>
          <div className="flex flex-col md:flex-row gap-x-4 w-full">
            {event.images && Array.isArray(event.images) && event.images.length > 0 && (
              <div className="w-auto md:w-[390px] h-[100px] md:h-[355px] mb-4 md:mb-0">
                <Carousel
                  activeSlide={activeSlide}
                  onSlideChange={onCarouselSlideChange}
                  slides={
                    event.images && Array.isArray(event.images) && event.images.length > 0
                      ? event.images.map((image, index) => (
                          <Img
                            key={index}
                            className="block object-cover rounded-[5px] w-[390px] h-[100px] md:h-[355px]"
                            src={image.uuid ? `${config.get("cdnUrl")}/${image.uuid}.jpg` : ""}
                            alt=""
                          />
                        ))
                      : []
                  }
                />
              </div>
            )}

            <div
              className={`flex flex-col items-start ${
                event.images?.length > 0 ? `w-[unset]` : `w-full`
              }`}
            >
              <div
                className={`text-[13px] mb-6 text-left leading-[15.5px] font-normal text-tertiaryBlack ${
                  event.images?.length > 0 ? `w-auto md:w-[437px]` : `w-full`
                }  h-[64px] overflow-y-auto`}
              >
                {event.description ?? "-"}
              </div>
              <div className="flex gap-x-2 items-center mb-[15px] pb-3 border-b-2 w-full">
                <img className="w-[16px] h-[18px]" src={calendarIcon} alt="calendar Icon" />
                <div className="text-[14px] font-semibold flex items-center gap-x-1">
                  {new Date(event.start_time).toLocaleString("de-DE", {
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hourCycle: "h24",
                  })}{" "}
                  <img className="w-[14px] h-[9px]" src={arrowRightIcon} alt="arrow right" />{" "}
                  {new Date(event.end_time).toLocaleString("de-DE", {
                    ...(new Date(event.start_time).toLocaleString("de-DE", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    }) !==
                    new Date(event.end_time).toLocaleString("de-DE", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    })
                      ? {
                          month: "long",
                          year: "numeric",
                          day: "numeric",
                        }
                      : {}),
                    // month: "long",
                    // year: "numeric",
                    // day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hourCycle: "h24",
                  })}
                </div>
              </div>
              <div className="flex gap-x-2 pb-3 border-b-2 w-full mb-[15px] justify-between items-center">
                <div className="flex gap-x-2 items-center">
                  <img className="w-[14px] h-[18px]" src={locationIcon} alt="location" />
                  <div className="flex flex-col items-start">
                    <div className="text-[14px] font-semibold">{event.office_name}</div>
                    {event.location ? (
                      <div className="text-[12px] font-normal text-tertiaryGray">
                        {t("Address")}: {event.location ?? "-"}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {event.event_link && event.event_link !== undefined ? (
                  <button className="w-[32px] h-[32px] bg-secondaryBlack rounded-full flex justify-center items-center">
                    <a href={`${event.event_link}`} target="_blank" rel="noreferrer">
                      <img className="h-3 w-4" src={CopyIcon} alt="chain Icon" />
                    </a>
                  </button>
                ) : (
                  <></>
                )}
              </div>
              {event.organizer?.name ? (
                <div className="pb-3 border-b-2 w-full mb-[15px] flex justify-between items-center">
                  {event.organizer?.name ? (
                    <div className="flex items-center gap-x-[10px] ">
                      <ProfileAvatar
                        name={event.organizer?.name}
                        image={event.organizer?.image}
                        size="small"
                      />
                      <div className="flex flex-col items-start">
                        <div className="text-[9px] font-semibold text-tertiaryGray">
                          {t("Organized by")}
                        </div>
                        <div className="text-[16px] font-semibold text-secondaryBlack">
                          {event.organizer?.name}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {event.organizer_media ? (
                    <button className="w-[32px] h-[32px] bg-secondaryBlack rounded-full flex justify-center items-center">
                      <a href={`${event.organizer_media}`} target="_blank" rel="noreferrer">
                        <img className="h-3 w-4" src={CopyIcon} alt="chain Icon" />
                      </a>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {event.presenters && event.presenters.length > 0 ? (
                <div className="flex items-center justify-between pb-3 mb-[15px] border-b-2 w-full">
                  <div className="text-[14px] font-semibold">{t("Presenters")}</div>
                  <ProfileAvatarGroup size="medium" users={event.presenters} />
                </div>
              ) : (
                <></>
              )}
              {event.attendees && event.attendees.length > 0 ? (
                <div className="flex items-center justify-between pb-3 w-full mb-8">
                  <div className="text-[14px] font-semibold">{t("Attendees")}</div>
                  {submitted ? (
                    <ProfileAvatarGroup size="medium" users={afterSubmit.attendees} />
                  ) : (
                    <ProfileAvatarGroup size="medium" users={event.attendees} />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            className={`flex  md:flex-row items-center justify-between w-full ${
              event.price === 0 ? `flex-col` : `flex-row`
            }`}
          >
            {submitted === false ? (
              event.price === 0 && (
                <form className="flex gap-x-2">
                  <div className="mb-4 text-left">
                    <label
                      className="block text-gray-700 text-[11px] font-semibold mb-1"
                      for="First Name"
                    >
                      First Name
                    </label>
                    <input
                      className="shadow mb-1 appearance-none border rounded w-full md:max-w-[180px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="First Name"
                      type="text"
                      required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      // placeholder="First Name"
                    />
                    {showError === "firstName" && (
                      <p className="text-gray-700 text-[11px]">Please enter your first name</p>
                    )}
                  </div>
                  <div className="mb-4 text-left">
                    <label
                      className="block text-gray-700 text-[11px] font-semibold mb-1"
                      for="Last Name"
                    >
                      Last Name
                    </label>
                    <input
                      className="shadow mb-1 appearance-none border rounded w-full md:max-w-[180px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="Last Name"
                      type="text"
                      required
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      // placeholder="Last Name"
                    />
                    {showError === "lastName" && (
                      <p className="text-gray-700 text-[11px]">Please enter your last name</p>
                    )}
                  </div>
                  <div className="mb-4 text-left">
                    <label
                      className="block text-gray-700 text-[11px] font-semibold mb-1"
                      for="email"
                    >
                      Email
                    </label>
                    <input
                      className="shadow mb-1 appearance-none border rounded w-full md:max-w-[180px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      // placeholder="email"
                    />
                    {showError === "email" && (
                      <p className="text-gray-700 text-[11px]">
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                </form>
              )
            ) : (
              <div className="flex gap-x-2 items-center">
                <img className="h-8 w-8" src={CheckIcon} alt="check Icon" />
                <div className="flex flex-col items-start">
                  <div className="text-[19px] font-semibold text-secondaryBlack">
                    {afterSubmit.firstName} {afterSubmit.lastName}
                  </div>
                  <div className="text-[14px] font-normal text-tertiaryGray">
                    {afterSubmit.email}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`flex items-center gap-x-[10px] justify-end ${
                event.price !== 0 && `w-full`
              }`}
            >
              <div className="text-[19px] font-bold text-secondaryBlack">
                {event.price === 0 ? "Free" : <OrangeryPrice euro={event.price} germanFormat />}
              </div>
              {event.price === 0 ? (
                <button
                  disabled={submitted}
                  onClick={handleSubmit}
                  className={`${
                    submitted
                      ? `bg-white text-primaryGreen border-2 border-primaryGreen`
                      : "bg-secondaryBlack text-white"
                  } w-[115px] h-[42px] text-[17px] font-bold rounded-[45px] self-end`}
                >
                  {submitted ? t("Attended") : t("Attend")}
                </button>
              ) : (
                <button
                  onClick={() => {
                    generals.goTo(
                      `${config.get("osCheckoutUrl")}/event/?id=${event.uuid}&returnUrl=${
                        window.location.href
                      }`
                    );
                  }}
                  className={`${
                    eventResult
                      ? `bg-white text-primaryGreen border-2 border-primaryGreen`
                      : "bg-secondaryBlack text-white"
                  } w-[115px] h-[42px] text-[17px] font-bold rounded-[45px] self-end md:mt-0`}
                >
                  {eventResult ? t("Attended") : t("Book")}
                </button>
              )}
            </div>
          </div>
          {submitError && <div className="mx-auto mt-2 md:mt-0">{submitError}</div>}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
