import config from 'react-global-configuration';

import { JustifyBetween } from '..'

import { ChevronRight } from "react-iconly";

export default function LocationCard(props) {
  const { location } = props

  return (
    <a
      className="group flex-none min-w-80 min-h-24 bg-white p-4 rounded-xl shadow-lg cursor-pointer block"
      href={`${config.get('homeUrl')}/location/${location.id}`}
      target="_blank"
      rel="noreferrer"
    >
      <JustifyBetween
        left={<div className='flex flex-col space-y-3'>
          <h2 className="text-xl font-bold text-gray-900 mr-5">
            {location.name}
          </h2>
          <p className="text-sm font-normal text-gray-600 mr-5">{location.address}</p>
        </div>}
        right={
          <div className="flex h-full mx-2 text-gray-400 hover:text-gray-700">
              <ChevronRight set="light" size={28} className="my-auto"/>
          </div>
        }
      />
    </a>
  )
}