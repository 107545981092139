import { ProfileAvatar } from "../index";

export default function ProfileAvatarGroup({ users, size }) {
  return (
    <div className="isolate flex -space-x-3 overflow-hidden">
      {users?.slice(0, users.length <= 7 ? users.length : 7).map((user) => {
        return (
          <ProfileAvatar
            key={user.uuid}
            name={user.name}
            image={user.image}
            size={size}
            useInGroup={true}
          />
        );
      })}
      {users && users.length > 7 && (
        <div
          className={`relative inline-flex ${
            size === "small" ? `w-[24px] h-[24px]` : `w-[30px] h-[30px]`
          } items-center justify-center overflow-hidden bg-[#EDEEF0] rounded-full  ring-2 ring-white`}
        >
          <span className={`font-medium text-sm text-tertiaryBlack`}>{`+${users.length - 7}`}</span>
        </div>
      )}
    </div>
  );
}
