/* eslint-disable jsx-a11y/anchor-is-valid */
/*
  This example requires Tailwind CSS v2.0+ 
*/

// contexts
import config from "react-global-configuration";
import { useTranslation } from "react-i18next";

import { useState } from "react";

import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import {
  LogoWithoutSlogan,
  ChangeLanguageButton,
  // Img,
} from "../../components";

import {
  // MenuIcon,
  XIcon,
} from "@heroicons/react/outline";

import { MenuIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { ModalContainer } from "../../components";

// const generals = require("../../generals")

// const navigation = [
//   // { name: t('Coworking'), href: '#' },
//   // { name: t('Meeting room'), href: '#' },
//   // { name: t('Blog'), href: '#' },
//   // { name: t('Contact'), href: '#' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header(props) {
  // const { user } = useContext(AuthContext)
  const { t } = useTranslation();

  const [calculatorModalShow, setCalculatorModalShow] = useState(false);
  function toggleCalculatorModalShow(show) {
    setCalculatorModalShow(show);
  }

  return (
    <>
      <ModalContainer
        id="modal"
        show={calculatorModalShow}
        toggleShow={toggleCalculatorModalShow}
        className={""}
        containerClassName={"overflow-hidden p-0 w-[800px] h-[400px]"}
        title={t("orangery Calculator")}
      >
        <iframe
          title={"Calculator"}
          style={{ border: 0 }}
          src={
            "https://calculator-staging.orangeryos.de/costquicky/form2embedded/"
          }
          width="800"
          height="400"
          allowFullScreen=""
          loading="lazy"
        />
      </ModalContainer>

      <Popover className="bg-eighth fixed top-0 z-[100] w-full">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10 mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl  h-22 md:h-22">
          <div className="my-auto">
            <LogoWithoutSlogan white={true} />
          </div>
          <div className="-mr-2 -my-2 md:hidden flex flex-row gap-2 items-center">
            <div>
              <ChangeLanguageButton mode="white" />
            </div>
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
              <span className="sr-only">{t("Open menu")}</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-500" : "text-gray-500",
                        "group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-white"
                      )}
                    >
                      <span>{t("Locations")}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-500" : "text-gray-500",
                          "ml-2 h-5 w-5 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                        <div className="rounded-lg bg-mainBackground p-4 max-w-[900px] shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <ul className="flex flex-wrap w-full">
                            {props.locationOptions &&
                              props.locationOptions.map((item) => (
                                <li
                                  key={item.label}
                                  className="flex basis-full md:basis-1/2 lg:basis-1/3 p-2 h-24"
                                >
                                  <a
                                    href={`${config.get("homeUrl")}/location/${item.value}`}
                                    className={`rounded-md ${props.selectedLocation?.locationName ===
                                      item.value
                                      ? "bg-openDays text-mainBackground"
                                      : "bg-mainBackground text-sixth"
                                      } hover:bg-openDays hover:text-mainBackground border-2 border-openDays flex place-items-center justify-center text-center w-full h-full text-base font-medium  my-auto`}
                                    role="button"
                                  >
                                    {item.label}
                                  </a>
                                </li>
                              ))}
                            <li className="flex basis-full md:basis-1/2 lg:basis-1/3 p-2 h-24 bg-mainBackground">
                              <div className="rounded-md border-2 border-openDays p-4 flex place-items-center justify-center text-center w-full h-full text-base font-medium text-sixth my-auto">
                                {t("Coming soon")}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-500" : "text-gray-500",
                        "group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-white"
                      )}
                    >
                      <span>{t("Events")}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-500" : "text-gray-500",
                          "ml-2 h-5 w-5 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="h-[200px] absolute z-10 -ml-4 mt-3 transform w-screen max-w-sm lg:max-w-sm">
                        <div className="h-full rounded-lg bg-mainBackground p-4 max-w-[900px] shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <ul className="flex flex-col w-full h-full">
                            <li className="flex p-2 h-24">
                              <a
                                href={"https://blog.orangery.io/events/"}
                                className={`rounded-md hover:bg-openDays hover:text-mainBackground border-2 border-openDays flex place-items-center justify-center text-center w-full h-full text-base font-medium  my-auto`}
                                role="button"
                              >
                                {t("Our Events")}
                              </a>
                            </li>
                            <li className="flex p-2 h-24">
                              <a
                                href={"https://blog.orangery.io/event-locations/"}
                                className={`rounded-md hover:bg-openDays hover:text-mainBackground border-2 border-openDays flex place-items-center justify-center text-center w-full h-full text-base font-medium  my-auto`}
                                role="button"
                              >
                                {t("Event-Location")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href={`${config.get("homeUrl")}/news/list`}
                className="text-base font-medium text-gray-500 hover:text-white"
              >
                {t("News")}
              </a>
              <a
                href={`https://blog.orangery.io/`}
                className="text-base font-medium text-gray-500 hover:text-white cursor-pointer"
              >
                {t("Blog")}
              </a>
              {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {config.get("buildEnv") !== "production" && (
                <a
                  href="#"
                  className="text-base font-medium text-gray-500 hover:text-white"
                  onClick={(e) => {
                    if (e) e.preventDefault();
                    setCalculatorModalShow(true);
                  }}
                >
                  {t("Calculator")}
                </a>
              )}
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-500" : "text-gray-500",
                        "group bg-transparent rounded-md inline-flex items-center text-base font-medium hover:text-white"
                      )}
                    >
                      <span>{t("Specials")}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-500" : "text-gray-500",
                          "ml-2 h-5 w-5 group-hover:text-white"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-sm lg:max-w-sm">
                        <div className="h-full rounded-lg bg-mainBackground p-4 max-w-[900px] shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <ul className="flex flex-col w-full h-full">
                            <li className="flex p-2 h-24">
                              <a
                                href={`${config.get(
                                  "homeUrl"
                                )}/coworking-festival`}
                                className={`rounded-md hover:bg-openDays hover:text-mainBackground border-2 border-openDays flex place-items-center justify-center text-center w-full h-full text-base font-medium  my-auto`}
                                role="button"
                              >
                                Coworking Festival
                              </a>
                            </li>
                            {/* <li className="flex basis-full md:basis-1/2 lg:basis-1/3 p-2 h-24">
                              <a
                                href={`${config.get("homeUrl")}/oster-Special`}
                                className={`rounded-md hover:bg-openDays hover:text-mainBackground border-2 border-openDays flex place-items-center justify-center text-center w-full h-full text-base font-medium  my-auto`}
                                role="button"
                              >
                                Oster-Special
                              </a>
                            </li> */}
                            {/* <li className="flex p-2 h-24">
                              <a
                                href={`${config.get("homeUrl")}/open-days`}
                                className={`rounded-md hover:bg-openDays hover:text-mainBackground border-2 border-openDays flex place-items-center justify-center text-center w-full h-full text-base font-medium  my-auto`}
                                role="button"
                              >
                                Open Days
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>

            {/* <a
              href={`${config.get("homeUrl")}/open-days`}
              className="text-sm font-semibold text-mainBackground bg-openDays hover:bg-openDays flex flex-row gap-1 items-baseline rounded-full py-1 pr-1 pl-2 mr-auto ml-8 h-[30px]"
              role="button"
            >
              <span>Open Days</span>
              <ArrowRightIcon
                className="h-4 w-4 mr-1 self-center"
                aria-hidden="true"
              />
              <span className="bg-white text-eighth px-1 ml-auto text-[11px] rounded-full h-[20px] flex items-center">
                NEW
              </span>
            </a>
            <a
              href={`${config.get("homeUrl")}/oster-Special`}
              className="text-sm font-semibold text-mainBackground bg-openDays hover:bg-openDays flex flex-row gap-1 items-baseline rounded-full py-1 pr-1 pl-2 mr-24 h-[30px]"
              role="button"
            >
              <span>Oster-Special</span>
              <ArrowRightIcon
                className="h-4 w-4 mr-1 self-center"
                aria-hidden="true"
              />
              <span className="bg-white text-eighth px-1 ml-auto text-[11px] rounded-full h-[20px] flex items-center">
                NEW
              </span>
            </a> */}
            {!props.noRightButtons ? (
              <div className="flex place-items-center z-30 py-0 pl-6 sm:pl-6 space-x-2">
                <div>
                  <ChangeLanguageButton mode="white" />
                </div>
                {/* <ButtonMenu
                  className="ml-3 relative"
                  srOnlyTitle={t("Open user menu")}
                  button={<Avatar
                    className="h-10 md:h-14 w-10 md:w-14 focus:outline-none"
                    // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    src={user && user.file && user.file.filename
                      ? `${config.get('cdnUrl')}/${user.file.filename}`
                      : ""
                    }
                    size={32}
                    onClick={() => {
                      generals.goTo(`${config.get('appUrl')}/dashboard`, 0)
                    }}
                  />
                  }
                /> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <div className="flex justify-center">
          <a
            href={`${config.get("homeUrl")}/open-days`}
            className="flex sm:hidden text-sm font-semibold text-mainBackground bg-openDays hover:bg-openDays gap-1 items-center rounded-full py-1 pr-1 pl-2 mx-2 mb-6 mt-4"
            role="button"
          >
            <span>Open Days</span>
            <ArrowRightIcon className="h-3 w-4 mr-1" aria-hidden="true" />
            <span className="bg-white text-eighth px-1 ml-auto text-[11px] rounded-full">
              NEW
            </span>
          </a>
          <a
            href={`${config.get("homeUrl")}/oster-Special`}
            className="flex sm:hidden text-sm font-semibold text-mainBackground bg-openDays hover:bg-openDays gap-1 items-center rounded-full py-1 pr-1 pl-2 mx-2 mb-6 mt-4"
            role="button"
          >
            <span>Oster-Special</span>
            <ArrowRightIcon className="h-3 w-4 mr-1" aria-hidden="true" />
            <span className="bg-white text-eighth px-1 ml-auto text-[11px] rounded-full">
              NEW
            </span>
          </a>
        </div> */}
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <LogoWithoutSlogan />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                      <span className="sr-only">{t("Close menu")}</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-4">
                    <div>
                      <a
                        href="#"
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setCalculatorModalShow(true);
                        }}
                      >
                        {t("Locations")}
                      </a>
                      <ul className="flex flex-wrap w-full gap-2">
                        {props.locationOptions &&
                          props.locationOptions.map((item, index) => (
                            <div className="flex">
                              <li key={item.label} className="flex p-2">
                                <a
                                  href={`${config.get("homeUrl")}/location/${item.value
                                    }`}
                                  className="text-base font-medium text-sixth"
                                  role="button"
                                >
                                  {item.label}
                                </a>
                              </li>
                              {index !== props.locationOptions.length - 1 && (
                                <li
                                  key={item.label}
                                  className="flex py-2 pl-2 text-gray-300"
                                >
                                  &#9679;
                                </li>
                              )}
                            </div>
                          ))}
                      </ul>
                    </div>

                    <div>
                      <a
                        href={`#`}
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                      >
                        {t("Events")}
                      </a>

                      <ul className="flex flex-wrap w-full gap-1">
                        <div className="flex">
                          <li className="flex p-2">
                            <a
                              href={"https://blog.orangery.io/events/"}
                              className="text-base font-medium text-sixth"
                              role="button"
                            >
                              {t("Our Events")}
                            </a>
                          </li>
                          <li className="flex py-2 pl-2 text-gray-300">
                            &#9679;
                          </li>
                        </div>
                        <div className="flex">
                          <li className="flex p-2">
                            <a
                              href={"https://blog.orangery.io/event-locations/"}
                              className="text-base font-medium text-sixth"
                              role="button"
                            >
                              {t("Event-Location")}
                            </a>
                          </li>
                        </div>
                      </ul>
                    </div>

                    <a
                      href={`${config.get("homeUrl")}/news/list`}
                      className="text-base font-medium text-gray-500 hover:text-gray-900"
                    >
                      {t("News")}
                    </a>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {/* {config.get("buildEnv") !== "production" && (
                      <a
                        href="#"
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                        onClick={(e) => {
                          if (e) e.preventDefault();
                          setCalculatorModalShow(true);
                        }}
                      >
                        {t("Calculator")}
                      </a>
                    )} */}
                    <div>
                      <a
                        href={`#`}
                        className="text-base font-medium text-gray-500 hover:text-gray-900"
                      >
                        {t("Specials")}
                      </a>
                      <ul className="flex flex-wrap w-full gap-1">
                        <div className="flex">
                          <li className="flex p-2">
                            <a
                              href={`${config.get(
                                "homeUrl"
                              )}/coworking-festival`}
                              className="text-base font-medium text-sixth"
                              role="button"
                            >
                              Coworking Festival
                            </a>
                          </li>
                          <li className="flex py-2 pl-2 text-gray-300">
                            &#9679;
                          </li>
                        </div>
                        {/* <div className="flex">
                          <li className="flex p-2">
                            <a
                              href={`${config.get("homeUrl")}/oster-Special`}
                              className="text-base font-medium text-sixth"
                              role="button"
                            >
                              Oster-Special
                            </a>
                          </li>
                          <li className="flex py-2 pl-2 text-gray-300">
                            &#9679;
                          </li>
                        </div> */}
                        <div className="flex">
                          <li className="flex p-2">
                            <a
                              href={`${config.get("homeUrl")}/open-days`}
                              className="text-base font-medium text-sixth"
                              role="button"
                            >
                              Open Days
                            </a>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}
