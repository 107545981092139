/*
 This example requires Tailwind CSS v2.0+
*/

import getlocations from "../data/getlocations.json";

// contexts
// import { AuthContext } from "../context/authContext";

import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import { Img } from "../components";

import { Header, Footer } from "../implementedComponents";

import firstSectionImage from "../assets/images/privacy-policy/datenschutz.jpg";

const generals = require("../generals");

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    // let getRequested = false;
    // if (!getRequested) {
    //   getRequested = true;

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       // 'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //         // config.set({ locationOptions: tempList }, { freeze: false, environment: 'notFreeze' })
    //         // config.setEnvironment('notFreeze');
    //       }
    //     })
    //     // .catch((error) => {
    //     //   config.set("locationOptions", [])
    //     // })
    //     .then(function () {
    //       // always executed
    //     });
    // }
  }

  useEffect(() => {
    loadLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-28 sm:pt-16 md:pt-22 pb-0 lg:pb-0">
              <div className="mx-0 px-0">
                <Img className="z-20 w-full h-auto object-cover" src={firstSectionImage} alt="" />
              </div>
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    {t("Privacy Policy")}
                  </h1>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Angaben gemäß § 5 TMG
                  </h2>
                  <strong>orangery Coworking GmbH</strong>
                  <br />
                  Am Alten Wasserwerk 1<br />
                  31135 Hildesheim
                  <br />
                  <br />
                  <p className="mb-3">
                    Steuernummer: 30/211/30900
                    <br />
                    Registernummer: HRB 204880 <br />
                    Registergericht: Amtsgericht Hildesheim
                    <br />
                    Geschäftsführung: Dominik Groenen
                    <br />
                    Telefonnummer: <a href={`tel:0800 005 50 77`}>0800 005 50 77</a>
                    <br />
                    E-Mail-Adresse: <a href={`mailto:contact@orangery.io`}>contact@orangery.io</a>
                    <br />
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Datenschutzbeauftragter:
                  </h2>
                  <p className="mb-3">
                    Unseren Datenschutzbeauftragten erreichen Sie unter{" "}
                    <a href={`mailto:contact@orangery.io`}>contact@orangery.io</a>
                  </p>
                  <p className="mb-3">Stand: 24.02.2020</p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Rechtsgrundlage für unsere Dienste und grundsätzliche Informationen zur Nutzung
                    und Weitergabe von Daten.
                  </h2>
                  <p className="mb-3">
                    Die Informationen in dieser Datenschutzerklärung dienen der Aufklärung über den
                    Zweck, Umfang und die Art der Verarbeitung Ihrer personenbezogenen Daten
                    innerhalb unseres gesamten Onlineangebotes und aller damit verbundenen
                    Webseiten, inklusive ihrer Funktionen und Inhalte (im Folgenden zusammenfassend
                    bezeichnet als „Webseite“ oder „Onlineangebot“). Diese Erklärung gilt für alle
                    Plattformen und Geräte (z. B. Mobilgeräte oder Desktop-PC), auf denen unser
                    Onlineangebot genutzt oder ausgeführt wird, unabhängig von den verwendeten
                    Domains oder Systemen. Diese Auskunft geschieht nach Maßgabe des Art. 13 DSGVO.
                  </p>
                  <p className="mb-3">
                    Die genutzten Begriffe, wie: „personenbezogene Daten“ oder deren „Verarbeitung“
                    werden in den Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO)
                    erklärt.
                  </p>
                  <p className="mb-3">
                    Die verwendeten Begrifflichkeiten, wie z.B. „Nutzer“, „Kunde“ oder
                    „Diensteanbieter“ sind geschlechtsneutral zu verstehen. Alle personenbezogenen
                    Nutzerdaten werden unter Einhaltung der einschlägigen Datenschutzbestimmungen
                    verarbeitet. Grundlage hierfür ist das Vorliegen einer gesetzlichen Erlaubnis
                    und die Einwilligung des Nutzers. Ist die Datenverarbeitung für die Erbringung
                    unserer vertraglichen Leistungen (z. B. Auftragsbearbeitung) oder des
                    Online-Services (z. B. zur Sicherstellung und Einhaltung gesetzlicher
                    Vorschriften), oder auch aufgrund unseres berechtigten Interesses (z. B. für die
                    Sicherheit unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO,
                    Analyse zur Optimierung der Sicherheit und Wirtschaftlichkeit unseres Betriebes,
                    inkl. Profilerstellung zu Werbe- und Marketingzwecken, Erhebung von Reichweiten-
                    und Zugriffsdaten und Drittanbieterdienste) erforderlich, werden wir die Daten
                    im Rahmen der gesetzlichen Erlaubnis nutzen.
                  </p>
                  <p className="mb-3">
                    Art. 6 Abs. 1 lit. a. und Art. 7 DSGVO bilden die Rechtsgrundlage für die
                    Einwilligungen, Art. 6 Abs. 1 lit. b. DSGVO dient als Rechtsgrundlage für die
                    Verarbeitung zur Vertrags- und Leistungserbringung. Rechtsgrundlage für die
                    Verarbeitung der Daten zur Erfüllung unserer rechtlichen Verpflichtungen ist
                    Art. 6 Abs. 1 lit. c. DSGVO, und als Grundlage für die Verarbeitung der Daten
                    zur Wahrung unserer berechtigten Interessen dient Art. 6 Abs. 1 lit. f. DSGVO.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Weitergabe von Daten an Dritte und Drittanbieter
                  </h2>
                  <p className="mb-3">
                    Eine Weitergabe von Daten an Dritte erfolgt ausschließlich im Rahmen
                    gesetzlicher Vorgaben. Sie erfolgt nur, wenn dies für die
                    Vertragszwecke/-erfüllung erforderlich ist (nach Art. 6 Abs. 1 lit. b) DSGVO),
                    oder aufgrund berechtigter Interessen an unserem wirtschaftlichen und effektiven
                    Geschäftsbetrieb (gem. Art. 6 Abs. 1 lit. f. DSGVO).
                  </p>
                  <p className="mb-3">
                    Um den gesetzlichen Vorschriften zu genügen und für den Schutz der
                    personenbezogenen Daten, ergreifen wir auch bei Einsatz von Subunternehmern
                    geeignete rechtliche, technische und organisatorische Maßnahmen. Werden
                    Drittanbieterdienste, -werkzeuge oder sonstige Mittel eingesetzt, und befindet
                    sich der genannte Sitz dieses Anbieters in einem Drittland, ist auch ein
                    Datentransfer in dieses Land wahrscheinlich. Die DSGVO ist eine EU-Verordnung
                    und gilt ohnehin für alle Mitgliedsstaaten. Die Übermittlung in Länder außerhalb
                    der EU, bzw. des Europäischen Wirtschaftsraums erfolgt nur mit gesetzlicher
                    Erlaubnis, Einwilligung der Nutzer, oder bei einem angemessenen
                    Datenschutzniveau im jeweiligen Drittstaat. In folgenden Abschnitten weisen wir
                    diese Drittanbieter aus.
                  </p>
                  <p className="mb-3">
                    Unsere Webseiten und unser E-Mail Dienst werden bei unserem Schwesterunternehmen
                    der Synatix GmbH (Deisterstraße 20, 31785 Hameln, Deutschland) und dem
                    Co-Location anbieter Hetzner Online GmbH (Industriestr. 25, 91710 Gunzenhausen,
                    Deutschland) gehostet (sog. „Hoster“). Personenbezogenen Daten, die auf unseren
                    Webseiten erfasst werden, werden auf den Servern unseres Hosters gespeichert.
                    Hierbei kann es sich v.a. um IP-Adressen, Kontaktanfragen, Meta- und
                    Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und
                    sonstige Daten, die über eine Webseite generiert werden, handeln.
                  </p>
                  <p className="mb-3">
                    {" "}
                    Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber
                    unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im
                    Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres
                    Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f
                    DSGVO).
                  </p>
                  <p className="mb-3">
                    {" "}
                    Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung
                    seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf
                    diese Daten befolgen. Um die datenschutzkonforme Verarbeitung zu gewährleisten,
                    haben wir einen Vertrag über Auftragsverarbeitung mit unserem
                    Hosting-Dienstleister geschlossen.
                  </p>
                  <p className="mb-3">
                    Wir geben Ihre persönlichen Daten nicht an Dritte weiter und verkaufen sie auch
                    nicht.
                  </p>
                  <p className="mb-3">
                    Wir geben Informationen nur in den folgenden Fällen weiter:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>
                      wenn dies gesetzlich vorgeschrieben ist, z. B. um einer Vorladung oder einem
                      ähnlichen rechtlichen Verfahren nachzukommen
                    </li>
                    <li>
                      wenn wir in gutem Glauben davon ausgehen, dass die Offenlegung notwendig ist,
                      um unsere Rechte zu schützen, Ihre Sicherheit oder die Sicherheit anderer zu
                      schützen, um Betrug zu untersuchen oder um auf eine behördliche Anfrage zu
                      reagieren
                    </li>
                    <li>
                      wir an einer Fusion, einer Übernahme oder einem Verkauf aller oder eines Teils
                      der Vermögenswerte beteiligt sind, werden Sie per E-Mail und/oder durch einen
                      auffälligen Hinweis in unserer App über jede Änderung der
                      Eigentumsverhältnisse oder der Verwendung Ihrer personenbezogenen Daten sowie
                      über Ihre Wahlmöglichkeiten bezüglich Ihrer personenbezogenen Daten informiert
                    </li>
                    <li>an eine andere dritte Partei mit Ihrer vorherigen Zustimmung.</li>
                  </ul>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Maßnahmen für Schutz und Sicherheit
                  </h2>
                  <p className="mb-3">
                    Um die durch uns verarbeiteten Daten vor zufälliger oder vorsätzlicher
                    Manipulation, Zerstörung, Verlust oder Zugriffen unberechtigter Personen zu
                    schützen, und um den Vorschriften der Datenschutzgesetze zu entsprechen, treffen
                    wir technische, organisatorische und vertragliche Sicherheitsvorkehrungen gemäß
                    dem Stand der Technik.
                    <br />
                    Die verschlüsselte Übertragung von Daten zwischen unserem Server und Ihrem
                    Browser gehört zu den eingesetzten Sicherheitsmaßnahmen.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Erbringung vertraglicher Leistungen
                  </h2>
                  <p className="mb-3">
                    Zur Erfüllung unserer Vertrags- und Serviceleistungen verarbeiten wir
                    Bestandsdaten (z.B. Name und Anschrift sowie Kontaktdaten der Nutzer) und Daten
                    zu abgeschlossenen Verträgen (z.B. genutzte Leistungen, Informationen zu Zahlung
                    und Versand) gem. Art. 6 Abs. 1 lit b. DSGVO.
                  </p>
                  <p className="mb-3">
                    Zum Schutz vor missbräuchlicher oder unbefugter Nutzung sowie zur Wahrung
                    unserer berechtigten Interessen, speichern wir die IP-Adresse und Zeitpunkt des
                    Besuches. Grundsätzlich erfolgt keine Weitergabe dieser Daten an Dritte, als
                    Ausnahmen gelten die Verfolgung unserer Ansprüche oder eine gesetzliche
                    Verpflichtung nach Art. 6 Abs. 1 lit. c DSGVO.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Kontaktaufnahme durch den Nutzer
                  </h2>
                  <p className="mb-3">
                    Zur Bearbeitung von Nutzeranfragen (per Kontaktformular), werden die Angaben des
                    Nutzers gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Onlinepräsenzen in sozialen Medien
                  </h2>
                  <p className="mb-3">
                    Auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f.
                    DSGVO unterhalten wir Onlinepräsenzen auf sozialen Netzwerken und
                    Plattformen.Dort wird versucht mit Kunden, Interessenten und Nutzern zu
                    kommunizieren, ebenfalls wird dort über unsere Leistungen informiert.Beim Aufruf
                    der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die
                    Datenverarbeitungsrichtlinien der jeweiligen Betreiber.
                  </p>
                  <p className="mb-3">
                    Soweit diese Datenschutzerklärung eine weitere Verarbeitung der Daten nicht
                    angibt, werden die Daten der Nutzer, die mit uns kommunizieren oder mit unseren
                    Inhalten interagieren, verarbeitet.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Messung der Reichweite und Einsatz von Cookies
                  </h2>
                  <p className="mb-3">
                    Cookies sind kleine Dateien, die auf den Datenträgern der Nutzer gespeichert
                    werden.
                  </p>
                  <p className="mb-3">
                    Wir verwenden hauptsächlich Cookies (Session-Cookies), die bei Beendigung der
                    Browser-Sitzung wieder vom jeweiligen Speichermedium gelöscht werden.Session -
                    Cookies werden benötigt, um z.B. Warenkorb - Funktionen oder die Speicherung
                    Ihrer Eingaben über mehrere Seiten hinweg zu ermöglichen. Jedoch verwenden wir
                    daneben auch Cookies, die auf der Festplatte des Nutzers verbleiben.Dies
                    ermöglicht die automatische Erkennung des Nutzers bei einem erneuten Besuch und
                    die bevorzugten Eingaben und Einstellungen.Diese Cookies werden für die Dauer
                    von einem Monat bis 10 Jahren auf der Festplatte gespeichert und löschen sich
                    nach der vorgegebenen Zeit von allein.Diese Cookies dienen vornehmlich dazu,
                    dass Onlineangebot nutzerfreundlicher, sicherer und effektiver zu machen.
                  </p>
                  <p className="mb-3">
                    Wir informieren die Nutzer außerdem innerhalb dieser Datenschutzerklärung über
                    den Einsatz von Cookies im Rahmen pseudonymer Reichweitenmessung. Möchten Nutzer
                    die Speicherung von Cookies vermeiden, kann diese Option in den
                    Browsereinstellungen selbst deaktiviert werden.Bereits gespeicherte Cookies
                    können dort ebenfalls gelöscht werden, allerdings kann der Ausschluss von
                    Cookies zu Funktionseinschränkungen unseres Onlineangebotes führen. Ein
                    Widerspruch gegen den Einsatz von Cookies, die der Reichweitenmessung und zu
                    Werbezwecken dienen, kann über die Deaktivierungsseite der
                    Netzwerkwerbeinitiative (
                    <a href="http://optout.networkadvertising.org" target="_blank" rel="noreferrer">
                      http: //optout.networkadvertising.org
                    </a>
                    ), die europäische Webseite{" "}
                    <a
                      href="http://www.youronlinechoices.com/uk/your-ad-choices"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      http: //www.youronlinechoices.com/uk/your-ad-choices
                    </a>
                    und zusätzlich die US - amerikanische Webseite(
                    <a href="http://www.aboutads.info/choices" target="_blank" rel="noreferrer">
                      http: //www.aboutads.info/choices
                    </a>
                    ) erfolgen.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Erhebung von Zugriffsdaten und Aufzeichnungen (Logfiles)
                  </h2>
                  <p className="mb-3">
                    Über jeden Zugriff auf unsere Server erheben wir in unserem berechtigten
                    Interesse im Sinne des Art. 6 Abs. 1 lit.f.DSGVO, entsprechende Daten(sog.Server
                    - Logfiles), inkl.Datum und Uhrzeit, Datenmenge, Name der aufgerufenen Webseite,
                    Erfolgsmeldung über den Abruf, das Betriebssystem inkl.Browsertyp und –version,
                    die zuvor besuchte Webseite, die IP - Adresse und des Providers. Für die
                    Aufklärung von Betrug oder Missbrauch werden die Logfile - Informationen aus
                    Sicherheitsgründen für maximal sieben Tage gespeichert und danach gelöscht.Sind
                    bestimmte Daten für Beweiszwecke notwendig, wird die Löschung bis zur
                    endgültigen Klärung des Vorfalls aufgeschoben.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Google Kalender-Integration
                  </h2>
                  <p className="mb-3">
                    Diese Datenschutzerklärung beschreibt, wie Orangerie (orangeryOS) die
                    Informationen sammelt und verwendet, die Sie in Ihrem Google-Kalender-Konto über
                    den Orangerie-Abschnitt "Google-Kalender-Integration" in der "App" angeben.
                    Außerdem wird beschrieben, welche Wahlmöglichkeiten Sie in Bezug auf die
                    Verwendung Ihrer Daten haben und wie Sie auf diese Daten zugreifen und sie
                    aktualisieren können.
                  </p>
                  <h3 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Erfassung und Verwendung
                  </h3>
                  <p className="mb-3">
                    Wir erfassen die folgenden personenbezogenen Daten aus Ihrem
                    Google-Kalender-Konto:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>Name des Ereignisses</li>
                    <li>Datum des Termins</li>
                    <li>Uhrzeit des Ereignisses</li>
                    <li>Beschreibungdes Termins</li>
                    <li>Ort der Veranstaltung</li>
                  </ul>
                  <p className="mb-3">
                    Wir fügen/importieren die folgenden persönlichen Informationen aus Ihrem
                    orangeryOS-Konto in Ihren Google-Kalender:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>Name des Termins</li>
                    <li>Datum des Termins</li>
                    <li>Uhrzeit des Termins</li>
                    <li>Beschreibung des Termins</li>
                  </ul>
                  <p className="mb-3">
                    Wir entfernen auch nur die orangeryOS-Veranstaltungen im Falle einer
                    Veranstaltungsabsage.
                  </p>
                  <p className="mb-3">Wir verwenden diese Informationen um</p>
                  <ol className="list-decimal pl-5 mb-3">
                    <li>
                      Ihren Google-Kalender in Ihrem orangeryOS-Terminkalender in der mobilen App
                      aufzufüllen.
                    </li>
                    <li>
                      Buchungen aus dem orangeryOS-Kalender für die synchronisierten Veranstaltungen
                      zu erstellen.
                    </li>
                    <li>
                      Ihren Google-Kalender mit Ihrem orangeryOS-Kalender zu synchronisieren und
                      vice versa.
                    </li>
                  </ol>
                  <p className="mb-3">
                    Wir werden diese Informationen nur für den spezifischen Zweck verwenden, für den
                    sie uns zur Verfügung gestellt wurden.
                  </p>
                  <h3 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Sicherheit
                  </h3>
                  <p className="mb-3">
                    Die Sicherheit Ihrer persönlichen Daten in Google Kalender ist uns wichtig. Wir
                    ergreifen wirtschaftlich vertretbare Maßnahmen und halten uns an allgemein
                    anerkannte Standards, um die von Ihnen zur Verfügung gestellten Informationen zu
                    schützen, sowohl während der Übertragung als auch nach dem Erhalt der
                    Informationen. Beispielsweise werden die von Ihnen bereitgestellten
                    Informationen durch Verschlüsselung mit Technologien wie der Secure Socket
                    Layer-Technologie (SSL) übertragen.
                  </p>
                  <p className="mb-3">
                    Keine Methode der Übertragung über das Internet und keine Methode der
                    elektronischen Speicherung ist zu 100 % sicher. Daher können wir die absolute
                    Sicherheit Ihrer Daten nicht garantieren.
                  </p>
                  <h3 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Zugriff auf Ihre Informationen
                  </h3>
                  <p className="mb-3">
                    Wir werden die synchronisierten/importierten Informationen so lange aufbewahren,
                    wie Ihr Konto aktiv ist oder wie es für die Bereitstellung unserer Dienste
                    erforderlich ist.
                  </p>
                  <p className="mb-6">
                    Wenn Sie die Integration des Google-Kalenders deaktivieren möchten, können Sie
                    den Google-Kalender in den Bereichen unserer Integrationen deaktivieren. Bitte
                    beachten Sie, dass die App standardmäßig nicht mit Ihrem Google-Kalender
                    verbunden ist und dass dies optional ist.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Google-Verwaltungsressourcen
                  </h2>
                  <h3 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    DATENSCHUTZ
                  </h3>
                  <p className="mb-3">
                    Diese Datenschutzerklärung beschreibt, wie Orangery (orangeryOS-Anwendung) die
                    Informationen sammelt und verwendet, die Sie in Ihrem Google Admin
                    Resources-Konto über die orangeryOS "Google Resources Integration" im Abschnitt
                    "Apps" zur Verfügung stellen. Außerdem wird beschrieben, welche Möglichkeiten
                    Sie hinsichtlich der Verwendung Ihrer Informationen haben und wie Sie auf diese
                    Informationen zugreifen und sie aktualisieren können.
                  </p>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Sammlung und Verwendung
                  </h4>
                  <p className="mb-3">
                    Wir erfassen/importieren die folgenden personenbezogenen Daten aus Ihrem Google
                    Admin Resources-Konto:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>Name des Gebäudes</li>
                    <li>Name der Etage</li>
                    <li>Name der Ressource</li>
                    <li>Beschreibung der Ressource</li>
                    <li>Standort der Ressource</li>
                    <li>Kapazität der Ressourcen</li>
                  </ul>
                  <p className="mb-3">
                    Wir fügen die folgenden Informationen aus Ihrem orangeryOS-Konto zu Ihren Google
                    Admin-Ressourcen hinzu bzw. exportieren diese:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>Name des Gebäudes</li>
                    <li>Name der Etage</li>
                    <li>Name der Ressource</li>
                    <li>Beschreibung der Ressource</li>
                    <li>Standort der Ressource</li>
                    <li>Kapazität der Ressourcen</li>
                  </ul>
                  <p className="mb-6">
                    Es ist erwähnenswert, dass wir keine Ressourcen aus Ihrem Google Admin-Konto
                    entfernen.
                  </p>
                  <p className="mb-3">Wir verwenden diese Informationen, um</p>
                  <ol className="list-decimal pl-5 mb-3">
                    <li>
                      Importieren Sie Ihre Google-Ressourcen einschließlich der Konferenzräume
                      (Besprechungsräume) in Ihr orangeryOS-Portalkonto.
                    </li>

                    <li>
                      Exportieren Sie die Büros (Gebäude) und Besprechungsräume aus Ihrem
                      orangeryOS-Konto in Ihr Google Admin Resources-Konto.
                    </li>
                    <li>
                      Synchronisieren Sie Ihre Google-Ressourcen mit Ihrem Orangerie-OS und
                      umgekehrt.
                    </li>
                    <li>
                      Bereitstellung eines Zwei-Wege-Reservierungssystems für die ausgewählten
                      Besprechungsräume von der Google-Kalender-Seite oder der orangeryOS-Anwendung
                      als ein One-Place-Reservierungssystem.
                    </li>
                  </ol>
                  <p className="mb-3">
                    Wir werden diese Informationen nur für den spezifischen Zweck verwenden, für den
                    sie uns zur Verfügung gestellt wurden.
                  </p>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Offenlegung gegenüber Dritten
                  </h4>
                  <p className="mb-3">
                    Wir geben die Informationen über die Ressourcen Ihrer Organisation nicht an
                    Dritte weiter und verkaufen sie auch nicht.
                  </p>
                  <p>Wir geben Informationen nur in den folgenden Fällen weiter:</p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>
                      Wenn dies gesetzlich vorgeschrieben ist, z. B. zur Befolgung einer Vorladung
                      oder eines ähnlichen rechtlichen Verfahrens.
                    </li>
                    <li>
                      Wenn wir in gutem Glauben davon ausgehen, dass die Offenlegung notwendig ist,
                      um unsere Rechte zu schützen, unsere Sicherheit oder die Sicherheit anderer zu
                      schützen, Betrug zu untersuchen oder auf eine behördliche Anfrage zu
                      reagieren.
                    </li>
                    <li>
                      Wenn wir an einer Fusion, einer Übernahme oder einem Verkauf aller oder eines
                      Teils der Vermögenswerte beteiligt sind, werden Sie per E-Mail und/oder durch
                      einen auffälligen Hinweis in unserer App über jede Änderung der
                      Eigentumsverhältnisse oder der Verwendung Ihrer persönlichen Daten sowie über
                      Ihre Wahlmöglichkeiten bezüglich Ihrer persönlichen Daten informiert.
                    </li>
                    <li>an sonstige Dritte mit Ihrer vorherigen Zustimmung.</li>
                  </ul>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Sicherheit
                  </h4>
                  <p className="mb-3">
                    Die Sicherheit Ihrer Informationen über Google Admin Resources ist uns wichtig.
                    Wir ergreifen wirtschaftlich vertretbare Maßnahmen und befolgen allgemein
                    anerkannte Standards, um die von Ihnen zur Verfügung gestellten Informationen zu
                    schützen, sowohl während der Übertragung als auch nach dem Erhalt der
                    Informationen. Beispielsweise werden die von Ihnen bereitgestellten
                    Informationen verschlüsselt übertragen, wobei Technologien wie die Secure Socket
                    Layer-Technologie (SSL) zum Einsatz kommen.
                  </p>
                  <p className="mb-3">
                    Keine Methode der Übertragung über das Internet und keine Methode der
                    elektronischen Speicherung ist 100% sicher. Daher können wir die absolute
                    Sicherheit Ihrer Informationen nicht garantieren.
                  </p>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Zugriff auf Ihre Informationen
                  </h4>
                  <p className="mb-3">
                    Wir werden die synchronisierten/importierten Informationen so lange aufbewahren,
                    wie Ihr Konto aktiv ist oder wie es erforderlich ist, um Ihnen unsere Dienste
                    zur Verfügung zu stellen.
                  </p>
                  <p className="mb-3">
                    Wenn Sie die Integration der Google Admin-Ressourcen deaktivieren möchten,
                    können Sie die Google Admin-Ressourcen in den Abschnitten unserer "Apps" im
                    Dashboard der Website deaktivieren. Bitte beachten Sie, dass die App
                    standardmäßig nicht mit Ihren Google-Admin-Ressourcen verbunden ist und dass
                    dies optional ist.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Integration von Google Admin-Nutzern
                  </h2>
                  <p className="mb-3 mt-3">
                    Diese Datenschutzerklärung beschreibt, wie Orangery (orangeryOS-Anwendung) die
                    Informationen, die Sie in Ihrem Google Admin User-Konto über die orangeryOS
                    "Google Admin User Integration" im Abschnitt "Apps" zur Verfügung stellen,
                    sammelt und verwendet. Außerdem wird beschrieben, welche Möglichkeiten Sie
                    hinsichtlich der Verwendung Ihrer Daten haben und wie Sie auf diese Daten
                    zugreifen und sie aktualisieren können.
                  </p>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Sammlung und Verwendung
                  </h4>
                  <p className="mb-3">
                    Wir erfassen/importieren die folgenden personenbezogenen Daten aus Ihrem Google
                    Admin-Benutzerkonto:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>Vollständiger Name des Benutzers</li>
                    <li>E-Mail des Benutzers</li>
                  </ul>
                  <p className="mb-3">
                    Wir verwenden diese Informationen, um die Google-Nutzer in Ihrer Organisation in
                    Ihr orangeryOS-Portalkonto als verschiedene Rollen zu importieren, die selektiv
                    sind.
                  </p>
                  <p className="mb-3">
                    Wir werden diese Informationen nur für den spezifischen Zweck verwenden, für den
                    sie uns zur Verfügung gestellt wurden.
                  </p>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Offenlegung gegenüber Dritten
                  </h4>
                  <p className="mb-3">
                    Wir geben die Informationen Ihrer Organisation über die Benutzer nicht an Dritte
                    weiter und verkaufen sie auch nicht.
                  </p>
                  <p className="mb-3">
                    Wir geben Informationen nur in den folgenden Fällen weiter:
                  </p>
                  <ul className="list-disc pl-5 mb-3">
                    <li>
                      Wenn dies gesetzlich vorgeschrieben ist, z. B. zur Befolgung einer Vorladung
                      oder eines ähnlichen rechtlichen Verfahrens
                    </li>
                    <li>
                      Wenn wir in gutem Glauben davon ausgehen, dass die Offenlegung notwendig ist,
                      um unsere Rechte zu schützen, unsere Sicherheit oder die Sicherheit anderer zu
                      schützen, Betrug zu untersuchen oder auf eine behördliche Anfrage zu reagieren
                    </li>
                    <li>
                      Wenn wir an einer Fusion, einer Übernahme oder einem Verkauf aller oder eines
                      Teils der Vermögenswerte beteiligt sind, werden Sie per E-Mail und/oder durch
                      einen auffälligen Hinweis in unserer App über jede Änderung der
                      Eigentumsverhältnisse oder der Verwendung Ihrer persönlichen Daten sowie über
                      Ihre Wahlmöglichkeiten bezüglich Ihrer persönlichen Daten informiert.
                    </li>
                    <li>an sonstige Dritte mit Ihrer vorherigen Zustimmung.</li>
                  </ul>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Sicherheit
                  </h4>
                  <p className="mb-3">
                    Die Sicherheit Ihrer Google Admin-Nutzerdaten ist für uns wichtig. Wir ergreifen
                    wirtschaftlich vertretbare Maßnahmen und befolgen allgemein anerkannte
                    Standards, um die von Ihnen zur Verfügung gestellten Informationen zu schützen,
                    sowohl während der Übertragung als auch nach dem Erhalt der Informationen.
                    Beispielsweise werden die von Ihnen bereitgestellten Informationen verschlüsselt
                    übertragen, wobei Technologien wie die Secure Socket Layer-Technologie (SSL) zum
                    Einsatz kommen.
                  </p>
                  <p className="mb-3">
                    Keine Methode der Übertragung über das Internet und keine Methode der
                    elektronischen Speicherung ist 100% sicher. Daher können wir die absolute
                    Sicherheit Ihrer Informationen nicht garantieren.
                  </p>
                  <h4 className="text-normal tracking-tight font-bold sm:text-normal mt-3">
                    Zugriff auf Ihre Informationen
                  </h4>
                  <p className="mb-3">
                    Wir werden die synchronisierten/importierten Informationen so lange aufbewahren,
                    wie Ihr Konto aktiv ist oder wie es für die Bereitstellung unserer Dienste
                    erforderlich ist. Wenn Sie die Integration von Google Admin User deaktivieren
                    möchten, können Sie den Google Admin User in den Abschnitten unserer "Apps" im
                    Dashboard der Website deaktivieren. Bitte beachten Sie, dass die App
                    standardmäßig nicht mit Ihrem Google Admin User verbunden ist und dass dies
                    optional ist.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3 mb-3">
                    Einsatz und Nutzung von Google Analytics
                  </h2>
                  <p className="mb-3">
                    Zur Optimierung, Analyse und für den wirtschaftlichen Betrieb unseres
                    Onlineangebotes setzen wir zu unserem eigenen Interesse im Sinne von Art. 6 Abs.
                    1 lit.f.DSGVO, Google Analytics ein.Es handelt sich dabei um einen
                    Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin
                    4, Irland.Dieser verwendet für die Analyse Cookies mit Informationen über die
                    Benutzung des Onlineangebotes durch die Nutzer.
                  </p>
                  <p className="mb-3">
                    Google erstellt in unserem Auftrag Reports über die Nutzung unseres
                    Onlineangebotes. Hierfür und für weitere Services in unserem Auftrag, werden
                    Informationen über die Aktivitäten der Nutzer innerhalb unseres Angebotes
                    erhoben.Diese Informationen können auch für die Erstellung von pseudonymen
                    Nutzungsprofilen herangezogen werden.
                  </p>
                  <p className="mb-3">
                    In der Regel kürzt Google die IP Adresse des Nutzers innerhalb der EU oder des
                    EWR-Raums (aktivierte IP - Anonymisierung).
                  </p>
                  <p className="mb-3">
                    Eine Zusammenführung der IP Adresse des Nutzers und anderer Google-Daten findet
                    nicht statt. Die Nutzer können die Erfassung und die Verarbeitung der
                    Nutzerdaten verhindern, indem sie das unter diesem Link verfügbare Browser -
                    Plugin herunterladen und installieren:
                    <a
                      href="http://tools.google.com/dlpage/gaoptout?hl=de"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http: //tools.google.com/dlpage/gaoptout?hl=de
                    </a>
                    Die Speicherung der Cookies kann ebenfalls durch Einstellungen in den jeweiligen
                    Browsern vermieden werden.
                  </p>
                  <p className="mb-3">
                    Weiterführende Informationen zu Einstellungs- und Widerspruchsmöglichkeiten
                    sowie zur Datenerhebung durch Google, erfahren Sie direkt bei Google:
                    <a
                      href="https://www.google.com/intl/de/policies/privacy/partners"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https: //www.google.com/intl/de/policies/privacy/partners
                    </a>
                    ,
                    <a
                      href="http://www.google.com/policies/technologies/ads"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http: //www.google.com/policies/technologies/ads
                    </a>
                    , außerdem können Sie hier,
                    <a href="https://adssettings.google.com/" target="_blank" rel="noreferrer">
                      https: //adssettings.google.com/
                    </a>
                    , Ihre ad Einstelllungen einsehen und bearbeiten.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Einbindung und Nutzung von Facebook-Marketing-Diensten
                  </h2>
                  <p className="mb-3">
                    Aufgrund unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f.
                    DSGVO nutzen wir für unser Onlineangebot das sog. "Facebook-Pixel", welches von
                    der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw.falls Sie in der
                    EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
                    Harbour, Dublin 2, Irland betrieben wird("Facebook"). Facebook bietet die
                    Garantie, dass das europäische Datenschutzrecht eingehalten wird, nachgewiesen
                    durch die Zertifizierung unter dem Privacy - Shield - Abkommen (
                    <a
                      href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https: //www.privacyshield.gov/participant
                    </a>
                    ).
                  </p>
                  <p className="mb-3">
                    Das Facebook-Pixel wird von uns eingesetzt um die von uns geschalteten Facebook
                    Werbeanzeigen nur jenen Facebook - Nutzern anzuzeigen, die auch ein Interesse an
                    unserem Onlineangebot, bestimmten Produkten oder Themen gezeigt haben.Außerdem
                    möchten wir mit Hilfe des Facebook - Pixels sicherstellen, dass unsere
                    Werbeanzeigen auf Facebook dem potentiellen Interesse der Nutzer entsprechen und
                    nicht belästigend wirken.Das Facebook Pixel ermöglicht uns, den Wirkungsgrad
                    unserer Facebook Ads festzustellen und Statistiken darüber zu erstellen, wie
                    viele Nutzer über eine Werbeanzeige unser Onlineangebot besuchen.
                  </p>
                  <p className="mb-3">
                    Wird eine unserer Webseiten aufgerufen, wird das Facebook-Pixel automatisch in
                    die Seite eingebunden und es kann ein Cookie auf dem Gerät des Nutzers
                    hinterlegt werden.Ist der Nutzer währenddessen bei Facebook eingeloggt oder
                    loggt er sich später ein, wird im entsprechenden Facebook - Profil auch der
                    Besuch unseres Onlineangebotes gespeichert.Die erhobenen Daten sind anonymisiert
                    und lassen keinerlei Rückschlüsse auf die Nutzeridentität durch uns zu.Facebook
                    selbst allerdings speichert und verarbeitet die Daten jedoch und so ist durch
                    die Verbindung zum jeweiligen Facebook - Profil auch die Facebook - eigene
                    Nutzung für Werbe - oder Marktforschungszwecke möglich.Sollte ein Abgleich der
                    Daten unsererseits mit Facebook notwendig sein, werden diese zunächst innerhalb
                    des Browsers verschlüsselt und erst dann von uns über eine gesicherte Verbindung
                    an Facebook versendet.
                  </p>
                  <p className="mb-3">
                    Umfang und Verarbeitung der Daten sind in Facebooks Datenverwendungsrichtlinie
                    festgeschrieben.Auch grundlegende Hinweise zu Facebook Werbeanzeigen finden Sie
                    unter:
                    <a href="https://www.facebook.com/policy.php" target="_blank" rel="noreferrer">
                      https: //www.facebook.com/policy.php
                    </a>
                    . Weiterführende Informationen zu Facebook Pixel und dessen Funktionsweise
                    erhalten Sie im Hilfebereich von Facebook:
                    <a
                      href="https://www.facebook.com/business/help/651294705016616"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https: //www.facebook.com/business/help/651294705016616
                    </a>
                    . Ein Widerspruch gegen die Datenerfassung durch den Facebook - Pixel und gegen
                    die Verwendung Ihrer Daten zur Anzeige von Facebook - Ads ist möglich.Besuchen
                    Sie dafür die von Facebook eingerichtete Seite und befolgen Sie dafür die
                    Hinweise zu den Einstellungen nutzungsbasierter Werbung:
                    <a
                      href="https://www.facebook.com/settings?tab=ads"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https: //www.facebook.com/settings?tab=ads
                    </a>
                    . Alle Einstellungen erfolgen plattformunabhängig, die Anwendung erfolgt somit
                    auf allen Endgeräten(wie z.B.Mobil - oder Desktopgeräte).
                  </p>
                  <p className="mb-3">
                    Ein Widerspruch gegen den Einsatz von Cookies, die der Reichweitenmessung und zu
                    Werbezwecken dienen, kann über die Deaktivierungsseite der
                    Netzwerkwerbeinitiative (
                    <a
                      href="http://optout.networkadvertising.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http: //optout.networkadvertising.org/
                    </a>
                    ), die europäische Webseite (
                    <a
                      href="http://www.youronlinechoices.com/uk/your-ad-choices/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      http: //www.youronlinechoices.com/uk/your-ad-choices/
                    </a>
                    und zusätzlich die US - amerikanische Webseite (
                    <a href="http://www.aboutads.info/choices" target="_blank" rel="noreferrer">
                      http: //www.aboutads.info/choices
                    </a>
                    ) erfolgen.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Einbindung und Nutzung von Zendesk
                  </h2>
                  <p className="mb-3">
                    Auf dieser Website werden mit Technologien der Zendesk Inc., 1019 Market St, San
                    Francisco, USA(
                    <a href="https://www.zendesk.com" target="_blank" rel="noreferrer">
                      www.zendesk.com
                    </a>
                    ) pseudonymisierte Daten zum Zwecke der Webanalyse und zum Betreiben des Live -
                    Chat - Systems zur Beantwortung von Live - Support - Anfragen gesammelt und
                    gespeichert.Aus diesen pseudonymisierten Daten können unter einem Pseudonym
                    Nutzungsprofile erstellt werden.Hierzu können Cookies eingesetzt werden.Sofern
                    die so erhobenen Informationen einen Personenbezug aufweisen, erfolgt die
                    Verarbeitung gemäß Art. 6 Abs. 1 lit.f DSGVO auf Basis unseres berechtigten
                    Interesses an effektiver Kundenbetreuung und der statistischen Analyse des
                    Nutzerverhaltens zu Optimierungszwecken. Die mit den Zendesk - Technologien
                    erhobenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen
                    nicht dazu benutzt, den Besucher dieser Website persönlich zu identifizieren und
                    nicht mit personenbezogenen Daten über den Träger des Pseudonyms
                    zusammengeführt.Um die Speicherung von Zendesk - Cookies zu vermeiden, können
                    Sie Ihren Internetbrowser so einstellen, dass zukünftig keine Cookies mehr auf
                    Ihrem Computer abgelegt werden können bzw.bereits abgelegte Cookies gelöscht
                    werden.Das Abschalten sämtlicher Cookies kann jedoch dazu führen, dass einige
                    Funktionen auf unseren Internetseiten nicht mehr ausgeführt werden können.Die
                    Datenerhebung und –speicherung zum Zwecke der Erstellung eines pseudonymisierten
                    Nutzungsprofils können Sie jederzeit mit Wirkung für die Zukunft deaktivieren,
                    indem Sie uns Ihren Widerspruch formlos per E - Mail an die im Impressum
                    genannte E - Mail - Adresse schicken.Zendesk Inc.mit Sitz in den USA ist für das
                    US - EU Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die
                    Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.{" "}
                    <a
                      href="https://www.privacyshield.gov/participant?id=a2zt0000000TOjeAAG&amp;status=Active"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {
                        "https://www.privacyshield.gov/participant?id=a2zt0000000TOjeAAG&amp;status=Active"
                      }
                    </a>
                    Nähere Informationen entnehmen Sie bitte den Datenschutzhinweisen von Zendesk:{" "}
                    <a
                      href="https://www.zendesk.com/company/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https: //www.zendesk.com/company/privacy.
                    </a>
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Einbindung von Diensten und Inhalten Dritter
                  </h2>
                  <p className="mb-3">
                    Innerhalb unseres Onlineangebotes kommen auch Angebote von Drittanbietern zum
                    Einsatz. Grundlage hierfür ist ebenfalls unser berechtigtes Interesse im Sinne
                    von Art. 6 Abs. 1 lit.f.DSGVO.Für die Inhalte und deren Darstellung(z.B.Videos
                    oder Schriftarten) ist erforderlich, dass die Drittanbieter die IP - Adresse des
                    Nutzers erkennen.Für die Versendung der Inhalte an den Browser ist dies
                    unumgänglich. Bei der Auswahl der Drittanbieter achten wir darauf, nur jene
                    Anbieter zu verwenden, die die IP - Adresse nur für die Auslieferung der Inhalte
                    nutzen.Außerdem können Drittanbieter Web Beacons oder Pixel - Tags verwenden, um
                    Daten für Statistik und Marketing zu erheben.Hierdurch können z.B.Informationen
                    zu den Besuchern der Webseite ausgewertet werden.Sämtliche Daten können
                    pseudonymisiert in Cookies auf dem vom Nutzer verwendeten Gerät gespeichert
                    werden.Zu diesen Daten gehören unter anderem die technischen Informationen zu
                    Betriebssystem und Browser, sowie Daten zur Nutzung des Angebotes.Diese Daten
                    können ebenfalls mit Daten aus anderen Quellen verbunden werden.
                  </p>
                  <p className="mb-3">
                    Nachfolgend finden Sie eine Übersicht der von uns eingebundenen Drittanbieter
                    inkl. Links zu den entsprechenden Datenschutzerklärungen.Diese enthalten auch
                    weiterführende Informationen zu Widerspruchsmöglichkeiten, sowie Opt - Out
                    Optionen, sofern diese möglich sind.
                  </p>
                  <ul className="data-list">
                    <li>
                      Für die Einbindung und Verwaltung der verschiedenen Analyse- und
                      Marketingdienste können wir den „Google Tag Manager“ einsetzen.
                    </li>
                    <li>
                      Externer Code des JavaScript-Frameworks “jQuery”, bereitgestellt durch den
                      Dritt-Anbieter jQuery Foundation,{" "}
                      <a href="https://jquery.org" target="_blank" rel="noreferrer">
                        https: //jquery.org
                      </a>
                      .
                    </li>
                    <li>
                      Externer Code für die Cookie Consent Bar, bereitgestellt durch den
                      Dritt-Anbieter Silktide Ltd.,{" "}
                      <a href="https://cookieconsent.insites.com" target="_blank" rel="noreferrer">
                        https: //cookieconsent.insites.com
                      </a>
                      .
                    </li>
                    <li></li>
                  </ul>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Persönliche Rechte der Nutzer
                  </h2>
                  <p className="mb-3">
                    Auf Antrag kann jeder Nutzer Auskunft über seine bei uns gespeicherten,
                    personenbezogenen Daten erhalten.
                  </p>
                  <p className="mb-3">
                    Darüber hinaus haben die Nutzer das Recht unrichtige Daten berichtigen zu
                    lassen, sowie die Verarbeitung und Löschung ihrer personenbezogenen Daten
                    einzuschränken.Außerdem kann das Recht auf Datenportabilität geltend gemacht
                    werden.Eine Beschwerde bei der zuständigen Aufsichtsbehörde ist jederzeit
                    möglich.
                  </p>
                  <p className="mb-3">
                    Jede vom Nutzer gegebene Einwilligung kann jederzeit grundsätzlich,
                    ausschließlich mit zukünftiger Auswirkung, widerrufen werden.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Datenlöschung
                  </h2>
                  <p className="mb-3">
                    Daten, die nicht einer gesetzlichen Aufbewahrungsfrist unterliegen, werden
                    gelöscht, sobald sie für Ihren Zweck nicht mehr erforderlich sind.Ist die
                    Löschung aufgrund ihres gesetzlich zulässigen Zweckes oder anderer Bestimmungen
                    nicht möglich, wird deren Verarbeitung eingeschränkt.Eine Sperrung der Daten
                    verhindert demnach die Verarbeitung zu anderen Zwecken.
                    <br />
                    Die Aufbewahrung erfolgt gemäß § 257 Abs. 1 HGB(für Handelsbücher, Inventare,
                    Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege, etc.) für 6
                    Jahre, sowie gemäß § 147 Abs. 1 AO(für Bücher, Aufzeichnungen, Lageberichte,
                    Buchungsbelege, Handels - und Geschäftsbriefe, Für Besteuerung relevante
                    Unterlagen, etc.) für 10 Jahre.
                  </p>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    Widerspruchsrecht und sonstige Rechte
                  </h2>
                  <p className="mb-3">
                    Hat der Nutzer seine Einwilligung zu der Verarbeitung der ihn betreffenden
                    personenbezogenen Daten für einen oder mehrere bestimmte Zwecke gegeben, steht
                    dem Nutzer die Möglichkeit des Widerrufs der Einwilligung mit Wirkung für die
                    Zukunft zu.
                  </p>
                  <p className="mb-3">
                    <b>
                      Insbesondere steht dem Nutzer grundsätzlich gegen die Verarbeitung der
                      personenbezogenen Daten im Rahmen der berechtigten Interessen das Recht zu,
                      der Verarbeitung jederzeit kostenfrei mit Wirkung für die Zukunft zu
                      widersprechen.Hierzu genügt eine E - Mail an{" "}
                      <a href={`mailto:contact@orangery.io`}>contact@orangery.io</a> oder an die
                      o.g.genannte Post - Adresse.
                    </b>
                  </p>
                  <p className="mb-3">
                    Jede betroffene Person hat unbeschadet eines anderweitigen
                    verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde
                    bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                    Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes,
                    wenn die betroffene Person der Ansicht ist, dass die Verarbeitung der sie
                    betreffenden personenbezogenen Daten gegen diese Verordnung verstößt.
                  </p>
                  <p className="mb-3">
                    Eine zuständige Behörde ist z.B. die Landesbeaufztragte für den Datenschutz
                    Niedersachsen, Prinzenstraße 5, 30159 Hannover.Der Nutzer kann aber auch eine
                    andere wählen.
                  </p>
                  <p className="heading--3">
                    Nutzung des Kontaktformulars oder sonstige Kontaktaufnahme:
                  </p>
                  <p className="mb-3">
                    Die Angabe folgender Daten ist für die Bearbeitung einer Anfrage über das
                    Kontaktformular zwingend erforderlich (Pflichtangaben):
                  </p>
                  <ol style={{ "list-style-type": "square !important", "padding-left": "40px" }}>
                    <li>Name</li>
                    <li>Telefonnummer</li>
                    <li>E-Mail-Adresse</li>
                  </ol>
                  <p className="mb-3">
                    Alle anderen Angaben sind für die Bearbeitung einer Kontaktanfrage nicht
                    erforderlich und sind somit freiwillig. Wenn die für die Bearbeitung einer
                    Kontaktanfrage erforderlichen Pflichtangaben nicht gegeben werden, findet eine
                    Bearbeitung der Kontaktanfrage nicht statt.Die Nichtangabe der freiwilligen
                    Angaben hat keinen Einfluss auf die Bearbeitung der Kontaktanfrage. Für die
                    Bearbeitung einer sonstigen Anfrage besteht keine Pflicht zur Angabe von Daten.
                  </p>
                  <p className="heading--3">Automatisierte Entscheidungsfindung</p>
                  <p className="mb-3">
                    Eine automatisierte Entscheidungsform einschließlich Profiling findet nicht
                    statt.
                  </p>
                  <h2> Aktualisierung der Datenschutzerklärung</h2>
                  <p className="mb-3">
                    Wir behalten uns vor, bei geänderter Rechtslage, sowie bei Änderungen unserer
                    Dienste oder der Datenverarbeitung diese Datenschutzerklärung zu ändern.Dies
                    gilt allerdings ausschließlich im Hinblick auf Erklärungen zur
                    Datenverarbeitung.Sind Einwilligungen der Nutzer erforderlich, oder enthalten
                    Bestandteile der Datenschutzerklärung Regelungen des Vertragsverhältnisses mit
                    den Nutzern, sind Änderungen nur mit Zustimmung der Nutzer möglich.
                    <br />
                    Wir bitten die Nutzer darum, regelmäßig eigenständige Informationen über den
                    Inhalt der Datenschutzerklärung einzuholen.
                  </p>
                </div>
              </div>
            </div>
          </main>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}
