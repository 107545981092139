/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json";
import config from "react-global-configuration";

// contexts
// import { AuthContext } from "../context/authContext";

// import { useTranslation } from 'react-i18next';
import i18next from "i18next";

import { useState, useEffect } from "react";

import { Img } from "../components";

import { Header, Footer } from "../implementedComponents";

import firstSectionImage from "../assets/images/news/News_Banner_new.png";

import newsList from "../data/newsList";

const generals = require("../generals");

export default function News(props) {
  // const { t } = useTranslation();

  let newsKey = props.match.params.newsKey;

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    // let getRequested = false;
    // if (!getRequested) {
    //   getRequested = true;

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       // 'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //         // config.set({ locationOptions: tempList }, { freeze: false, environment: 'notFreeze' })
    //         // config.setEnvironment('notFreeze');
    //       }
    //     })
    //     // .catch((error) => {
    //     //   config.set("locationOptions", [])
    //     // })
    //     .then(function () {
    //       // always executed
    //     });
    // }
  }

  useEffect(() => {
    loadLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let news =
    newsKey !== "list" &&
    newsList.filter((newsItem) => newsItem.key === newsKey)?.[0];

  return (
    <>
      <div className="bg-mainBackground">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-28 sm:pt-16 md:pt-22 pb-0 lg:pb-0">
              <div className="mx-0 px-0">
                <Img
                  className={`z-20 w-full h-[200px] md:h-[600px] md:object-cover`}
                  src={
                    news
                      ? news[i18next.language]?.imageSrc ?? news.imageSrc
                      : firstSectionImage
                  }
                  alt=""
                />
              </div>
            </div>

            {/* Second section */}
            {news ? (
              <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                  <div className="">
                    <h1 className="text-center w-full text-3xl tracking-tight font-medium sm:text-5xl leading-tight font-Caveat+Brush">
                      {news[i18next.language]?.title ?? news.title}
                    </h1>
                    <br />

                    <p className="mb-3">
                      <strong>
                        {news[i18next.language]?.date ?? news.date}
                      </strong>
                    </p>

                    {news[i18next.language]?.body ?? news.body}

                    {news[i18next.language]?.contacts
                      ? news[i18next.language]?.contacts
                      : news.contacts ?? ""}
                  </div>
                </div>
              </div>
            ) : (
              <div className="px-4 mx-auto max-w-7xl mb-12">
                <h1 className="mt-12 mb-8 text-left w-full text-3xl tracking-tight font-medium sm:text-5xl leading-tight font-Overpass border-b-2">
                  News list
                </h1>
                {Array.isArray(newsList) && newsList.length > 0 ? (
                  newsList.map((newsItem) => (
                    <NewsHorizontalCard news={newsItem} key={newsItem.key} />
                  ))
                ) : (
                  <>No news is available now.</>
                )}
              </div>
            )}
          </main>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}

function NewsHorizontalCard({ news }) {
  return (
    <a
      className="py-4 flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-4 sm:space-y-0"
      href={`${config.get("homeUrl")}/news/${news.key}`}
      // target="_blank"
      // rel="noreferrer"
    >
      <Img
        className="z-20 w-48 h-40 rounded-md object-cover "
        src={news[i18next.language]?.imageSrc ?? news.imageSrc}
        alt=""
      />
      <div>
        <p className="text-sm font-semibold">
          {news[i18next.language]?.date ?? news.date}
        </p>
        <h2 className="text-lg font-bold mt-2">
          {news[i18next.language]?.cleanTitle ?? news.cleanTitle}
        </h2>
        <p className="text-sm mt-2">
          {news[i18next.language]?.summary ?? news.summary}
        </p>
      </div>
    </a>
  );
}
