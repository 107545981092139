/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json";

// contexts
// import { AuthContext } from "../context/authContext";

import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import { Img } from "../components";

import { Header, Footer } from "../implementedComponents";

import firstSectionImage from "../assets/images/impressum/impressum.jpg";

const generals = require("../generals");

export default function Impressum() {
  const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    // let getRequested = false;
    // if (!getRequested) {
    //   getRequested = true;

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       // 'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //         // config.set({ locationOptions: tempList }, { freeze: false, environment: 'notFreeze' })
    //         // config.setEnvironment('notFreeze');
    //       }
    //     })
    //     // .catch((error) => {
    //     //   config.set("locationOptions", [])
    //     // })
    //     .then(function () {
    //       // always executed
    //     });
    // }
  }

  useEffect(() => {
    loadLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-28 sm:pt-16 md:pt-22 pb-0 lg:pb-0">
              <div className="mx-0 px-0">
                <Img
                  className="z-20 w-full h-auto object-cover"
                  src={firstSectionImage}
                  alt=""
                />
              </div>
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    {t("Imprint")}
                  </h1>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("According to § 5 TMG")}
                  </h2>
                  <strong>{t("orangery Coworking GmbH")}</strong>
                  <br />
                  {t("Am Alten Wasserwerk 1")}
                  <br />
                  {t("31135 Hildesheim")}
                  <br />
                  {t("Represented by CEO's")}:
                  <br />
                  Dominik Groenen, Sebastian Groenen
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("Contact")}
                  </h2>
                  {t("Phone")}:{" "}
                  <a href={`tel:0800 005 50 77`}>0800 005 50 77</a>
                  <br />
                  {t("Fax")}: 05121-408807
                  <br />
                  {t("E-Mail")}:{" "}
                  <a href={`mailto:contact@orangery.io`}>info@orangery.io</a>
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("TAX-ID")}
                  </h2>
                  {t("TAX-ID")}: 30/21130919
                  <br />
                  {t("Registration number")}: HRB 205883
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t(
                      "Responsible for the content according to § 55 Abs. 2 RStV"
                    )}
                  </h2>
                  Dominik Groenen,
                  <br />
                  {t("Am Alten Wasserwerk 1")}
                  <br />
                  {t("31135 Hildesheim")}
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("Dispute settlement")}
                  </h2>
                  {t(
                    "The European Commission provides a platform for online dispute resolution (OS)"
                  )}
                  : https://ec.europa.eu/consumers/odr.
                  <br />
                  {t("You can find our email address in the imprint above.")}
                  <br />
                  {t(
                    "We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board."
                  )}
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("Liability for content")}
                  </h2>
                  {t(
                    "As a service provider, we are responsible for our own content on these pages in accordance with general laws in accordance with Section 7 (1) TMG. According to §§ 8 to 10 TMG we are not as a service provider obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity."
                  )}
                  <br />
                  {t(
                    "Obligations to remove or block the use of information according to general laws remain unaffected. Liability in this regard is only from the time of Knowledge of a concrete violation of the law is possible. As soon as we become aware of such violations, we will remove this content immediately."
                  )}
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("Liability for links")}
                  </h2>
                  {t(
                    "Our offer contains links to external websites of third parties, the content of which we have no influence on. For this reason, we cannot accept any liability for this external content. For the The content of the linked pages is always the responsibility of the respective provider or operator of the pages. The linked pages were at the time of linking for possible legal violations checked. No illegal content was discernible at the time the link was created."
                  )}
                  <br />
                  {t(
                    "A permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. We will be aware of any violations of the law remove such links immediately."
                  )}
                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-3">
                    {t("Copyright")}
                  </h2>
                  {t(
                    "The content and works on these pages created by the site operator are subject to German copyright law. Duplication, editing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this page are for private use only, not commercial use allowed."
                  )}
                  <br />
                  {t(
                    "As far as the content on this page was not created by the operator, the copyrights of third parties are respected. In particular contents of third parties are marked as such. Should you Nevertheless, if we become aware of a copyright infringement, we ask you to inform us accordingly. As soon as we become aware of legal violations, we will immediately publish such content remove."
                  )}
                </div>
              </div>
            </div>
          </main>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}
