/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json";
// import config from "react-global-configuration";

// contexts
// import { AuthContext } from "../context/authContext";

// import { useTranslation } from 'react-i18next';
import i18next from "i18next";

import { useState, useEffect } from "react";

import { Img } from "../components";

import { Header, Footer } from "../implementedComponents";

import osterSpecialHeaderImage from "../assets/images/connect/orangeryCONNECT_Header.jpg";

import HubspotForm from "react-hubspot-form";
const generals = require("../generals");

export default function CoworkingFestival() {
  // const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);
  }

  useEffect(() => {
    loadLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-16 md:pt-22 pb-0 lg:pb-0">
              <div className="mx-0 px-0">
                <Img
                  className="z-20 w-full h-auto object-cover"
                  src={osterSpecialHeaderImage}
                  alt=""
                />
              </div>
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  {/* <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    {t("Privacy Policy")}
                  </h1> */}

                  {i18next.language === "en" ? (
                     <>
                     <h3 className="mb-2 text-xl font-semibold">Coworking Festival MV 2023</h3>
                     <p className="mb-5">
                     We are participating in the second Coworking Festival MV with our locations in Rostock, Schwerin and Stralsund. From 26.04. to 28.04.2023 you can get a taste of the orangery spaces by simply registering for the Open Days or you can register for our event in Rostock on April 27.
                     </p>
                     <h3 className="mb-2 text-xl font-semibold">orangeryCONNECT!</h3>
                     <p className="mb-3">
                     One office, two people, three minutes and endless possibilities. With our event series orangeryCONNECT! we want to connect people because coworking is simply more than just working. Get to know each other, laugh and get creative together. We enjoy deeper conversations afterwards with snacks & beer.
                     </p>
                     <p className="mb-3">
                     When?: on 27.04.2023 from 1:30-4:30 p.m.<br/>
                     {'Where?: orangery Rostock (Timmermannsstrat 4b, 18055 Rostock)'}
                     </p>
                   </>
                  ) : (
                    <>
                      <h3 className="mb-2 text-xl font-semibold">Coworking Festival MV 2023</h3>
                      <p className="mb-5">
                      Wir nehmen mit unseren Standorten in Rostock, Schwerin und Stralsund am zweiten Coworking Festival MV teil. Vom 26.04. bis zum 28.04.2023 könnt ihr so in die orangery Spaces schnuppern, indem ihr euch einfach für die Open Days registriert oder ihr euch für unser Event in Rostock am 27. April anmeldet.
                      </p>
                      <h3 className="mb-2 text-xl font-semibold">orangeryCONNECT!</h3>
                      <p className="mb-3">
                      Ein Büro, zwei Menschen, drei Minuten und unendlich viele Möglichkeiten. Mit unserer Eventreihe orangeryCONNECT! möchten wir Menschen miteinander verbinden weil Coworking einfach mehr als nur arbeiten ist. Lernt euch kennen, lacht und werdet kreativ zusammen. Tiefergehende Gespräche genießen wir anschließend bei Snacks & Bier.
                      </p>
                      <p className="mb-3">
                      Wann?: am 27.04.2023 von 13:30-16:30 Uhr<br/>
                      Wo?: orangery Rostock (Timmermannsstrat 4b, 18055 Rostock)
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
            id="orangeryConnectForm"
            className=" text-center pl-4 md:pl-[200px] mx-auto w-full md:max-w-[800px]"
          >
            <HubspotForm
              region="na1"
              portalId="7914845"
              formId="c86c47a6-1031-461e-9508-92ae8fc883c0"
              onSubmit={() => {}}
              onReady={(form) => {}}
              loading={<div>Loading...</div>}
            />
          </div>
          <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  {/* <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    {t("Privacy Policy")}
                  </h1> */}

                  {i18next.language === "en" ? (
                    <>
                     <h3 className="mb-2 text-xl font-semibold">Open Days</h3>
                      <p className="mb-5">
                      Come by, make the orangery your home office and get to know and love all our features.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className="mb-2 text-xl font-semibold">Open Days</h3>
                      <p className="mb-5">
                      Komm vorbei, mach die orangery zu deinem Homeoffice und lerne all unsere Features kennen und lieben.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </main>
          <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
         
          <div
            id="orangeryConnectForm"
            className=" text-center pl-4 md:pl-[200px] mx-auto w-full md:max-w-[800px]"
          >
            <HubspotForm
              region="na1"
              portalId="7914845"
              formId="5cb92c86-c9dd-4d37-b5f3-42df766bfce8"
              onSubmit={() => {}}
              onReady={(form) => {}}
              loading={<div>Loading...</div>}
            />
          </div>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}
