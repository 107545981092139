/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          teal: colors.teal,
          cyan: colors.cyan,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import getlocations from "../data/getlocations.json";
import config from "react-global-configuration";

// contexts
// import { AuthContext } from "../context/authContext";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import { DropDown, Button, Img, Carousel, LocationSlide } from "../components";
import { Header, Footer, FeaturesList } from "../implementedComponents";

import firstSectionImage from "../assets/images/home/first section.jpeg";
import secondSectionImage from "../assets/images/home/Coworking.png";
import forthSectionBackground from "../assets/images/home/forth section background.svg";

// import bannerLeft from '../assets/images/home/banner/l.png'
// import bannerRight from '../assets/images/home/banner/r.png'

import { C1, C2, C3, C4, C5 } from "../assets/images/customers";

import { Calling, Message } from "react-iconly";

import i18next from "i18next";

const generals = require("../generals");

// const blogPosts = [
//   {
//     id: 1,
//     title: 'Boost your conversion rate',
//     href: '#',
//     date: 'Mar 16, 2020',
//     datetime: '2020-03-16',
//     category: { name: 'Article', href: '#' },
//     imageUrl:
//       'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//     preview:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
//     author: {
//       name: 'Roel Aufderehar',
//       imageUrl:
//         'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//       href: '#',
//     },
//     readingLength: '6 min',
//   },
//   {
//     id: 2,
//     title: 'How to use search engine optimization to drive sales',
//     href: '#',
//     date: 'Mar 10, 2020',
//     datetime: '2020-03-10',
//     category: { name: 'Video', href: '#' },
//     imageUrl:
//       'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//     preview:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
//     author: {
//       name: 'Brenna Goyette',
//       imageUrl:
//         'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//       href: '#',
//     },
//     readingLength: '4 min',
//   },
//   {
//     id: 3,
//     title: 'Improve your customer experience',
//     href: '#',
//     date: 'Feb 12, 2020',
//     datetime: '2020-02-12',
//     category: { name: 'Case Study', href: '#' },
//     imageUrl:
//       'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
//     preview:
//       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
//     author: {
//       name: 'Daniela Metz',
//       imageUrl:
//         'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//       href: '#',
//     },
//     readingLength: '11 min',
//   },
// ]

export default function Home() {
  // const { accessToken } = useContext(AuthContext)

  const { t } = useTranslation();

  const [locations, setLocations] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState(-1);
  const [waiting] = useState(false);

  const customerQuotes = [
    {
      id: 1,
      name: "Justin Gillert",
      position: t("CEO"),
      company: "G&G Praxisberatung",
      quote: t(
        "The orangery manages to create a real feel-good atmosphere here in Hildesheim with spacious rooms, in which one not only likes to work, but also to spend time. The concept is not only well thought out, but also extremely well implemented."
      ),
      avatar: C1,
    },
    {
      id: 2,
      name: "Daniel Schmahl",
      position: t("Deputy CEO"),
      company: "PremiumMed AG",
      quote: t(
        'With the work and lifestyle of co-working, especially in the orangery, I combine the symbiosis of modern creative work in a very appealing ambience and the interaction with interesting co-workers as well as the great and very friendly orangery team. To top it all off, there\'s a delicious bowl and a cappuccino at "Roots\'n\'Seeds" for the "break"... what more could you ask for! orangery… place to be!'
      ),
      avatar: C2,
    },
    {
      id: 3,
      name: "Ghazaleh Ghazanfari",
      position: t("Product Designer"),
      company: "CutMetall Sales GmbH",
      quote: t(
        "We used to work in our office and our boss decided to move out and we came here in the beginning to consider whether we should look for an office for our company or not. this place was so nice that we decided to stay here and we feel comfortable here."
      ),
      avatar: C3,
    },
    {
      id: 4,
      name: "Marius Appel & Mortiz Appel",
      position: "",
      company: "tecis Finanzdienstleistungen AG",
      quote: t(
        "We are happy to be part of the orangery community and love the exchange with each other. We are already looking forward to the first joint events in summer."
      ),
      avatar: C4,
    },
    {
      id: 5,
      name: "Alexander Homburg",
      position: "",
      company: "Head of Sales - Nordhealth AS",
      quote: t(
        "New job, new challenge, new office, new environment - out of the comfort zone? At least not spatially. With its office, sports and café offering, the Orangery offers a comfort zone that is second to none. Stylishly furnished, well equipped - everything you need to give your all every day! At the online meetings with colleagues from Sweden, America, England and the rest of the world, one or the other gets jealous. Everything done right Orangery, keep it up!"
      ),
      avatar: C5,
    },
  ];

  function loadLocations() {
    let data = getlocations.data;
    setLocations(data);
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    // let getRequested = false;
    // if (!getRequested) {
    //   getRequested = true;

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         setLocations(data)
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     })
    //     .then(function () {
    //       // always executed
    //     });
    // }
  }

  useEffect(() => {
    loadLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventTrack = (category, action, label) => {
    // console.log("GA event trigerred: ", category, ":", action, ":", label);
    // console.log({
    //   ReactGAEventTrack: {
    //     category: category,
    //     action: action,
    //     label: label,
    //   }
    // })
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  function onLocationChange(fieldName, value) {
    // eventTrack("home page", "select location", locations.filter((location) => location.id === value)[0]?.name)
    eventTrack("home page", "select location", value);
    setSelectedLocationName(value);
  }

  function onStartButtonClick(e) {
    e.preventDefault();
    if (
      selectedLocationName !== undefined &&
      selectedLocationName !== null &&
      selectedLocationName !== -1
    ) {
      // setWaiting(true)
      // eventTrack("home page", "start button click", "goto: " + locations.filter((location) => location.id === selectedLocationId)[0]?.name)
      eventTrack(
        "home page",
        "start button click",
        "goto: " + selectedLocationName
      );
      generals.goTo(
        `${config.get("homeUrl")}/location/${selectedLocationName}`,
        0
      );
    }
  }

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-eighth mt-22 sm:mt-10 md:mt-22 pb-0 lg:pb-0">
              {/* <div className="flex flex-row absolute z-20 w-full h-12 top-10 sm:top-6 lg:top-2">
                <div className="bg-brand w-5 md:w-20 xl:w-40 2xl:w-80" />
                <Img
                  className=""
                  src={bannerLeft}
                  alt=""
                />
                <div className="bg-black grow h-full flex items-center">
                  {/* eslint-disable-next-line jsx-a11y/no-distracting-elements * /}
                  <marquee>
                    <p className="text-base sm:text-lg md:text-2xl font-semibold tracking-wide font-Caveat+Brush text-center w-full">
                      <span className="text-white">BLACK FRIDAY DEAL</span>{" "}
                      <span className="text-brand">JETZT 50% AUF UNSERE FLEXDESKS SPAREN (1 Monat oder 3 Monate)</span>
                    </p>
                  </marquee>
                </div>
                <Img
                  className=""
                  src={bannerRight}
                  alt=""
                />
                <div className="bg-brand w-5 md:w-20 xl:w-40 2xl:w-80" />
              </div> */}

              <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
                <div className="flex lg:space-x-6 2xl:space-x-12 flex-col lg:flex-row py-4">
                  <div className="basis-full lg:basis-5/12 sm:text-center px-0 lg:text-left lg:flex lg:items-center">
                    <div className="py-2 lg:pt-10 lg:pb-8 mt-4 lg:mt-20">
                      <p className="py-4 sm:py-0.5 text-5xl font-semibold tracking-wide font-Caveat+Brush">
                        <span className="text-white font-Overpass">
                          {t("THE")}{" "}
                        </span>
                        <br />
                        <span className="text-brandNew font-Overpass">
                          {t("MOST FLEXIBLE OFFICE")}
                        </span>
                        <br />
                        <span className="text-white font-Overpass">
                          {" "}
                          {t("IN TOWN!")}
                        </span>
                      </p>
                      {/* <div className="lg:pt-28 lg:pb-24 mt-24">
                      <p className="py-0.5 text-3xl font-semibold tracking-wide font-Caveat+Brush">
                        <span className="text-white">{t("THE")}{" "}</span>
                        <span className="text-brand">{t("MOST FLEXIBLE OFFICE")}</span>
                        <span className="text-white">{" "}{t("IN TOWN!")}</span>
                      </p> */}
                      {/* <h1 className="text-2xl tracking-tight font-medium text-white sm:text-4xl mt-3 font-Roboto+Slab">
                        {config.get('orangery').slogan}
                      </h1> */}
                      <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl mt-10">
                        {t("Find your workspace")}
                      </p>
                      <div className="mt-0">
                        <form action="#" className="">
                          <div className="sm:max-w-xl sm:mx-auto mt-3 lg:mx-0 sm:flex justify-start sm:justify-center lg:justify-start">
                            <div className="">
                              <DropDown
                                field={{
                                  label: "",
                                  id: "location",
                                  name: "location",
                                  type: "select",
                                  placeholder: t("Find workspace in ..."),

                                  valid: true,
                                  value: "",

                                  items: locationOptions,

                                  key: "location",
                                  trimmable: false,
                                  required: false,

                                  inputClassName: "text-left w-72 h-12",
                                  className: "w-72 h-12",
                                }}
                                onChange={onLocationChange}
                              />
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
                              <Button
                                onClick={onStartButtonClick}
                                className="h-12 w-72 sm:w-32 bg-openDays border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-mainBackground hover:bg-brandNew focus:outline-none"
                                text={t("Start")}
                                waiting={waiting}
                              />
                            </div>
                          </div>
                        </form>
                        <a
                          className="flex flex-row space-x-2 items-center justify-start sm:justify-center lg:justify-start text-white mt-4 mb-2"
                          href={`tel:${config.get("orangery").phone}`}
                          onClick={() => {
                            eventTrack(
                              "home-section1",
                              "click",
                              "official phone tag"
                            );
                          }}
                        >
                          <div className="flex-none">
                            <Calling set="light" size={22} />
                          </div>
                          <span>
                            {t("Contact sales")}: {config.get("orangery").phone}
                          </span>
                        </a>
                        <a
                          className="flex flex-row space-x-2 items-center justify-start sm:justify-center lg:justify-start text-white mt-0 mb-6"
                          href={`mailto:${
                            config.get("orangery").official_email
                          }`}
                          // target="_blank"
                          // rel="noreferrer"
                          onClick={() => {
                            eventTrack(
                              "home-section1",
                              "click",
                              "official emial tag"
                            );
                          }}
                        >
                          <div className="flex-none">
                            <Message set="light" size={22} />
                          </div>
                          <span>{config.get("orangery").official_email}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="basis-full lg:basis-7/12 lg:relative">
                    <div
                      className={`mx-auto px-0 ${
                        i18next.language === "en" ? "h-[91%]" : "h-full"
                      }`}
                    >
                      <Img
                        className="rounded-xl lg:h-full lg:ml-auto object-cover mt-4 lg:mt-0"
                        src={firstSectionImage}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="grid grid-cols-1 md:grid-cols-3 mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="col-span-1 md:col-span-2 md:pr-6">
                  <h2 className="text-base font-semibold tracking-wider text-black">
                    {t("Our services")}
                  </h2>
                  <p className="mt-2 mb-8 text-3xl font-extrabold text-black tracking-tight sm:text-4xl">
                    {t("orangery Coworking Features")}
                  </p>
                  {/* <ul className="mt-4 flex flex-wrap">
                    {services.map((item, index) => (
                      <li key={index} className='flex basis-1/2 sm:basis-1/3 flex-none py-3 pr-3 text-gray-700'>
                        {item.icon}{item.label}
                      </li>
                    ))}
                  </ul> */}
                  <FeaturesList />
                </div>
                <div className="mt-6 md:mt-24 -mb-2 sm:-mb-3 lg:-mb-6">
                  <Img className="rounded-lg" src={secondSectionImage} alt="" />
                </div>
              </div>
            </div>

            {/* Third section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="grid grid-cols-1 md:grid-cols-5 md:space-x-10 mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="md:col-span-2">
                  <h2 className="text-base font-semibold tracking-wider text-black">
                    {t("Our spaces")}
                  </h2>
                  <p className="mt-2 text-3xl font-extrabold text-black tracking-tight sm:text-4xl">
                    {t("_ourSpacesSubtitle")}
                  </p>
                  <p className="mt-10 text-justify">
                    {t("_ourSpacesBodyText")}
                  </p>
                </div>
                <div className="md:col-span-3">
                  <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5">
                    <Carousel
                      id="HomeOurSpacesCarousel"
                      noIndicators={true}
                      slides={
                        locations && Array.isArray(locations)
                          ? locations.map((location) => (
                              <LocationSlide
                                key={location.id}
                                location={location}
                              />
                            ))
                          : []
                        // (locationInfo.spacephotos && Array.isArray(locationInfo.spacephotos) && locationInfo.spacephotos.length > 0
                        //   ? locationInfo.spacephotos.map((spacephoto, index) => (
                        //     <Img
                        //       key={index}
                        //       className="block object-cover h-112 w-full rounded-lg"
                        //       src={spacephoto.file
                        //         ? spacephoto.file.filename
                        //           ? `${config.get('cdnUrl')}/${spacephoto.file.filename}`
                        //           : spacephoto.file.src
                        //             ? spacephoto.file.src
                        //             : ""
                        //         : ""
                        //       }
                        //       alt=""
                        //     />
                        //   ))
                        //   : []
                        // )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Forth section */}
            <div className="relative bg-gray-100 py-12 sm:py-20 lg:py-24">
              <Img
                className="absolute w-full h-full right-0 top-0 object-cover -z-10"
                src={forthSectionBackground}
                alt=""
              />
              <div className="flex flex-wrap mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  <h2 className="text-base font-semibold tracking-wider text-black">
                    {t("Customer testimonials")}
                  </h2>
                  <p className="mt-2 text-3xl font-extrabold text-black tracking-tight sm:text-4xl">
                    <span>{t("Hear from")}</span>{" "}
                    <span>{t("happy customers")}</span>
                  </p>
                  <p className="mt-10">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. */}
                  </p>
                </div>
                <div className="">
                  <div className="flex flex-wrap">
                    {customerQuotes.map((customerQuote, index) => (
                      <div
                        key={index}
                        className="basis-full md:basis-1/2 max-w-sm"
                      >
                        <blockquote className="px-6 py-4 m-4 bg-eighth rounded-2xl">
                          <svg
                            className="h-12 w-12 text-brandNew"
                            fill="currentColor"
                            viewBox="0 0 32 32"
                            aria-hidden="true"
                          >
                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                          </svg>
                          <div>
                            <p className="mt-1 text-base font-medium text-mainBackground text-justify">
                              {customerQuote.quote}
                            </p>
                          </div>
                          <footer className="mt-6 flex flex-row items-center justify-items-start">
                            <Img
                              className="flex-none h-12 w-12 rounded-full mr-4 object-cover"
                              src={customerQuote.avatar}
                              alt={customerQuote.name}
                            />
                            <div>
                              <p className="text-base font-bold text-mainBackground">
                                {customerQuote.name}
                              </p>
                              {customerQuote.position && (
                                <p className="text-sm font-normal text-gray-400">
                                  {customerQuote.position},
                                </p>
                              )}
                              <p className="text-sm font-normal text-gray-400">
                                {customerQuote.company}
                              </p>
                            </div>
                          </footer>
                        </blockquote>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Blog section * /}
            <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
              <div className="relative">
                <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                  <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">Learn</h2>
                  <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    Helpful Resources
                  </p>
                  <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                    Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
                    malesuada. Eleifend condimentum id viverra nulla.
                  </p>
                </div>
                <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
                  {blogPosts.map((post) => (
                    <div key={post.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                      <div className="flex-shrink-0">
                        <Img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                      </div>
                      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                        <div className="flex-1">
                          <p className="text-sm font-medium text-cyan-600">
                            <a href={post.category.href} className="hover:underline">
                              {post.category.name}
                            </a>
                          </p>
                          <a href={post.href} className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                            <p className="mt-3 text-base text-gray-500">{post.preview}</p>
                          </a>
                        </div>
                        <div className="mt-6 flex items-center">
                          <div className="flex-shrink-0">
                            <a href={post.author.href}>
                              <Img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt={post.author.name} />
                            </a>
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              <a href={post.author.href} className="hover:underline">
                                {post.author.name}
                              </a>
                            </p>
                            <div className="flex space-x-1 text-sm text-gray-500">
                              <time dateTime={post.datetime}>{post.date}</time>
                              <span aria-hidden="true">&middot;</span>
                              <span>{post.readingLength} read</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* CTA Section * /}
            <div className="relative bg-gray-900">
              <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <Img
                  className="w-full h-full object-cover"
                  src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                  alt=""
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
                />
              </div>
              <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                <div className="md:ml-auto md:w-1/2 md:pl-10">
                  <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                    Award winning support
                  </h2>
                  <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">We’re here to help</p>
                  <p className="mt-3 text-lg text-gray-300">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas tempus tellus etiam sed. Quam a
                    scelerisque amet ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat quisque ut interdum
                    tincidunt duis.
                  </p>
                  <div className="mt-8">
                    <div className="inline-flex rounded-md shadow">
                      <a
                        href="."
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                      >
                        Visit the help center
                        <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </main>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}
