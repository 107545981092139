/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

// import config from 'react-global-configuration';
import { AuthContext } from "../../context/authContext";
import i18next from "i18next";

import { createRef, Component, Fragment } from "react";

import { JustifyBetween, ModalContainer } from "../../components";

import { BookAMeetingRoomForm } from "../../implementedComponents";

import { Calendar, Location } from "react-iconly"; // , ChevronRight

// import locationIcon from '../../assets/images/icons/location.svg'

class BookAMeetingRoomCard extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);
    this.accessToken = context.accessToken;

    this.state = {
      selectBookingRoomModalShow: false,
      selectedPageIndex: 0,
    };

    this.myDatePickerRef = createRef();
  }

  toggleSelectBookingRoomModal = (show) => {
    this.setState({ selectBookingRoomModalShow: show });
  };

  handleClick = (e) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.selectedPageIndex = 0;
    this.setState({ selectedPageIndex: 0 });

    if (e && e !== null) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.toggleSelectBookingRoomModal(true);
  };

  handleModalClose = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.selectedPageIndex = 0;
    this.setState({ selectedPageIndex: 0 });
  };

  render() {
    const { officeUuid, locationName, meetingRooms } = this.props;

    return (
      <>
        <ModalContainer
          id="modalContianerMeetingRoom"
          show={this.state.selectBookingRoomModalShow}
          toggleShow={this.toggleSelectBookingRoomModal}
          onClose={this.handleModalClose}
          className={"w-11/12 md:max-w-6xl z-[999] h-full"}
          title={
            <div className="flex flex-col md:flex-row items-start md:items-center gap-1 md:gap-2">
              <span className="pl-4 md:pl-0 pr-3 md:border-r-2 mr-1 text-xl font-bold">
                {i18next.t("Book a meeting room")}
              </span>
              <div className="pl-4 md:pl-0 flex flex-row items-center gap-2">
                <Location set="light" size={20} />
                {/* <img
              className="h-5 w-5 mr-2"
              src={locationIcon}
              alt="icon"
            /> */}
                {locationName}
              </div>
            </div>
          }
        >
          <div className="flex flex-col flex-none w-full min-h-[360px] md:w-[700px] max-w-full h-full md:h-[400px] overflow-y-hidden pl-1 md:pl-3 pr-3 md:pr-6">
            <BookAMeetingRoomForm
              meetingRooms={meetingRooms}
              officeUuid={officeUuid}
              locationName={locationName}
              selectedPageIndex={this.state.selectedPageIndex}
              setSelectedPageIndex={(selectedPageIndex) =>
                this.setState({ selectedPageIndex: selectedPageIndex })
              }
            />
          </div>
        </ModalContainer>
        <div
          className={`flex flex-col space-y-3 grow group relative py-4 px-3 bg-openDays text-mainBackground rounded-lg overflow-hidden select-none cursor-pointer`}
          onClick={this.handleClick}
        >
          <JustifyBetween
            elementsClassName="flex items-start"
            left={
              <div className="space-x-2 flex flex-row text-white">
                <Calendar set="light" size={22} />
                <h3 className="text-base font-semibold">
                  {i18next.t("Book a meeting room")}
                </h3>
              </div>
            }
            right={""}
          />
        </div>
      </>
    );
  }
}

export default BookAMeetingRoomCard;
