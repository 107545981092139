/* eslint-disable jsx-a11y/alt-text */
// import { useEffect, useState } from "react";
import { Component } from "react/cjs/react.production.min";

import PlaceHolderImage from "../../assets/images/placeholder/placeholder-image.jpg";
// import PlaceHolderErrorImage from "../../assets/images/placeholder/placeholder-error-image.jpg";

// const { DefaultLoader } = require("../../components")

export default class Img extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgSrc: null,
      imgStatus: "notLoaded"
    }

    this.img = new Image();
    this.img.src = props.src;

    this.img.addEventListener("load", () => {
      this.setState({ imgSrc: props.src });
      this.setState({ imgStatus: "loaded" })
    });

    this.img.addEventListener("error", () => {
      this.setState({ imgSrc: null });
      this.setState({ imgStatus: "notFound" })
    });
  }

  render() {
    let { src, placeholderImg, errorImg, alt, ...props } = this.props

    if (!placeholderImg)
      placeholderImg = PlaceHolderImage;

    if (!errorImg)
      errorImg = PlaceHolderImage;
    // errorImg = PlaceHolderErrorImage;


    return (<>
      {src && this.state.imgStatus === "loaded"
        ? <img {...props} alt={alt ? alt : "orangery"} src={this.state.imgSrc} />
        : <img {...props} alt={alt ? alt : "orangery"} src={placeholderImg} />
        // : <DefaultLoader />
      }
    </>
    );
    // <img
    //   className={props.className}
    //   src={props.image}
    //   alt={props.alt}
    //   aria-hidden={props['aria-hidden']}
    // />
  }
};
