/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

// import config from 'react-global-configuration';

// import { AuthContext } from "../../context/authContext";
import i18next from 'i18next';

import { Component } from 'react'

// import { Button } from '..';
import OrangeryPrice from '../utilities/OrangeryPrice';

import {
  // Buy,
  People,
  // TimeCircle,
  Calendar
} from "react-iconly";
class HorizontalMeetingRoomCard extends Component {
  handlePickATimeButtonClick = (selectedMeetingRoom) => {
    if (this.props.onPickATimeButtonClick)
      this.props.onPickATimeButtonClick(1, selectedMeetingRoom)
  }

  render() {
    let { meetingRoom } = this.props

    return (
      <div
        key={this.props.key}
        className={`relative text-left flex flex-col w-full h-auto md:justify-between px-4 pt-3 pb-5 bg-[#F9F9FA] text-black rounded-lg overflow-hidden select-none border border-[1px solid #EDEEF0] ${this.props.className ? this.props.className : ""}`}
      >
        <p className="text-sm font-normal text-[#2B2E33]">
          {i18next.t("Your meeting room")}
        </p>
        <div className='flex flex-row items-center justify-start'>
          <h3 className="text-xl font-bold text-[#2B2E33]">
            {meetingRoom.name}
          </h3>

          <div className='flex flex-row space-x-6 items-center mt-1 ml-6'>
            <div className='flex flex-row space-x-2 items-center'>
              <div className='text-[#55585C]'>
                <People set='light' size={22} />
              </div>
              <div className='text-[#55585C] text-base font-semibold'>
                {i18next.t("Capacity")}:&nbsp;{meetingRoom.capacity}
              </div>
            </div>
            <div className='flex flex-row space-x-2 items-center'>
              <div className='text-[#55585C]'>
                <Calendar set='light' size={18} />
              </div>
              <div className='text-[#55585C] text-base font-semibold'>
                {new Date(this.props.selectedDate ?? "").toLocaleString("de-DE", {
                  month: "long",
                  year: "numeric",
                  day: "numeric",
                })}
              </div>
            </div>
          </div>
          <div className='flex flex-row space-x-3 ml-auto items-center'>
            <span className='text-2xl font-extrabold text-[#2B2E33]'>
              <OrangeryPrice euro={this.props.daily ? meetingRoom.daily_price : meetingRoom.price} germanFormat />
            </span>
            <span className='text-[#55585C] font-normal text-sm'>
              {this.props.daily ? "" : i18next.t("per hour")}
            </span>
          </div>
        </div>
        {/* 
        {
          !this.props.noButton
            ? <div className='basis-full flex justify-end pt-3 absolute right-3 bottom-3'>
              <Button
                onClick={(e) => this.handlePickATimeButtonClick(meetingRoom)}
                className="w-32 h-8 text-black bg-brand hover:bg-brand-900 font-normal text-sm rounded"
                text={<div className='flex items-center space-x-2'><TimeCircle set="light" size={16} /><span>{i18next.t("pick a time")}</span></div>}
              />
            </div>
            : ""
        } */}
      </div>
    )
  }
}

export default HorizontalMeetingRoomCard;