import config from "react-global-configuration";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { useTranslation } from "react-i18next";

import { createRef } from "react";

import { HorizontalForm, Accordion } from "../../components";
import { ChevronRight } from "react-iconly";

export default function BookATourForm(props) {
  const { t } = useTranslation();

  let accordionRef = createRef();

  const fields = {
    name: {
      label: "",
      id: "name",
      name: "name",
      type: "text",
      autocomplete: "name",
      placeholder: t("name"),
      errorMessage: t("Name is not valid"),

      valid: true,
      value: "",

      key: "name",
      trimmable: true,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 pb-1 pt-0 px-2",
      inputClassName: "h-10 text-base ",
      labelClassName: "font-medium",
    },
    last_name: {
      label: "",
      id: "last_name",
      name: "last_name",
      type: "text",
      autocomplete: "last_name",
      placeholder: t("last name"),
      errorMessage: t("Last name is not valid"),

      valid: true,
      value: "",

      key: "last_name",
      trimmable: true,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 pb-1 pt-0 px-2",
      inputClassName: "h-10 text-base",
      labelClassName: "font-medium",
    },
    email: {
      label: "",
      id: "email",
      name: "email",
      type: "email",
      autocomplete: "email",
      placeholder: "mail@website.com",
      errorMessage: t("Email is not valid"),

      valid: true,
      value: "",

      key: "email",
      trimmable: true,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 pb-1 pt-0 px-2",
      inputClassName: "h-10 text-base",
      labelClassName: "font-medium",
    },
    phone: {
      label: "",
      id: "phone",
      name: "phone",
      type: "text",
      placeholder: t("Phone number"),
      errorMessage: t("Phone number is not valid"),

      valid: true,
      value: "",

      key: "phone",
      trimmable: false,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 pb-1 pt-0 px-2",
      inputClassName: "h-10 text-base",
      labelClassName: "font-medium",
    },
    story: {
      label: "",
      id: "story",
      name: "story",
      type: "text",
      placeholder: t("I'm intereseted in..."),
      errorMessage: t("Text is not valid"),

      valid: true,
      value: "",

      key: "story",
      trimmable: true,
      required: false,

      className: "basis-full space-y-1 pb-1 pt-0 px-2",
      inputClassName: "h-10 text-base",
      labelClassName: "font-medium",
    },
    date: {
      label: "",
      id: "date",
      name: "date",
      type: "date",

      valid: true,
      value: new Date(),

      key: "date",
      trimmable: true,
      required: false,
    },
    location_id: {
      id: "location_id",
      name: "location_id",
      type: "hidden",

      valid: true,
      value: props.locationId,

      key: "location_id",
      trimmable: false,
      required: true,
    },
  };

  function handleSubmit() {
    accordionRef.current.triggerClick();
  }

  return props.tourLink ? (
    <div className="bg-eighth p-3 w-auto h-auto rounded-lg">
      <a
        className="flex flex-row cursor-pointer"
        href={props.tourLink}
        target="_blank"
        rel="noreferrer"
        // onClick={
        //   (e) => {
        //     if (e) {
        //       e.stopPropagation();
        //       e.preventDefault();
        //     }
        //     window.open(locationInfo.link)
        //   }
        // }
      >
        <ChevronRight
          set="light"
          primaryColor="white"
          size={28}
          className="my-auto"
        />
        <h2 className="pl-3 py-2 text-lg font-semibold text-mainBackground">
          {t("Book a tour")}
        </h2>
      </a>
    </div>
  ) : (
    <div className="bg-eighth p-3 w-auto h-auto rounded-lg">
      <h2 className="pt-2 text-lg font-semibold text-mainBackground">
        {t("Book a tour")}
      </h2>
      <Accordion
        ref={(el) => (accordionRef.current = el)}
        className="-mt-8 md:-mt-8"
        id="BookATourAccordion"
      >
        <HorizontalForm
          className="w-full h-auto"
          fields={fields}
          submitButtonText={t("Send request")}
          submitApiUrl={config.get("apiUrl") + `/booktour`}
          // cancellable={true}
          // cancelButtonText={"Cancel"}

          onSubmit={handleSubmit}
          // disabled={true}
          // editable={true}

          hasFile={false}
        />
      </Accordion>
    </div>
  );
}
