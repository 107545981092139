import { useState } from "react";
import config from "react-global-configuration";
import { useTranslation } from "react-i18next";
import { Img, ProfileAvatar, ProfileAvatarGroup, NewModal } from "..";

import OrangeryPrice from "../utilities/OrangeryPrice";

export default function EventCard({ key, event, onSubmitting, active }) {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);

  const handleShowModal = () => {
    setModalShow(true);
  };

  return (
    <div className="p-[15px] rounded-[5px] bg-secondaryWhite h-auto md:h-[191px] flex flex-col justify-between">
      <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
        <div className="flex flex-col md:flex-row gap-x-4">
          <Img
            className="w-[115px] h-[90px] object-cover self-center md:self-start mb-4 md:mb-0"
            src={
              event.images &&
              Array.isArray(event.images) &&
              event.images.length > 0 &&
              event.images[0]
                ? `${config.get("cdnUrl")}/${event.images[0].uuid}.jpg`
                : ""
            }
          />
          <div className="flex flex-col justify-between items-start mb-4 md:mb-0">
            <div className="flex flex-col text-lg font-bold mb-4 md:mb-0">{event.title}</div>
            <div className="flex flex-col items-center md:items-start">
              <div className="text-[13px] font-semibold text-secondaryGray">{t("Location")}</div>
              <div className="text-[14px] font-semibold text-secondaryBlack">
                {event.office_name ?? "-"} |{" "}
                {new Date(event.start_time).toLocaleString("de-DE", {
                  month: "long",
                  year: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hourCycle: "h24",
                })}
              </div>
            </div>
          </div>
        </div>
        {event.organizer?.name ? (
          <div className="flex items-center gap-x-[10px] mb-3 md:mb-0 ">
            <ProfileAvatar
              name={event.organizer?.name}
              image={event.organizer?.image}
              size="small"
            />
            <div className="flex flex-col items-start">
              <div className="text-[9px] font-semibold text-tertiaryGray">{t("Organized by")}</div>
              <div className="text-[16px] font-semibold text-secondaryBlack">
                {event.organizer?.name}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`flex flex-col items-center md:flex-row ${
          event.attendees && event.attendees.length > 0 ? `justify-between` : `justify-end`
        }`}
      >
        {event.attendees && event.attendees.length > 0 ? (
          <div className="flex flex-col items-center md:items-start">
            <div className="text-[14px] font-semibold text-secondaryGray">{t("Attendees")}</div>
            <ProfileAvatarGroup users={event.attendees} size="medium" />
          </div>
        ) : (
          <></>
        )}
        <div className="flex items-center flex-col md:flex-row gap-x-[10px] mt-4 md:mt-0">
          <div className="text-[19px] font-bold text-secondaryBlack mb-2 md:mb-0">
            {event.price === 0 ? "Free" : <OrangeryPrice euro={event.price} germanFormat />}
          </div>
          <button
            onClick={handleShowModal}
            className="bg-secondaryBlack w-[115px] h-[42px] text-white rounded-[45px]"
          >
            {event.price === 0 ? t("Attend") : t("Book")}
          </button>
        </div>
      </div>
      <NewModal event={event} open={modalShow} setOpen={setModalShow} />
    </div>
  );
}
