import 'tw-elements';
/* This example requires Tailwind CSS & tw-elements */

import { Component, createRef } from 'react';

export default class Accordion extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = createRef();
  }

  triggerClick = () => {
    this.buttonRef.current.click();
  }

  render() {
    return (
      <div className={`accordion accordion-flush${this.props.className ? " " + this.props.className : ""}`} id={this.props.id} >
        <div className="accordion-item border-t-0 border-l-0 border-r-0 rounded-none">
          <h2 className={`accordion-header mb-0 ${this.props.noButton ? "hidden" : ""}`} id={`${this.props.id}-flush-headingOne`}>
            <button
              ref={(el) => (this.buttonRef.current = el)}

              className="ml-auto h-10 md:h-10 2xl:h-10 w-10 md:w-10 2xl:w-10 accordion-button collapsed relative flex items-center text-base text-gray-800 text-left bg-gray-200 border-0 transition focus:outline-none rounded-full"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${this.props.id}-flush-collapseOne`}
              aria-expanded="false"
              aria-controls={`${this.props.id}-flush-collapseOne`}>
            </button>
          </h2>
          <div
            id={`${this.props.id}-flush-collapseOne`}
            className="accordion-collapse border-0 collapse"
            aria-labelledby={`${this.props.id}-flush-headingOne`}
            data-bs-parent={`#${this.props.id}`}
          >
            <div className="accordion-body">
              {this.props.children}
            </div>
          </div>
        </div>
        {/* <div className="accordion-item border-l-0 border-r-0 rounded-none bg-white border border-gray-200">
        <h2 className="accordion-header mb-0" id="flush-headingTwo">
          <button className="accordion-button
      collapsed
      relative
      flex
      items-center
      w-full
      py-4
      px-5
      text-base text-gray-800 text-left
      bg-white
      border-0
      rounded-none
      transition
      focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Accordion Item #2
          </button>
        </h2>
        <div id="flush-collapseTwo" className="accordion-collapse border-0 collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
        </div>
      </div>
      <div className="accordion-item border-l-0 border-r-0 border-b-0 rounded-none bg-white border border-gray-200">
        <h2 className="accordion-header mb-0" id="flush-headingThree">
          <button className="accordion-button
      collapsed
      relative
      flex
      items-center
      w-full
      py-4
      px-5
      text-base text-gray-800 text-left
      bg-white
      border-0
      rounded-none
      transition
      focus:outline-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Accordion Item #3
          </button>
        </h2>
        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body py-4 px-5">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
        </div>
      </div> */}
      </div >
    )
  }
}
