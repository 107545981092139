export default function OrangeryPrice({
  euro,
  germanFormat = true,
  withoutSymbol = false,
}) {
  if (!euro) euro = 0;

  return (
    new Intl.NumberFormat(germanFormat ? 'de-DE' : "", withoutSymbol ? {} : { style: 'currency', currency: 'EUR' }).format(euro)
  );
}