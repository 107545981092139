/*
  This example requires Tailwind CSS v2.0+ 
*/

// contexts
// import config from 'react-global-configuration';
import { useTranslation } from "react-i18next";

import { useState } from "react";

import {
  FeatureCard,
  ModalContainer,
  FeatureLargeCard,
} from "../../components";

// import { Star, Game } from 'react-iconly';  // , ShieldDone
// import {
//   IoCarOutline,
//   // IoBicycleOutline,
//   // IoLaptopOutline,
// } from "react-icons/io5";
// import {
//   GiKickScooter,
// } from 'react-icons/gi'
import {
  MdOutlineAccessTime,
  MdOutlineWifi,
  MdMeetingRoom,
} from "react-icons/md"; // , MdOutlineShower
// import {
//   RiParkingLine,
// } from 'react-icons/ri'
import { AiOutlinePrinter } from "react-icons/ai";
import { GoPackage } from "react-icons/go";
import { HiOutlineLightBulb } from "react-icons/hi";
import {
  // BsCheck2All,
  BsMailbox,
} from "react-icons/bs";
import { FaRegBell } from "react-icons/fa";

export default function FeaturesList(props) {
  const { t } = useTranslation();

  const features = [
    {
      label: t("Highspeed Internet"),
      icon: <MdOutlineWifi className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Highspeed Internet"),
    },
    // {
    //   label: t("Mobility Sharing"),
    //   icon: <IoCarOutline className="mr-3 w-7 h-7" aria-hidden="true" />,
    //   description: t("_description of Mobility Sharing")
    // },
    {
      label: t("Mail and Parcel Acceptance"),
      icon: <GoPackage className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Mail and Parcel Acceptance"),
    },
    {
      label: t("Concierge"),
      icon: <FaRegBell className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Concierge"),
    },
    {
      label: t("Printer and Scanner"),
      icon: <AiOutlinePrinter className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Printer and Scanner"),
    },
    {
      label: t("Business Community"),
      icon: <HiOutlineLightBulb className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Business Community"),
    },
    {
      label: t("Meeting & Conference Rooms"),
      icon: <MdMeetingRoom className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Meeting & Conference Rooms"),
    },
    {
      label: t("Mailbox Included"),
      icon: <BsMailbox className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Mailbox Included"),
    },
    {
      label: t("Access 24/7"),
      icon: <MdOutlineAccessTime className="mr-3 w-7 h-7" aria-hidden="true" />,
      description: t("_description of Access 24/7"),
    },
  ];

  const [allFeaturesModalShow, setAllFeaturesModalShow] = useState(false);
  function toggleAllFeaturesModalShow(show) {
    setAllFeaturesModalShow(show);
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ModalContainer
          id="modal"
          show={allFeaturesModalShow}
          toggleShow={toggleAllFeaturesModalShow}
          className={"h-full max-w-6xl"}
          title={t("Discover all features")}
          containerClassName={"p-6 pt-0"}
        >
          <div className="flex flex-col w-full sm:w-112 md:w-[700px] max-w-full h-96 overflow-y-auto max-h-screen space-y-4">
            {features.map((feature) => {
              return <FeatureLargeCard key={feature.label} feature={feature} />;
            })}
          </div>
        </ModalContainer>

        {features.map((feature) => {
          return <FeatureCard key={feature.label} feature={feature} />;
        })}
      </div>
      <a
        className=""
        href="."
        onClick={(e) => {
          if (e) {
            e.stopPropagation();
            e.preventDefault();
          }
          setAllFeaturesModalShow(true);
        }}
      >
        <h2 className="text-indigo-700 text-base font-medium text-left my-3">
          {t("Discover all features")}
        </h2>
      </a>
    </div>
  );
}
