import config from 'react-global-configuration';

import { Img, LocationCard } from "../../components"

export default function LocationSlide(props) {
  const { location } = props

  return (
    <div className="relative">
      <div className="flex flex-row space-x-0">
        <Img
          className="object-cover rounded-lg rounded-r-none w-[225px] h-48 sm:w-[450px] sm:h-96 z-0"
          src={location.spacephotos && Array.isArray(location.spacephotos) && location.spacephotos.length > 0 && location.spacephotos[0].file
            ? `${config.get('cdnUrl')}/${location.spacephotos[0].file.filename}`
            : ""}
          alt={`orangery ${location.name}`}
        />
        <div
          className="rounded-lg rounded-l-none w-[125px] sm:w-[250px] h-48 sm:h-96 overflow-hidden"
        >
          <iframe
            title={location.name}
            style={{ filter: "invert(90%)", border: 0 }}
            src={location.maplink
              ? location.maplink
              : ""
            }
            width="250"
            height="384"
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="absolute bottom-3 left-5">
        <LocationCard
          location={location}
        />
      </div>
    </div>
  )
}
