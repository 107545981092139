/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json"

// contexts
// import { AuthContext } from "../context/authContext";

import { useState, useEffect } from 'react'

import { Header, Footer } from '../implementedComponents'

const generals = require("../generals")

export default function AGB() {
  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    // let getRequested = false;
    // if (!getRequested) {
    //   getRequested = true;

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       // 'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //         // config.set({ locationOptions: tempList }, { freeze: false, environment: 'notFreeze' })
    //         // config.setEnvironment('notFreeze');
    //       }
    //     })
    //     // .catch((error) => {
    //     //   config.set("locationOptions", [])
    //     // })
    //     .then(function () {
    //       // always executed
    //     });
    // }
  }

  useEffect(() => {
    loadLocationOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-28 sm:pt-16 md:pt-22 pb-0 lg:pb-0">
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className=''>
                  <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    Allgemeine Geschäftsbedingungen <strong>orangery Coworking GmbH</strong>
                  </h1>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 1 Leistungsbeschreibung
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      <p>Gegenstand der Angebote und Dienstleistungen von der <strong>orangery Coworking GmbH</strong> ist die Bereitstellung von Räumlichkeiten sowie Büroarbeitsplätzen (Co-Working Spaces) einschließlich folgender Dienstleistungen:</p>
                      <ul class="list-disc mt-2 ml-6">
                        <li>
                          Internetzugang
                        </li>
                        <li>
                          Bereitstellung von Konferenzräumen (inkl. Technik, max 3 Stunden pro Tag)
                        </li>
                        <li>
                          Heiß- und Kaltgetränke
                        </li>
                        <li>
                          Zugang zu den Räumlichkeiten
                        </li>
                        <li>
                          Carsharing
                        </li>
                        <li>
                          Events
                        </li>
                      </ul>
                      <p>
                        Art und Umfang der Dienstleistung richten sich nach dem jeweils gewählten Pass des Vertragspartners, der im Nutzungsvertrag vereinbart wird. Je nach gewählten Pass ist die Nutzungsmöglichkeit auf eine bestimmte Art der Nutzung und/oder bestimmte Zeit beschränkt.
                      </p>
                    </li>
                    <li>
                      Die Büroarbeitsplätze sind ausgestattet mit: Tisch, Stuhl, Lampe, Strom, WLAN.
                    </li>
                    <li>
                      Der Nutzer hat die Ausstattung vor Beginn desVertragsverhältnisses ausführlich überprüft und deren Funktionsfähigkeit anerkannt.
                    </li>
                    <li>
                      Die Arbeitsplätze dürfen durch den Nutzer nurfür den im Vertrag bezeichneten Betrieb und den angegebenen Zweck benutzt werden. Eine Änderung des Betriebes bedarf der ausdrücklichen schriftlichen Zustimmung durch die <strong>orangery Coworking GmbH</strong>. Ein Verstoß gegen diese Bestimmung berechtigt die <strong>orangery Coworking GmbH</strong> zur fristlosen Kündigung.
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 2 Keine ungesetzliche oder unrechtmäßige Nutzung
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      Die Nutzung der von der <strong>orangery Coworking GmbH</strong> angebotenen Dienste für jeglichen ungesetzlichen oder in diesen Nutzungsbedingungen ausgeschlossenen Zweck ist unzulässig.
                    </li>
                    <li>
                      Der Nutzer verplichtet sich insbesondere, die
                      Dienste nicht in einer Art und Weise zu nutzen, die zur Beschädigung, Zerstörung, Überlastung oder sonstigen Unnutzbarkeit der von der <strong>orangery Coworking GmbH</strong> bereitgestellten Infrastruktur (wie Server, Netzwerk, Drucktechnik, Mobiliar) führen oder Störungen selbiger für andere Nutzer verursachen.
                    </li>
                    <li>
                      Der Nutzer unternimmt keine Versucheunberechtigten Zugriffs auf die Infrastruktur durch Hacking oder ähnliche Methoden.
                    </li>
                    <li>
                      Der Nutzer bestätigt, dass er die Dienste undInfrastruktur von der <strong>orangery Coworking GmbH</strong> für keine der im Folgenden aufgezählten Tätigkeiten nutzen wird:
                      <ol className='alphabetic mt-2 ml-6 space-y-2'>
                        <li>
                          Nutzung im Zusammenhang mit Gewinnspielen,MLM (Schneeballsystemen), Kettenbriefen, Spam-
                          E-Mail, oder sonstige Art von unerwünschten Nachrichten oder Werbung (sowohl privat, als auch geschäftlich);
                        </li>
                        <li>
                          Diffamierung, Missbrauch, Belästigung, Stalking,Bedrohung oder sonstige Verletzung gesetzlicher
                          Bestimmungen (wie Schutz der Privatsphäre, Persönlichkeitsrecht) von Personen oder Firmen inner- und außerhalb des Coworking-Space;
                        </li>
                        <li>
                          Verbreitung von sittenwidrigen, beleidigenden,pornografischen oder sonstigen ungesetzlichen Materialien oder Daten innerhalb oder über die
                          von der <strong>orangery Coworking GmbH</strong>
                          bereitgestellte Infrastruktur;
                        </li>
                        <li>
                          Verbreitung oder Bereitstellung von Daten, dieBilder, Fotografien, Bewegtbild, Software oder sonstiges Material enthalten, das Gesetzen zum Schutz von geistigem Eigentum (z.B. Markenrecht) unterliegt, es sei denn der Nutzer ist Rechte-Inhaber oder besitzt die Berechtigung zur
                          Verbreitung;
                        </li>
                        <li>
                          Verbreitung von Daten, die Viren, Trojaner,Würmer, Bots oder sonstige Schadsoftware enthalten;
                        </li>
                        <li>
                          illegaler Download von urheberrechtlichgeschützten Daten;
                        </li>
                        <li>
                          Behinderung oder Abhalten anderer Nutzer vomZugang und Anwendung der Services und Infrastruktur von der <strong>orangery Coworking GmbH</strong>;
                        </li>
                        <li>
                          unrechtmäßige Beschaffung von Informationen von anderen Nutzern, insbesondere auch deren E-Mail- Adressen, ohne deren Zustimmung; i. Angabe von falschen Identitätsdaten.
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 3 Zugangsbedingungen und Verhaltensregeln
                  </h2>
                  <p>
                    Der Zugang zur der <strong>orangery Coworking GmbH</strong> ist je nach gewähltem Pass nur zu den Öffnungszeiten zwischen 8 und 18 Uhr, Montag bis
                    Freitag, oder mit eigenem Schlüssel zu jeder Tages- und Nachtzeit an 7 Tagen der Woche möglich. Der Nutzer erkennt die Öffnungszeiten ausdrücklich an.
                  </p>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 4 Pässe und Zahlungsmodalitäten, Kaution
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      Alle Preise sind Nettopreise zzgl. der jeweils gültigen, gesetzlichen Umsatzsteuer und beziehen sich nur auf die angegebenen Dienstleistungen. Darüberhinausgehende Servicedienstleistungen sind gesondert zu vergüten. Es gelten hierfür die jeweils gesondert ausgewiesenen Pässe/Preise von der <strong>orangery Coworking GmbH</strong>.
                    </li>
                    <li>
                      Die Nutzungsgebühr ist unmittelbar mit dem
                      Vertragsbeginn fällig.  Eine laufende Nutzungsgebühr ist am dritten Werktag eines jeden Monats fällig. Der Nutzer hat die Zahlung auf das angegebene Konto des Betreibers, für diesen kostenfrei, zu leisten. Maßgeblich für die Rechtzeitigkeit der Zahlung ist der Eingang auf dem Konto des Betreibers.
                    </li>
                    <li>
                      Für jeden ausgehändigten Schlüssel zahlt derNutzer bei Schlüsselübergabe eine Kaution von 50,00 €. Die Kaution wird nicht verzinst.
                    </li>
                    <li>
                      Die generelle Kaution beträgt einen NettoMonatsbeitrag.
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 5 Dauer des Vertrages und Beendigung
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      Der Vertrag gilt auf unbestimmte Zeit.
                    </li>
                    <li>
                      Das Vertragsverhältnis kann von beiden Parteien schriftlich mit einer Frist von 4 Wochen zum Monatsende gekündigt werden. (3) Die <strong>orangery Coworking GmbH</strong> kann das Vertragsverhältnis ohne Einhaltung einer Kündigungsfrist mit sofortiger Wirkung kündigen, wenn der Kunde:
                      <ol className='alphabetic mt-2 ml-6 space-y-2'>
                        <li>
                          mit der Nutzungsgebühr in Höhe von zwei Monatsbeiträgen in Verzug ist;
                        </li>
                        <li>
                          trotz Mahnung unregelmäßige oderunvollständige Zahlungen leistet.
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 6 Datenschutz
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      Die <strong>orangery Coworking GmbH</strong> wird die Vorschriften über den Datenschutz nach dem Bundesdatenschutzgesetz (BDSG) und den weiteren gesetzlichen Vorschriften zum Datenschutz beachten.
                    </li>
                    <li>
                      Der Nutzer erklärt sein Einverständnis damit, dass seine für die Vertragsdurchführung notwendigen persönlichen Daten auf Datenträgern gespeichert werden. Der Kunde willigt ferner in die Übermittlung seiner zur Bonitätsprüfung notwendigen persönlichen Daten an ein Auskunftsbüro ein. Sämtliche Daten werden durch die <strong>orangery Coworking GmbH</strong>, sowie berechtigte Dritte vertraulich behandelt. (3) Dem Nutzer steht das Recht zu, seine Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen.
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 7 Haftung
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      Der Nutzer hat die Arbeitsplätze vor Vertragsschluss eingehend besichtigt. Er hat zur Kenntnis genommen, dass sich die Arbeitsplätze in einem Großraumbüro befinden und nicht separat verschließbar sind. Ausnahme hierbei sind die verfügbaren Einzelbüros. Der Nutzer  verzichtet wegen des ihm bekannten Zustands auf etwaige Ansprüche gemäß §§ 536, 536 a BGB. Minderungsansprüche bestehen insoweit nicht.  Der Kunde erkennt an, dass sich der jeweils von ihm genutzte Arbeitsplatz einschließlich sämtlicher Einrichtungsgegenstände vor Nutzungsbeginn in vertragsgemäßen Zustand befindet.
                    </li>
                    <li>
                      In allen Fällen, in denen die <strong>orangery Coworking GmbH</strong> im geschäftlichen Verkehr aufgrund vertraglicher oder gesetzlicher Anspruchsgrundlagen zum Schadens- oder Aufwendungsersatz verpflichtet ist, haftet die <strong>orangery Coworking GmbH</strong> nur, soweit ihr Vorsatz, grobe Fahrlässigkeit, oder eine Verletzung von Leben, Körper oder Gesundheit zur Last fällt. Hiervon unberührt bleibt die Haftung für die schuldhafte Verletzung wesentlicher Vertragspflichten und Garantien. Die Haftung ist jedoch insofern auf den vorhersehbaren, vertragstypischen Schaden beschränkt. Die Haftung für Folgeschäden, insbesondere auf entgangenen Gewinn oder Ersatz von Schäden Dritter, wird ausgeschlossen, es sei denn, der <strong>orangery Coworking GmbH</strong> fällt Vorsatz oder grobe Fahrlässigkeit zur Last.
                    </li>
                    <li>
                      Die <strong>orangery Coworking GmbH</strong> übernimmt keine Haftung für die Verletzung von Schutzrechten Dritter im Bezug auf Arbeiten der Kunden, sowie die Übermittlung von Daten und Datenträgern durch den Kunden. Der Kunde ist dafür verantwortlich, dass alle wettbewerbsrechtlichen, urheberrechtlichen, markenrechtlichen, datenrechtlichen oder sonstige Rechtsverstöße im Rahmen der Vertragsbeziehung zu der <strong>orangery Coworking GmbH</strong> unterbleiben. Sofern die <strong>orangery Coworking GmbH</strong> von derartigen Rechtsverstößen Kenntnis erhält, wird das Vertragsverhältnis unverzüglich gekündigt. Im Falle eines Rechtsverstoßes hält der Kunde die <strong>orangery Coworking GmbH</strong> von jeglichen Ansprüchen Dritter frei. Der Kunde ersetzt der <strong>orangery Coworking GmbH</strong> die Kosten der Rechtsverfolgung in der Höhe der gesetzlichen Anwaltsgebühren für den Fall, dass die <strong>orangery Coworking GmbH</strong> von Dritten infolge einer Rechtsverletzung in Anspruch genommen wird.
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 8 Versicherung
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      <strong>orangery Coworking GmbH</strong> ist in ihrer Geschäftsätigkeit versichert. Es besteht jedoch kein Versicherungsschutz für persönliche Gegenstände der Nutzer. Hierfür wird der Abschluss einer geeigneten persönlichen Versicherung empfohlen.
                    </li>
                  </ol>

                  <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mt-4">
                    § 9 Schlussbestimmungen und Schriftformklausel
                  </h2>
                  <ol className='parantheses mt-2 ml-6 space-y-2'>
                    <li>
                      Erweisen sich einzelne Bestimmungen desVertrags als unwirksam, so bleibt der Vertrag im Übrigen wirksam.
                    </li>
                    <li>
                      Die unwirksame Bestimmung ist durch einewirksame Bestimmung zu ersetzen, die den Ansprüchen der Gesellschafter und der Nutzer sowie dem Sinn und Zweck des Vertrages am Meisten entsprechen würde.
                    </li>
                    <li>
                      Alle Änderungen des Vertrages bedürfen derSchriftform. Dies gilt auch für die Änderung des Schriftformklausel.
                    </li>
                    <li>
                      Änderungen und Irrtümer vorbehalten
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </main>
          <Footer locationOptions={locationOptions} />
        </div>
      </div >
    </>
  )
}
