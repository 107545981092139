/*
  This example requires Tailwind CSS v2.0+ 
*/

// contexts
import config from 'react-global-configuration';
import { AuthContext } from "../context/authContext";

import { useState } from 'react'

import { useEffect, useContext, createRef } from 'react'
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { PlanMediumCard, Button, Modal, CheckBox, A } from '../components'
import { Header, Footer } from '../implementedComponents'

const generals = require("../generals")

export default function Checkout(props) {
  const { accessToken } = useContext(AuthContext)

  const { t } = useTranslation();

  let urlObj = new URL(window.location.href);
  let officePlanId = urlObj.searchParams.get("id");
  let date = urlObj.searchParams.get("date");

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalOnSubmit, setModalOnSubmit] = useState(() => { });
  function toggleModal(show) {
    setModalShow(show);
  }

  const [waiting, setWaiting] = useState(false);

  let checkBoxRef = createRef();

  const [officePlan, setOfficePlan] = useState(null)
  function loadOfficePlan() {
    let data = {
      officeplan_id: officePlanId
    };
    let url = `${config.get('apiUrl')}/checkout`;
    let headers = {
      timeout: config.get('apiCallTimeout')
    }
    axios.post(url, data, { headers })
      .then(res => {
        if (res.data.result)
          setOfficePlan(res.data.data)
        else {
          setModalText(res.data.message);
          setModalShow(true);
        }
      })
      .catch(err => {
        setModalText(err + " ");
        setModalShow(true);
      });
  }

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocations() {
    let getRequested = false;
    if (!getRequested) {
      getRequested = true;

      let url = `${config.get('apiUrl')}/getlocations`;
      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
        },
      }
      axios.get(url, configs)
        .then((response) => {
          let data = response.data.data;
          if (response.data.result) {
            let tempList = generals.toList(data, "name", "name");
            setLocationOptions(tempList);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function handleSubmit(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    let valid = true;
    if (officePlan.duration === "monthly")
      valid = checkBoxRef.current.validate() && valid

    if (valid) {
      setWaiting(true)

      let url = `${config.get('apiUrl')}/bookofficeplan`;

      let returnUrl = `${config.get('appUrl')}/payment_result`;
      let data = {
        officeplan_id: officePlan.id,
        url: returnUrl,
      };
      if (officePlan.duration === "daily")
        data["date"] = date

      let headers = {
        'x-access-token': accessToken,
        timeout: config.get('apiCallTimeout')
      }

      axios.post(url, data, { headers })
        .then(res => {
          setWaiting(false);

          if (props.onSubmit)
            props.onSubmit(res.data.data);

          if (res.data && res.data.data && res.data.data.url && (typeof res.data.data.url === 'string' || res.data.data.url instanceof String) && res.data.data.url !== '')
            generals.goTo(res.data.data.url)
          else {
            setModalText(res.data.message);
            setModalShow(true);

            // switch (res.data.error_code) {
            //   case 201:
            //   case '201':
            //     setModalSubmitButtonText("Go to dashboard");
            //     break;

            //   default:
            // }

            setModalOnSubmit(() => {
              if (res.data && !res.data.result) {
                switch (res.data.error_code) {
                  case 201:
                  case '201':
                    if (props.onSubmitting)
                      props.onSubmitting(false);

                    generals.goTo(`${config.get('appUrl')}/dashboard`, 0)
                    break;

                  case undefined:
                    if (props.onSubmitting)
                      props.onSubmitting(false);

                    // if (res.data.result === false && res.data.data === false)   // token is invalid
                    //   generals.goTo(`${config.get('appUrl')}/login?dest=location&id=${this.props.locationId}`, 0)

                    break;

                  default:
                    if (props.onSubmitting)
                      props.onSubmitting(false);
                }
              }
            })
          }
        })
        .catch(err => {
          setWaiting(false);

          setModalText(err + "");
          setModalShow(true);

          setModalOnSubmit(() => {
            let res = { data: { result: true, error_code: 201 } }
            if (res.data && !res.data.result) {
              switch (res.data.error_code) {
                case 201:
                case '201':
                  if (props.onSubmitting)
                    props.onSubmitting(false);

                  generals.goTo(`${config.get('appUrl')}/dashboard`, 0)
                  break;

                case undefined:
                  if (props.onSubmitting)
                    props.onSubmitting(false);

                  // if (res.data.result === false && res.data.data === false)   // token is invalid
                  //   generals.goTo(`${config.get('appUrl')}/login?dest=location&id=${this.props.locationId}`, 0)

                  break;

                default:
                  if (props.onSubmitting)
                    props.onSubmitting(false);
              }
            }
          })
        });
    }
  }

  useEffect(() => {
    loadOfficePlan()
    loadLocations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    officePlan ? <>
      <Modal
        id="modal"
        text={modalText}
        show={modalShow}
        toggleModal={toggleModal}

        // modalSubmitButtonText={modalSubmitButtonText}
        onSubmit={modalOnSubmit}
      // onClose={modalOnSubmit}
      />
      <div className="bg-gray-50">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />

          <main className="max-w-7xl mx-auto mt-32 pb-24 px-4 sm:px-6">
            <div className="max-w-2xl mx-auto lg:max-w-none">
              <h1 className="sr-only">Checkout</h1>

              <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                <div>
                  <div className="mt-10 border-t border-gray-200 pt-10">
                    <h2 className="text-lg font-medium text-gray-900">{t("Plan information")}</h2>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                      <div>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                          Plan
                        </label>
                        <div className="mt-1">
                          {officePlan.name}{" "}({officePlan.office_type}, {officePlan.duration})
                        </div>
                      </div>

                      <div>
                        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                          {t("Location")}
                        </label>
                        <div className="mt-1">
                          {officePlan.location.name}
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                          {t("Description")}
                        </label>
                        <div className="mt-1">
                          {officePlan.description}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 border-t border-gray-200 py-6 px-4 sm:px-6 space-y-3">
                    <div className='mx-2 space-y-3'>
                      <CheckBox
                        ref={el => { checkBoxRef.current = el; }}

                        field={{
                          label: <span>{t("I agree to the")}{' '}<A href={config.get('orangery').termsAndConditions} text={t("Terms & Conditions")} />{' '}{t("_to")}</span>,
                          id: "agree-terms",
                          name: "agree-terms",
                          type: "checkbox",
                          errorMessage: t("Should checked"),

                          valid: true,
                          value: accessToken ? true : false,

                          key: "agree-terms",
                          trimmable: false,
                          required: true,
                        }}
                      />
                    </div>
                    <Button
                      onClick={handleSubmit}
                      className="mt-4 w-full bg-brand border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-black hover:bg-brand-900 focus:outline-none"
                      text={t("Pay")}
                      waiting={waiting}
                    />
                  </div>
                </div>

                {/* Order summary */}
                <div className="mt-10 lg:mt-0">
                  <h3 className="sr-only">Items in your cart</h3>
                  <PlanMediumCard
                    officePlan={officePlan}
                    date={date}

                    notShowSetCurrentPlanButton={true}
                  />
                  <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    {officePlan.checkout
                      && <>
                        <dl className=" py-6 px-4 space-y-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <dt className="text-sm">Price</dt>
                            <dd className="text-sm font-medium text-gray-900">€{officePlan.checkout.office_price}</dd>
                          </div>
                          {officePlan.checkout.deposit
                            && <div className="flex items-center justify-between">
                              <dt className="text-sm">{t("Deposit")}</dt>
                              <dd className="text-sm font-medium text-gray-900">€{officePlan.checkout.deposit}</dd>
                            </div>
                          }
                          <div className="flex items-center justify-between border-b">
                            <dt className="text-sm">Subtotal</dt>
                            <dd className="text-sm font-medium text-gray-900">€{officePlan.checkout.subtotal}</dd>
                          </div>
                          {officePlan.checkout.coupon
                            && <div className="flex items-center justify-between">
                              <dt className="text-sm">Coupon <span className='text-gray-500"'>{officePlan.checkout.coupon_percent && `(${officePlan.checkout.coupon_percent}% off)`}</span></dt>
                              <dd className="text-sm font-base text-gray-900">-€{officePlan.checkout.coupon}</dd>
                            </div>
                          }
                          <div className="flex items-center justify-between">
                            <dt className="text-sm"><span className='text-gray-500"'>Tax</span></dt>
                            <dd className="text-sm font-base text-gray-900">€{officePlan.checkout.vat}</dd>
                          </div>
                          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                            <dt className="text-base font-medium">Total</dt>
                            <dd className="text-base font-medium text-gray-900">€{officePlan.checkout.total_due}</dd>
                          </div>
                        </dl>

                        {officePlan.checkout.coupon
                          && <p className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                            Die erste Zahlung kann niedriger ausfallen, da sie anteilig von morgen bis zum Ende dieses Kalendermonats berechnet wird. Nach Ablauf eines Einführungspreises oder eines Aktionscodes wird Ihre Mitgliedschaft mit einer monatlichen Gebühr von {officePlan.checkout.monthly ? officePlan.checkout.monthly : Number.parseFloat(officePlan.checkout.office_price) + Number.parseFloat(officePlan.checkout.vat).toFixed(2)} €/Monat pro Mitgliedschaft belastet. Die von Ihnen gewählte Zahlungsmethode wird automatisch am oder um den ersten Tag eines jeden Monats mit diesem Betrag belastet, es sei denn, Sie deaktivieren Ihre Mitgliedschaft mindestens einen (1) Tag vor dem letzten Tag eines Kalendermonats in Übereinstimmung mit den <A href={config.get('orangery').termsAndConditions} text={t("Terms & Conditions")} /> der <strong>orangery Coworking GmbH</strong>, indem Sie uns unter <a href={`mailto:${config.get('orangery').official_email}`}>{config.get('orangery').official_email}</a> kontaktieren.
                          </p>
                        }
                      </>
                    }
                  </div>
                </div>
              </form>
            </div>
          </main>

          <Footer
            locationOptions={locationOptions}
          />
        </div>
      </div >
    </>
      : ""
  )
}
