// contexts
import config from "react-global-configuration";
// import { AuthContext } from "../../context/authContext";
import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";
import axios from "axios";

import { Modal } from "../../components";

import HubspotForm from "react-hubspot-form";

export default function SubscribeEMailForm(props) {
  // const { accessToken } = useContext(AuthContext);
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [waiting, setWaiting] = useState(false);

  function toggleModal(show) {
    setModalShow(show);
  }

  const [email, setEmail] = useState(props.email ? props.email : "");

  useEffect(() => {
    setEmail(props.email ? props.email : "");
  }, [props.email]);

  function onSubscribeClick(e) {
    if (e !== null) e.preventDefault();

    if (!props.hubspot) {
      if (email) {
        setWaiting(true);

        let data = {
          // officeplan_id: props.offerUuid,
          email: email,
        };
        // let url = `${config.get("apiUrl")}/subscribe`;
        let url = `${config.get("osApiUrl")}/public-product/${props.offerUuid}/waitlist`;
        let headers = {
          // "x-access-token": accessToken,
          // timeout: config.get("apiCallTimeout"),
        };
        axios
          .post(url, data, { headers })
          .then((res) => {
            try {
              if (window)
                // <!-- Event snippet for Newsletter abonniert conversion page -->
                window["gtag"]("event", "conversion", {
                  send_to: "AW-615501991/iS13COOH3ckDEKehv6UC",
                });
            } catch (err) {
              console.warn({ err });
              // state.setupErrors(err);
            }

            setWaiting(false);

            setEmail("");

            setModalText(res.data.data && res.data.success ? t("Your email has been subscribed.") : t("A problem has been occurred during email subscription."));
            setModalShow(true);
          })
          .catch((err) => {
            setWaiting(false);

            setModalText(err + " ");
            setModalShow(true);
          });
      } else {
      }
    } else {
      // // method 1
      let data = {
        fields: [
          {
            objectTypeId: "0-1",
            name: "email",
            value: email,
          },
        ],
      };
      let url =
        "https://api.hsforms.com/submissions/v3/integration/submit/7914845/5eaf39f9-6e3a-4406-b4e1-e1baa92cc780"; // ?hapikey=13999338-27b7-4dec-bcf4-ffb5ccfbe062
      let headers = {
        accept: "application/json",
        "content-type": "application/json",
        // "authorization": `Bearer ${config.get('hubSpotAccessToken')}`
      };
      axios
        .post(url, data, { headers })
        .then((res) => {
          try {
            if (window)
              // <!-- Event snippet for Newsletter abonniert conversion page -->
              window["gtag"]("event", "conversion", {
                send_to: "AW-615501991/iS13COOH3ckDEKehv6UC",
              });
          } catch (err) {
            console.warn({ err });
            // state.setupErrors(err);
          }

          setWaiting(false);

          setEmail("");

          // setModalText(res.data.message);
          setModalText(t("Your email has been subscribed."));
          setModalShow(true);
        })
        .catch((err) => {
          setWaiting(false);

          setModalText(err.message + " ");
          setModalShow(true);
        });

      // // method 2 - not working due to CORS
      // let data = {
      //   emailAddress: email,
      //   subscriptionId: Math.random(1, 1e5).toString() + Math.random(1, 1e5).toString() + Math.random(1, 1e5).toString(),
      //   // legalBasis: 'LEGITIMATE_INTEREST_PQL',
      //   // legalBasisExplanation: 'string'
      // };
      // let url = "https://api.hubapi.com/communication-preferences/v3/subscribe" // ?hapikey=13999338-27b7-4dec-bcf4-ffb5ccfbe062
      // let headers = {
      //   "accept": "application/json",
      //   "content-type": "application/json",
      //   "authorization": `Bearer ${config.get('hubSpotAccessToken')}`
      // }
      // axios.post(url, data, { headers })
      //   .then(res => {
      //     try {
      //       if (window)
      //         // <!-- Event snippet for Newsletter abonniert conversion page -->
      //         window["gtag"]('event', 'conversion', { 'send_to': 'AW-615501991/iS13COOH3ckDEKehv6UC' });
      //     } catch (err) {
      //       console.warn({ err })
      //       // state.setupErrors(err);
      //     }

      //     setWaiting(false)

      //     setEmail("")

      //     // setModalText(res.data.message);
      //     setModalText(t("Your email has been subscribed."));
      //     setModalShow(true);
      //   })
      //   .catch(err => {
      //     setWaiting(false)

      //     setModalText(err.message + " ");
      //     setModalShow(true);
      //   });
    }
  }

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  return false && props.hubspot ? ( ////////!!!!!!!!
    <HubspotForm
      region="na1"
      portalId="7914845"
      formId="5eaf39f9-6e3a-4406-b4e1-e1baa92cc780"
      onSubmit={() => { }}
      onReady={(form) => { }}
      loading={<div>Loading...</div>}
    />
  ) : (
    <form className="mt-4 sm:flex sm:max-w-md">
      <Modal
        id="modal"
        text={modalText}
        show={modalShow}
        toggleModal={toggleModal}
      />
      <label htmlFor="email-address" className="sr-only">
        {t("Email address")}
      </label>
      <input
        type="email"
        name="email-address"
        id="email-address"
        autoComplete="email"
        required
        className="appearance-none min-w-0 w-full bg-eighth border text-mainBackground border-gray-300 rounded-md py-3 px-4 text-base placeholder-white focus:outline-none focus:ring-brand focus:border-brand focus:placeholder-gray-400"
        placeholder={`${t("Email")} *`}
        value={email}
        onChange={onEmailChange}
      />
      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          className="w-full flex items-center justify-center text-eighth bg-brandNew px-4 py-3 border border-transparent text-base font-medium rounded-md hover:bg-brandNewLight"
          onClick={onSubscribeClick}
        >
          {props.submitButtonText}
        </button>
      </div>
    </form>
  );
}
