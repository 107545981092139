import { Component } from 'react';
import { Spinner, Img } from '../../components';

class Button extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //   }
  // }

  // need when using props for initializing state
  // componentWillReceiveProps(nextProps) {
  // }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //   };
  // }

  render() {
    return (
      <button
        onClick={this.props.onClick}
        className={
          "py-2 border border-transparent inline-flex justify-center items-center "
          + (this.props.className
            ? this.props.className
            + (
              this.props.className.indexOf("rounded") === -1
                ? " rounded-md "
                : "")
            : ` w-44 h-12 ${this.props.disabled ? "cursor-not-allowed text-white bg-gray-500 hover:bg-gray-500" : "cursor-pointer text-black bg-brand hover:bg-brand-900"} font-medium rounded-lg`
          )}
        disabled={this.props.waiting || this.props.disabled}
      >
        {this.props.icon && !this.props.waiting &&
          <Img
            image={this.props.icon}
            className="mr-1 h-full w-auto"
            aria-hidden="true"
          />
        }
        {this.props.waiting &&
          <div className='mr-2'>
            <Spinner />
          </div>
        }
        <span className='align-middle'>{this.props.waiting && this.props.waitingText ? this.props.waitingText : this.props.text}</span>
      </button>
    );
  }
}

export default Button;