/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'

export default function PaginationContainer(props) {
  const [selectedPageIndex, setSelectedPageIndex] = useState(props.selectedPageIndex)

  useEffect(() => {
    setSelectedPageIndex(props.selectedPageIndex)
  }, [props.selectedPageIndex])

  return (
    props.pages && Array.isArray(props.pages) && props.pages.length > 0
      ? props.pages.map((page, index) => (
        <Transition
          className="h-full"
          key={index}

          show={index === selectedPageIndex}

          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-0 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          {page}
        </Transition >
      ))
      : ""
  )
}