/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import config from "react-global-configuration";
import { AuthContext } from "../../context/authContext";
import i18next from "i18next";

import { createRef, Component } from "react";
import ReactTooltip from "react-tooltip";

import {
  JustifyBetween,
  BookingButton,
  Modal,
  Accordion,
  MyDatePicker,
  ModalContainer,
} from "..";
import OrangeryPrice from "../utilities/OrangeryPrice";

import { Calendar, ChevronRight } from "react-iconly";
import SubscribeEMailForm from "./subscribeEMailForm";

const generals = require("../../generals");

class OfferCard extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);
    this.accessToken = context.accessToken;
    this.user = context.user;

    let { offer } = props;

    this.state = {
      modalShow: false,
      modalText: "",
      waiting: false,

      modalSubmitButtonText: "",
      modalOnSubmit: () => { },

      pickedDate: null,

      informMeModalShow: false,
    };

    this.available = offer.available ?? false;

    this.myDatePickerRef = createRef();
    this.accordionRef = createRef();
  }

  toggleModal = (show) => {
    this.setState({ modalShow: show });
  };

  onPickedDateChange = (value) => {
    this.setState({ pickedDate: value });
  };

  

  handleSubmit = (e = null) => {
    e?.preventDefault();
    e?.stopPropagation();

    let offer = this.props.offer;

    if (!this.available) {
      this.toggleInformMeModal(true);
    } else if (!this.props.disabled)
      if (this.props.active) {
        // if (offer.date_picker && !this.state.pickedDate) {
        //   this.accordionRef.current.triggerClick();
        //   this.myDatePickerRef.current.setValue(new Date())
        // } else
        ////////!!!!!!!!
        this.setState({ waiting: true });

        if (this.props.onSubmitting) this.props.onSubmitting(true, offer.name);

        offer &&
          generals.goTo(
            `${config.get("osCheckoutUrl")}/offer/?id=${offer.uuid}${offer.date_picker
              ? `&date=${this.myDatePickerRef.current.getValue()}`
              : ""
            }&returnUrl=${window.location.href}`
          );
      } else {
      }
  };

  toggleInformMeModal = (show) => {
    this.setState({ informMeModalShow: show });
  };

  render() {
    let thisRand = Math.random();

    let { offer } = this.props;

    return (
      <div
        onClick={(e) => this.handleSubmit(e)}
        className={
          `flex flex-col space-y-3 grow group relative py-1 px-5 pb-3  text-eighth rounded-lg overflow-hidden select-none 
          ${!this.props.disabled ? " cursor-pointer" : " cursor-not-allowed"}` + // !offer.date_picker ?
          (!this.props.disabled ? " bg-brandNew" : " bg-gray-200")
        }
      >
        {this.props.ribbon && this.props.ribbon}
        <Modal
          id="modal"
          text={this.state.modalText}
          show={this.state.modalShow}
          toggleModal={this.toggleModal}
          modalSubmitButtonText={this.state.modalSubmitButtonText}
          onSubmit={this.state.modalOnSubmit}
        // onClose={this.state.modalOnSubmit}
        />
        <ModalContainer
          id="modal"
          show={this.state.informMeModalShow}
          toggleShow={this.toggleInformMeModal}
          className={"max-w-6xl"}
        >
          <div className="flex flex-col overflow-y-auto max-w-md p-4 pt-5">
            Momentan wächst und wächst unsere Community. Wir sind schon auf
            Hochtouren dabei, dass wir neue Workspaces eröffnen und planen.
            Trage Dich einfach auf unsere Warteliste ein
            <SubscribeEMailForm
              offerUuid={offer.uuid ?? ""}
              email={this.user ? this.user.email : ""}
              submitButtonText={i18next.t("Send")}
            />
          </div>
        </ModalContainer>
        <JustifyBetween
          elementsClassName="flex items-start"
          left={
            <>
              <h3 className="text-base font-semibold text-gray-900">
                {offer.name}
              </h3>
            </>
          }
          right={
            <div className="text-sm ml-3 justify-end">
              {!this.props.noDate
                ? this.available
                  ? offer.contract_start_date &&
                    new Date() < new Date(offer.contract_start_date)
                    ? new Date(offer.contract_start_date).toLocaleString(
                      "de-DE",
                      {
                        month: "long",
                        year: "numeric",
                        day: "numeric",
                      }
                    ) +
                    (offer.contract_end_date
                      ? " - " +
                      new Date(offer.contract_end_date).toLocaleString(
                        "de-DE",
                        {
                          month: "long",
                          year: "numeric",
                          day: "numeric",
                        }
                      )
                      : "")
                    : i18next.t("available")
                  : i18next.t("unavailable")
                : ""}
            </div>
          }
        />
        <p className="text-sm font-normal text-gray-600">
          {offer.description.length < 250
            ? offer.description
            : offer.description.substring(0, 247) + "..."}
        </p>
        <JustifyBetween
          elementsClassName="flex items-center"
          left={
            <>
              <p>
                {offer.sale_price &&
                  parseFloat(offer.sale_price) > 0 &&
                  parseFloat(offer.sale_price) < parseFloat(offer.price) ? (
                  <>
                    <s className="text-sm font-medium text-gray-500">
                      <OrangeryPrice euro={offer.price} germanFormat />
                    </s>
                    &nbsp;
                    <span className="text-base font-medium text-gray-900">
                      <OrangeryPrice euro={offer.sale_price} germanFormat />
                    </span>
                  </>
                ) : (
                  <span className="text-base font-medium text-gray-900">
                    <OrangeryPrice euro={offer.price} germanFormat />
                  </span>
                )}
                {offer.name.toLowerCase().indexOf("black friday") === -1 && (
                  <>
                    {" "}
                    <span className="text-gray-500 text-sm">
                      {offer.one_time_payment
                        ? `(${i18next.t("per day")})`
                        : `(${i18next.t("per month")})`}
                    </span>
                  </>
                )}
              </p>
            </>
          }
          right={
            <>
              <BookingButton
                data-tip
                data-for={"bookingButtonTooltip" + thisRand}
                icon={
                  this.available ? (
                    offer.date_picker ? (
                      this.state.pickedDate ? (
                        <div className="pl-3 pr-2 flex flex-row items-center place-items-center">
                          <div className="my-auto">{i18next.t("continue")}</div>
                          <div className="my-auto">
                            <ChevronRight set="light" size={22} />
                          </div>
                        </div>
                      ) : (
                        <Calendar set="light" size={22} />
                      )
                    ) : (
                      <ChevronRight set="light" size={22} />
                    )
                  ) : (
                    <div className="pl-3 pr-2 flex flex-row items-center place-items-center">
                      <div className="my-auto">{i18next.t("wait list")}</div>
                    </div>
                  )
                }
                disabled={this.props.disabled}
                onClick={this.handleSubmit}
                waiting={this.state.waiting}
              />
              {!this.available && (
                <ReactTooltip
                  id={"bookingButtonTooltip" + thisRand}
                  place="top"
                  effect="solid"
                >
                  {i18next.t("put me on the waiting list")}
                </ReactTooltip>
              )}
            </>
          }
        />
        <Accordion
          ref={(el) => {
            this.accordionRef.current = el;
          }}
          noButton={true}
          className="-mt-8 md:-mt-8"
          id={`OfferDatePicker${offer.uuid} `}
        >
          <MyDatePicker
            ref={(el) => {
              this.myDatePickerRef.current = el;
            }}
            onChange={this.onPickedDateChange}
            field={{}}
          />
        </Accordion>
      </div>
    );
  }
}

export default OfferCard;
