import config from "react-global-configuration";

import { Component } from "react";

import logo from "../../assets/images/logo/LogoSlogan_Orangery_black.png";
import logoWhite from "../../assets/images/logo/LogoSlogan_Orangery_white.png";

const generals = require("../../generals");

class Logo extends Component {
  render() {
    return (
      <img
        className={`cursor-pointer object-scale-down ${
          this.props.className ? this.props.className : ""
        }`}
        width="280px"
        src={this.props.white ? logoWhite : logo}
        alt="orangery Office"
        onClick={(e) => {
          e.preventDefault();
          generals.goTo(`${config.get("homeUrl")}/home`, 0);
        }}
      />
    );
  }
}

export default Logo;
