import config from "react-global-configuration";

import { Component } from "react";

import logo from "../../assets/images/logo/Orangery-Logo_black-noSlogan.png";
import logoWhite from "../../assets/images/logo/Orangery-Logo_white-noSlogan.png";

const generals = require("../../generals");

class LogoWithoutSlogan extends Component {
  render() {
    return (
      <img
        className={`cursor-pointer  object-scale-down ${
          this.props.className ? this.props.className : ""
        }`}
        width="220px"
        src={this.props.white ? logoWhite : logo}
        alt="orangery Office"
        onClick={(e) => {
          e.preventDefault();
          generals.goTo(`${config.get("homeUrl")}/home`, 0);
        }}
      />
    );
  }
}

export default LogoWithoutSlogan;
