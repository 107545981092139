import { Component } from 'react';

class CheckBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: this.props.field.valid ? this.props.field.valid : true,
      value: this.props.field.value ? this.props.field.value : false,
    }
  }

  // eslint-disable-next-line no-unused-vars
  getValue = () => {
    return this.state.value;
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value);
    } else {
      this.handleChange(value);
    }
  }

  init = () => {
    if (this.props.field.initialValue !== undefined)
      this.setValue(this.props.field.initialValue)
  }

  reset = () => {
    if (this.props.field.emptyValue !== undefined)
      this.setValue(this.props.field.emptyValue)
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.field.valid !== this.props.field.valid)
      this.setState({ valid: nextProps.field.valid });

    if (nextProps.field.value !== this.props.field.value)
      this.setState({ value: nextProps.field.value });
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  handleChange = (value) => {
    this.setState({ value: value });

    if (this.props.onChange)
      this.props.onChange(this.props.field.name, value)

    this.validate(value)
  }

  handleBlur = (e) => {
    this.validate()
  }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    let valid = true;

    if (this.props.field.required) {
      if (value === false)
        valid = false;
    }

    this.setState({ valid: valid });

    return valid;
  }

  render() {
    let { field } = this.props

    return (
      <>
        <div className="flex items-center">
          <input
            id={field.id}
            name={field.name}
            type={field.type ? field.type : "checkbox"}
            required={field.required ? "required" : ""}
            className={
              "form-checkbox xl:h-4 xl:w-4 rounded text-black border " +
              (!this.state.valid ? " border-red-500" : " border-border focus:border-black")
            }
            checked={this.state.value}

            onChange={(e) => this.handleChange(e.target.checked)}
            onBlur={this.handleBlur}
          />
          {field.label &&
            <label htmlFor={field.id} className="ml-2 block text-sm text-gray-900">
              {field.label}
            </label>
          }
          {field.a &&
            <div className="ml-1">
              {field.a}
            </div>
          }
        </div>
        <p className="h-1 flex items-center font-medium tracking-wide text-red-500 text-xs ml-1">
          {!this.state.valid ? field.errorMessage : ""}
        </p>
      </>
    );
  }
}

export default CheckBox;