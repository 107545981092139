import config from "react-global-configuration";
import AuthContextProvider from "./context/authContext";
import { withTranslation } from "react-i18next";
// import history from "./utils/history";

import { Component, Suspense } from "react";
import {
  // Router,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  Home,
  LocationPage,
  // Team,
  // OpenDays,
  CoworkingFestival,
  PrivacyPolicy,
  Impressum,
  News,
  AGB,
  Checkout,
} from "./pages";
import { DefaultLoader } from "./components";

import ReactGA from "react-ga";

const localStorageUseCookiesWildCard = `orangery-${process.env.REACT_APP_ENVIRONMENT}-use-cookies`;

class App extends Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem(localStorageUseCookiesWildCard))
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
  }

  componentDidMount() {
    if (localStorage.getItem(localStorageUseCookiesWildCard))
      ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { t } = this.props;

    console.log(t("_welcomeMessage"));

    return (
      <Suspense fallback={<DefaultLoader />}>
        <AuthContextProvider>
          {/* Warning: <BrowserRouter> ignores the history prop. To use a custom history, use `import {Router}` instead of `import {BrowserRouter as Router}`. */}
          <Router>
            {" "}
            {/* history={history} // only workes for Router */}{" "}
            {/* basename={config.get('baseUrl')} // only workes for BrowserRouter */}
            <Switch>
              {/* <Route path={`${config.get('baseUrl')}/team`}
                render={(props) =>
                  <Team {...this.props} {...props} />
                }
              /> */}
              <Route
                path={`${config.get("baseUrl")}/location/:locationId`}
                component={LocationPage}
              />
              <Route
                path={`${config.get("baseUrl")}/location`}
                component={LocationPage}
              />
              <Route
                path={`${config.get("baseUrl")}/checkout`}
                render={(props) => <Checkout {...this.props} {...props} />}
              />
              <Route
                path={`${config.get("baseUrl")}/agb`}
                render={(props) => <AGB {...this.props} {...props} />}
              />
              {/* <Route
                path={`${config.get("baseUrl")}/open-days`}
                render={(props) => <OpenDays {...this.props} {...props} />}
              /> */}
              <Route
                path={`${config.get("baseUrl")}/coworking-festival`}
                render={(props) => (
                  <CoworkingFestival {...this.props} {...props} />
                )}
              />
              <Route
                path={`${config.get("baseUrl")}/privacy-policy`}
                render={(props) => <PrivacyPolicy {...this.props} {...props} />}
              />
              <Route
                path={`${config.get("baseUrl")}/impressum`}
                render={(props) => <Impressum {...this.props} {...props} />}
              />
              <Route
                path={`${config.get("baseUrl")}/news/:newsKey`}
                component={News}
              />
              <Route
                path={`${config.get("baseUrl")}/blog`}
                render={() => {
                  return <Redirect to={`${config.get("baseUrl")}/blog/`} />;
                }}
              />
              <Route
                path={`${config.get("baseUrl")}/blog/`}
                render={() => {
                  return <Redirect to={`${config.get("baseUrl")}/blog/`} />;
                }}
              />
              {/* <Route path={`${config.get('baseUrl')}/news`}
                render={(props) =>
                  <News {...this.props} {...props} />
                }
              /> */}
              <Route
                path={`${config.get("baseUrl")}/home`}
                render={(props) => <Home {...this.props} {...props} />}
              />
              <Route
                path={`${config.get("baseUrl")}/`}
                render={(props) => <Home {...this.props} {...props} />}
              // render={() => {
              //   return (
              //     <Redirect to={`${config.get('baseUrl')}/home`} />
              //   )
              // }}
              />
              <Route
                path={`${config.get("baseUrl")}`}
                render={(props) => <Home {...this.props} {...props} />}
              // render={() => {
              //   return (
              //     <Redirect to={`${config.get('baseUrl')}/home`} />
              //   )
              // }}
              />
              <Route
                path="/"
                render={(props) => <Home {...this.props} {...props} />}
              // render={() => {
              //   return (
              //     <Redirect to={`${config.get('baseUrl')}/home`} />
              //   )
              // }}
              />
              {/* <Route path="*"
                render={() => {
                  return (
                    <Redirect to={`${config.get('baseUrl')}/home`} />
                  )
                }
                }
              /> */}
            </Switch>
          </Router>
        </AuthContextProvider>
      </Suspense>
    );
  }
}

export default withTranslation()(App);
