/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json";
// import config from "react-global-configuration";

// contexts
// import { AuthContext } from "../context/authContext";

// import { useTranslation } from 'react-i18next';
import i18next from "i18next";

import { useState, useEffect } from "react";

import { Img } from "../components";

import { Header, Footer } from "../implementedComponents";

import firstSectionNewImage_de from "../assets/images/open-days/Website_Header_ OpenDays.jpg";
import HubspotForm from "react-hubspot-form";
const generals = require("../generals");

export default function OpenDays() {
  // const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    // let getRequested = false;
    // if (!getRequested) {
    //   getRequested = true;

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       // 'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //         // config.set({ locationOptions: tempList }, { freeze: false, environment: 'notFreeze' })
    //         // config.setEnvironment('notFreeze');
    //       }
    //     })
    //     // .catch((error) => {
    //     //   config.set("locationOptions", [])
    //     // })
    //     .then(function () {
    //       // always executed
    //     });
    // }
  }

  useEffect(() => {
    loadLocationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Header locationOptions={locationOptions} />
          <main>
            {/* First section */}
            <div className="bg-white pt-16 md:pt-22 pb-0 lg:pb-0">
              <div className="mx-0 px-0">
                <Img
                  className="z-20 w-full h-auto object-cover"
                  src={firstSectionNewImage_de}
                  alt=""
                />
              </div>
            </div>

            {/* Second section */}
            <div className="relative bg-gray-50 py-12 sm:py-20 lg:py-24">
              <div className="mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="">
                  {/* <h1 className="text-2xl tracking-tight font-medium sm:text-4xl">
                    {t("Privacy Policy")}
                  </h1> */}

                  {i18next.language === "en" ? (
                    <>
                      <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mb-3">
                        From now on, every Wednesday in all our locations will
                        be an OPEN DAY (except Magdeburg).
                      </h2>
                      <p className="mb-3">
                        During our Open Days you have the opportunity to test
                        the orangery and all our features. Every Wednesday from
                        8 a.m. to 4 p.m., the doors at all orangery locations
                        (except Magdeburg) are open to you and you can relocate
                        your home office to one of our orangery spaces on a
                        trial basis.
                      </p>
                      <p className="mb-3">
                        You don't have time to try out our spaces on Wednesdays?
                        Please contact us and we will find a solution.
                      </p>
                      <p className="mb-3">
                        We are looking forward to your visit!
                      </p>
                      <p className="italic mb-4">Your orangery Team</p>
                      <p>
                        *Each person can participate 2x free of charge in our
                        Open Days.
                      </p>
                    </>
                  ) : (
                    <>
                      <h2 className="text-2xl tracking-tight font-medium sm:text-2xl mb-3">
                        Ab sofort wird jeder Mittwoch in all unseren Standort
                        zum OPEN DAY (ausgenommen Magdeburg).
                      </h2>
                      <p className="mb-3">
                        Im Rahmen unserer Open Days hast Du die Möglichkeit, die
                        orangery und all unsere Features zu testen. Jeden
                        Mittwoch von 8-16:00 Uhr stehen dir hierfür die Türen an
                        allen orangery Standorten (außer Magdeburg) offen und Du
                        kannst dein Homeoffice probeweise in die orangery
                        verlagern.* Registriere dich ganz einfach über das
                        Anmeldeformular und komm vorbei. Vor Ort freuen sich
                        schon unsere Community ManagerInnen auf dich, sie zeigen
                        dir alles und beantworten dir alle deine Fragen.
                      </p>
                      <p className="mb-3">
                        Du hast mittwochs keine Zeit zum Probearbeiten? Melde
                        dich gerne bei uns und wir finden eine Lösung.
                      </p>
                      <p className="mb-3">Wir freuen uns auf deinen Besuch!</p>
                      <p className="italic mb-4">Dein orangery Team</p>
                      <p>
                        *Jede Person kann 2x kostenfrei an unseren Open Days
                        teilnehmen.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </main>
          <script
            charset="utf-8"
            type="text/javascript"
            src="https://js.hsforms.net/forms/embed/v2.js"
          ></script>
          <div
            id="opendaysForm"
            className=" text-center pl-4 md:pl-[200px] mx-auto w-full md:max-w-[800px]"
          >
            <HubspotForm
              region="na1"
              portalId="7914845"
              formId="5cb92c86-c9dd-4d37-b5f3-42df766bfce8"
              onSubmit={() => {}}
              onReady={(form) => {}}
              loading={<div>Loading...</div>}
            />
          </div>
          <Footer locationOptions={locationOptions} />
        </div>
      </div>
    </>
  );
}
