import config from "react-global-configuration";
// import { useTranslation } from 'react-i18next';

import { Img } from "../../components";

import { Message } from "react-iconly";

export default function ManagerInfoCard(props) {
  const { manager } = props;
  // const { t } = useTranslation();

  const fullname = manager.name + " " + manager.last_name;
  return (
    <div
      className={
        "relative " +
        (props.className ? props.className : "bg-white p-3 w-auto h-auto") +
        " rounded-lg block xs:flex flex-row"
      }
    >
      <Img
        className={
          "mx-auto xs:ml-0 flex-none object-cover rounded-xl" +
          (props.imageClassName ? props.imageClassName : " h-30 w-24")
        }
        src={
          manager.file
            ? manager.file.filename
              ? `${config.get("cdnUrl")}/${manager.file.filename}`
              : manager.file.src
            : ""
        }
        alt={fullname}
      />
      <div className="flex flex-col w-full pl-0 xs:pl-3 mt-4">
        <h3 className="mx-auto xs:ml-0 text-center xs:text-left text-base 2xl:text-xl font-medium text-mainBackground">
          {fullname}
        </h3>
        <div className="flex space-x-2 mt-1">
          <p className="mx-auto xs:ml-0 text-center xs:text-left text-xs 2xl:text-base text-mainBackground whitespace-normal">
            {
              // t("Your contact person in")}{" "}{props.locationName
            }
            {manager.description}
          </p>
        </div>
        {manager.socials && (
          <div className="mx-auto xs:ml-0 text-xs 2xl:text-base text-right mt-2">
            {manager.socials.map((social, index) => (
              <span key={index}>{social}</span>
            ))}
          </div>
        )}
        {props.officialEmail && (
          <a href={`mailto:${props.officialEmail}`}>
            <div className="justify-center xs:justify-start text-mainBackground mx-auto xs:ml-0 text-center xs:text-left flex flex-row items-center text-base space-x-1 mt-4">
              <Message set="light" size={22} />
              <span>{props.officialEmail}</span>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
