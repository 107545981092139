/*
  This example requires Tailwind CSS v2.0+ 
*/

import config from "react-global-configuration";

import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import { Location, Calling, Message } from "react-iconly";

import { Logo, SubscribeEMailForm } from "../../components";

import playStore from "../../assets/images/icons/apps/google-play-brands.svg";
import appStore from "../../assets/images/icons/apps/apple-brands.svg";

export default function Footer(props) {
  const { t } = useTranslation();

  // const explores = [
  //   {
  //     label: t("Team"),
  //     href: `${config.get('homeUrl')}/team`,
  //   },
  // ]

  const eventTrack = (category, action, label) => {
    // console.log("GA event trigerred: ", category, ":", action, ":", label);
    // console.log({
    //   ReactGAEventTrack: {
    //     category: category,
    //     action: action,
    //     label: label,
    //   }
    // })
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const contacts = [
    {
      label: (
        <span className="flex flex-row space-x-2">
          <div className="flex-none">
            <Location set="light" size={22} />
          </div>
          <span>{config.get("orangery").address}</span>
        </span>
      ),
    },
    {
      label: (
        <span className="flex flex-row space-x-2 items-center">
          <div className="flex-none">
            <Calling set="light" size={22} />
          </div>
          <span>
            <a
              href={`tel:${config.get("orangery").phone}`}
              onClick={() => {
                eventTrack("footer", "click", "official phone tag");
              }}
            >
              {config.get("orangery").phone}
            </a>
          </span>
        </span>
      ),
    },
    {
      label: (
        <span className="flex flex-row space-x-2 items-center">
          <div className="flex-none">
            <Message set="light" size={22} />
          </div>
          <span>
            <a
              href={`mailto:${config.get("orangery").official_email}`}
              // target="_blank"
              // rel="noreferrer"
              onClick={() => {
                eventTrack("footer", "click", "official emial tag");
              }}
            >
              {config.get("orangery").official_email}
            </a>
          </span>
        </span>
      ),
    },
  ];

  return (
    <footer className="bg-eighth py-6" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
        <Logo white={true} />
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8 xl:gap-12">
          <div className="col-span-1 md:col-span-2">
            <p className="text-mainBackground text-base mt-8">
              {t("_footerBodyText")}
            </p>
            <div className="flex flex-col space-x-6 mt-8">
              <div className="xl:mt-0">
                <p className="text-sm font-semibold text-mainBackground tracking-wider uppercase">
                  {t("Newsletter")}
                </p>
                <SubscribeEMailForm
                  hubspot
                  submitButtonText={t("Subscribe newsletter")}
                />
              </div>
            </div>
          </div>
          <div className="mt-12 md:mt-0">
            <div>
              <h3 className="text-sm font-semibold text-mainBackground tracking-wider uppercase">
                {t("Contact")}
              </h3>
              <ul className="mt-4 space-y-4">
                {contacts &&
                  contacts.map((item, index) => (
                    <li key={index}>
                      <div className="text-base text-white hover:text-gray-200">
                        {item.label}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="mt-10">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                {t("Download Now")}
              </h3>
              <ul className="flex flex-wrap mt-4 gap-2">
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.orangeryos"
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-row space-x-2 text-base font-normal text-white hover:text-gray-200 border border-white py-2 px-3 rounded-md"
                  >
                    <img
                      className="h-8 w-8"
                      src={playStore}
                      alt="orangeryOS play store"
                    />
                    <span className="my-auto">Play Store</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/de/app/orangeryos/id1615709484"
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-row space-x-2 text-base font-normal text-white hover:text-gray-200 border border-white py-[6px] px-3 rounded-md"
                  >
                    <img
                      className="h-9 w-9 text-white"
                      src={appStore}
                      alt="orangeryOS play store"
                    />
                    <span className="my-auto">App Store</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("Explore")}</h3>
            <ul className="mt-4 space-y-4">
              {explores && explores.map((item) => (
                <li key={item.label}>
                  <a href={item.href} className="text-base text-white hover:text-gray-200" role="button">
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
        </div>
        <div className="mt-12">
          <h3 className="text-sm font-semibold text-mainBackground tracking-wider uppercase">
            {t("Locations")}
          </h3>
          <ul className="mt-4 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-2">
            {props.locationOptions &&
              props.locationOptions.map((item) => (
                <li key={item.label}>
                  <a
                    href={`${config.get("homeUrl")}/location/${item.value}`}
                    className="text-base text-white hover:text-gray-200"
                    role="button"
                  >
                    {item.label}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="mt-12 border-t border-gray-200 py-8 flex justify-between text-base text-gray-400">
          <p className="">{`${t(
            "Copyright"
          )} © 2022 orangery Coworking GmbH. ${t("All Rights Reserved")}.`}</p>
          <span className="flex space-x-4">
            <a
              href={config.get("orangery").termsAndConditions}
              className="text-base text-mainBackground hover:text-gray-200"
              role="button"
            >
              {t("_Terms & Conditions")}
            </a>
            <a
              href={config.get("orangery").privacyPolicy}
              className="text-base text-mainBackground hover:text-gray-200"
              role="button"
            >
              {t("privacy")}
            </a>
            <a
              href={config.get("orangery").imprint}
              className="text-base text-mainBackground hover:text-gray-200"
              role="button"
            >
              {t("imprint")}
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
