export default function FeatureLargeCard(props) {
  return (
    <div className="bg-gray-200 mx-3 p-3 grow flex flex-col-reverse sm:flex-row justify-between rounded ">
      <div className={"relative px-3 py-3 sm:px-8 sm:py-8 lg:px-2 xl:px-8 w-full flex flex-col text-left place-items-center"}>
        <div className='text-left w-full'>
          <p className="text-lg font-medium whitespace-normal text-left w-full">
            {props.feature.label}
          </p>
        </div>
        <div className='mt-1 text-left w-full'>
          <p className="text-base whitespace-normal text-left w-full">
            {props.feature.description}
          </p>
        </div>
      </div>
      <div className="px-4 mt-2 sm:my-auto">
        <div className="w-7 h-7">
          {props.feature.icon}
        </div>
      </div>
    </div>
  )
}