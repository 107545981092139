/*
  This example requires Tailwind CSS v2.0+ 
*/

import getlocations from "../data/getlocations.json";
import getlocationinfo from "../data/getlocationinfo.json";
import config from "react-global-configuration";

// contexts
import { AuthContext } from "../context/authContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ReactGA from "react-ga";

import { useState, useEffect, useContext, createRef } from "react";
import axios from "axios";
import moment from "moment";

import {
  ManagerInfoCard,
  OfferCard,
  EventCard,
  Tabs,
  Img,
  Carousel,
  BookAMeetingRoomCard,
  StatusBadge,
  Ribbon,
  NewModal,
} from "../components";
import { Header, Footer, BookATourForm, FeaturesList } from "../implementedComponents";
// import { NewModal } from "../components";
import { Location, Calling, Message } from "react-iconly";
import { GrThreeD } from "react-icons/gr";

// import breakFastSvg from "../assets/images/icons/feature-icon-breakfast.svg"

import offices from "../data/offices.json";
import locationNameIds from "../data/locationNameIds.json";

import { Helmet } from "react-helmet";
import { loadMeetingRooms } from "../implementedComponents/forms/bookAMeetingRoomForm";

const generals = require("../generals");

const eventTrack = (category, action, label) => {
  // console.log("GA event trigerred: ", category, ":", action, ":", label);
  // console.log({
  //   ReactGAEventTrack: {
  //     category: category,
  //     action: action,
  //     label: label,
  //   }
  // })
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

const getSeason = (month) => {
  if (3 <= month && month <= 5) {
    return "Spring";
  }

  if (6 <= month && month <= 8) {
    return "Summer";
  }

  if (9 <= month && month <= 11) {
    return "Fall";
  }

  return "Winter";
};

export default function LocationPage(props) {
  const { accessToken } = useContext(AuthContext);
  const { t } = useTranslation();

  const [locationOptions, setLocationOptions] = useState([]);
  const [locationInfo, setLocationInfo] = useState(null);
  // const [locationInfo, setLocationInfo] = useState(config.get('defaults').locationInfo);

  let locationId = props.match.params.locationId;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlLocationUuid = urlParams.get("id");
    const eventId = urlParams.get("eventId");

    const tempLocationId = offices[config.get("buildEnv")]?.filter(
      (office) => office.uuid === urlLocationUuid
    )[0]?.orangeryOfficeId; ////////!!!!!!!!

    let tempLocation = locationNameIds.find(
      (locationNameId) => locationNameId.locationId === tempLocationId
    );

    if (!locationId)
      window.location.href = `/location/${tempLocation.locationName}/?event=true&eventId=${eventId}`;
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line eqeqeq
  if (parseInt(locationId) != locationId)
    locationId = locationNameIds.filter(
      (locationNameId) => locationNameId.locationName === locationId
    )[0]?.locationId;
  let location = locationNameIds.find((locationNameId) => locationNameId.locationId === locationId);

  if (location === undefined)
    window.location.href = `/`;

  // locationId = locationNameIds.filter((locationNameId) => locationNameId.locationName === locationId)[0]?.locationId //!!

  const officeUuid = offices[config.get("buildEnv")]?.filter(
    (office) => office.orangeryOfficeId === parseInt(locationId)
  )[0]?.uuid; ////////!!!!!!!!

  const [activeSlide, setActiveSlide] = useState(0);
  function onThumbnailClick(activeSlide) {
    setActiveSlide(activeSlide);
  }

  let thumbnailRefs = [];
  let thumbnails = "";
  if (
    locationInfo &&
    locationInfo.spacephotos &&
    Array.isArray(locationInfo.spacephotos) &&
    locationInfo.spacephotos.length > 0
  ) {
    thumbnails = locationInfo.spacephotos.map((spacephoto, index) => {
      thumbnailRefs[index] = createRef();
      return (
        <Img
          ref={(el) => {
            thumbnailRefs[index].current = el;
          }}
          id={"thumbnail" + index}
          key={spacephoto.file.filename}
          className={`flex-none my-2 object-cover h-14 w-24 sm:h-24 sm:w-40 rounded-lg${index === activeSlide ? " ring-1 ring-brandNew p-px ring-inset" : ""
            }`}
          src={`${config.get("cdnUrl")}/${spacephoto.file.filename}`}
          alt=""
          onClick={(e) => {
            if (e) e.stopPropagation();
            onThumbnailClick(index);
          }}
          role="button"
        />
      );
    });
  }

  // useEffect(() => {
  //   thumbnailRefs = new Array();
  //   if (locationInfo && locationInfo.spacephotos && Array.isArray(locationInfo.spacephotos) && locationInfo.spacephotos.length > 0) {
  //     locationInfo.spacephotos.forEach((spacephoto, index) => {
  //       thumbnailRefs[index] = createRef();
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [locationInfo && locationInfo.spacephotos])

  function onCarouselSlideChange(activeSlide) {
    ////////!!!!!!!! not working!
    setActiveSlide(activeSlide);
    if (
      document.querySelector("#thumbnailsContainer") &&
      document.querySelector("#thumbnail" + activeSlide)
    )
      document.querySelector("#thumbnailsContainer").scrollLeft =
        Number.parseInt(activeSlide) *
        Number.parseInt(document.querySelector("#thumbnail" + activeSlide).offsetWidth);
  }

  function loadLocationInfo(locationId) {
    let data = getlocationinfo.find((thisLocationInfo) => thisLocationInfo.id === locationId);
    setLocationInfo(data);

    //   let url = `${config.get('apiUrl')}/getlocationinfo?location_id=${locationId}`;
    //   let configs = {
    //     headers: {
    //       'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       // data.communitymanager.description = "Your contact person in Hildesheim"; ////////!!!!!!!!
    //       // data.communitymanager.image = managerImageSrc; ////////!!!!!!!!
    //       // data.officeplans = [  ////////!!!!!!!!
    //       //   {
    //       //     id: 1,
    //       //     price: 123,
    //       //     name: "test office",
    //       //   },
    //       //   {
    //       //     id: 2,
    //       //     price: 1234,
    //       //     name: "test office plan 2",
    //       //   }
    //       // ]

    //       setLocationInfo(data);
    //     })
    //     .catch((error) => {
    //       console.warn(error.message)
    //     })
    //     .then(function () {
    //       // always executed
    //     });
  }

  function loadLocationOptions() {
    let data = getlocations.data;
    let tempList = generals.toList(data, "name", "name");
    setLocationOptions(tempList);

    //   let url = `${config.get('apiUrl')}/getlocations`;
    //   let configs = {
    //     headers: {
    //       'x-access-token': accessToken,
    //       timeout: config.get('apiCallTimeout')
    //     },
    //     params: {
    //       // page: f + 1
    //     },
    //   }
    //   axios.get(url, configs)
    //     .then((response) => {
    //       let data = response.data.data;
    //       if (response.data.result) {
    //         let tempList = generals.toList(data, "name", "name");
    //         setLocationOptions(tempList);
    //         // config.set({ locations: tempList }, { freeze: false, environment: 'notFreeze' })
    //         // config.setEnvironment('notFreeze');
    //       }
    //     })
    //     // .catch((error) => {
    //     //   config.set("locations", [])
    //     // })
    //     .then(function () {
    //       // always executed
    //     });
  }

  const [meetingRooms, setMeetingRooms] = useState([]);

  const [offers, setOffers] = useState([]);
  function loadOffers() {
    let url = `${config.get("osApiUrl")}/office/${officeUuid}/product/published`;
    let configs = {
      headers: {
        "x-access-token": accessToken,
        timeout: config.get("apiCallTimeout"),
      },
      params: {
        // page: f + 1
      },
    };
    axios
      .get(url, configs)
      .then((response) => {
        let data = response.data?.data;

        // Tagespass
        // Flex Desk
        // Hybrid Office
        // Private Office
        let offers = data.products?.sort((a, b) => (a.sale_price < b.sale_price ? 1 : -1)) ?? [];
        let sortedOffers = [
          ...offers.filter((offer) => offer.name.toLowerCase().indexOf("tagespass") !== -1),
          ...offers.filter(
            (offer) =>
              offer.name.toLowerCase().indexOf("flex desk") !== -1 ||
              offer.name.toLowerCase().indexOf("flexdesk") !== -1
          ),
          ...offers.filter((offer) => offer.name.toLowerCase().indexOf("hybrid office") !== -1),
          ...offers.filter((offer) => offer.name.toLowerCase().indexOf("private office") !== -1),
        ];

        setOffers(sortedOffers);
      })
      .catch((error) => {
        console.warn(error.message);
      })
      .then(function () {
        // always executed
      });
  }

  const [events, setEvents] = useState([]);

  function loadEvents() {
    let url = `${config.get("osApiUrl")}/event?office_uuid=${officeUuid}`;
    let configs = {
      headers: {
        "x-access-token": accessToken,
        timeout: config.get("apiCallTimeout"),
      },
      params: {
        // page: f + 1
      },
    };
    axios
      .get(url, configs)
      .then((response) => {
        let data = response.data?.data.events;
        setEvents(data);
      })
      .catch((error) => {
        console.warn(error.message);
      })
      .then(function () {
        // always executed
      });
  }

  useEffect(() => {
    loadLocationOptions();
    loadMeetingRooms(officeUuid, moment(new Date()).format("DD-MM-YYYY"), setMeetingRooms, accessToken);
    loadOffers();
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadLocationInfo(locationId);
    loadMeetingRooms(officeUuid, moment(new Date()).format("DD-MM-YYYY"), setMeetingRooms, accessToken);
    loadOffers();
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const tabs = {
    description: {
      key: "description",
      title: t("Description"),
      href: "#",
      content:
        location && location?.description ? (
          <div className="text-left space-y-4">
            <h2 className="text-xl font-bold text-black">
              {location?.description[i18next.language]?.title}
            </h2>
            {location?.description[i18next.language]?.body.map((paragraph, index) => {
              switch (index) {
                case 0:
                  return (
                    <h4 key={index} className="mt-0 text-lg font-semibold text-black">
                      {paragraph}
                    </h4>
                  );

                default:
                  return (
                    <p key={index} className="mt-2 text-justify">
                      {paragraph}
                    </p>
                  );
              }
            })}
            {/* {locationInfo.description.split("___").map((line, index) => {
            switch (index) {
              case 0:
                return <h2 key={index} className="text-xl font-bold text-black">{line}</h2>

              case 1:
                return <h4 key={index} className="mt-0 text-lg font-semibold text-black">{line}</h4>

              default:
                return <p key={index} className='mt-2 text-justify'>{line}</p>
            }
          })} */}
          </div>
        ) : (
          ""
        ),
    },
    features: {
      key: "features",
      title: t("Features"),
      href: "#",
      content: locationInfo && <FeaturesList />,
    },
    locationAccesses: {
      key: "locationAccesses",
      title: t("Location & Accesses"),
      href: "#",
      content:
        locationInfo && locationInfo.locationAccesses ? (
          <div className="text-left">{locationInfo.locationAccesses}</div>
        ) : (
          ""
        ),
    },
    events: {
      key: "events",
      title: t("Events"),
      href: "#",
      content: events && events.length > 0 && (
        <EventsList events={events} locationName={locationInfo} officeUuid={officeUuid} />
      ),
    },
  };

  let opening = locationInfo
    ? locationInfo.opening &&
      locationInfo.opening !== "null" &&
      locationInfo.opening !== "NULL" &&
      locationInfo.opening !== "Null"
      ? locationInfo.opening
      : // : config.get('openings')[locationInfo.id]
      //   ? config.get('openings')[locationInfo.id]
      ""
    : "";

  const [showModalEvent, setShowModalEvent] = useState(false);
  const [eventResult, setEventResult] = useState([]);
  const [eventUrlData, setEventUrlData] = useState({});
  const [submittedModalEvent, setSubmittedModalEvent] = useState(false);
  useEffect(() => {
    var url = window.location.href;

    let tempEventUrlData = {};

    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      key = decodeURIComponent(key);
      value = decodeURIComponent(value);
      tempEventUrlData[key] = value;
    });

    setEventUrlData(tempEventUrlData);

    if (
      eventUrlData.eventId !== undefined &&
      eventUrlData.event === "true" &&
      eventUrlData.email !== undefined
    ) {
      let event =
        events && events.length > 0 && events.find((event) => event.uuid === eventUrlData.eventId);
      if (event !== undefined) {
        setEventResult([event]);
        setSubmittedModalEvent(true);
        setShowModalEvent(true);
      } else {
        setEventResult([]);
      }
    } else if (
      eventUrlData.eventId !== undefined &&
      eventUrlData.event === "true" &&
      eventUrlData.email === undefined
    ) {
      let event =
        events && events.length > 0 && events.find((event) => event.uuid === eventUrlData.eventId);

      if (event) {
        setEventResult([event]);
        setSubmittedModalEvent(false);
        setShowModalEvent(true);
      } else {
        setEventResult([]);
      }
    }
    // eslint-disable-next-line
  }, [events]);

  return (
    <>
      {locationInfo ? (
        <>
          <Helmet>
            {/* < !--Google Tag Manager(noscript)-- > */}
            <noscript
              dangerouslySetInnerHTML={{
                __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `,
              }}
            ></noscript>
            {/* document.querySelector<HTMLInputElement>("body")!.prepend(`<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`); */}
            {/* <!--End Google Tag Manager(noscript)-- > */}

            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_TRACKING_ID}`}
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.REACT_APP_GOOGLE_TRACKING_ID
                  }', {location_name: page_location_${locationInfo.name
                    .toLowerCase()
                    .replace(" ", "")
                    .replace("-", "")
                    .replace("_", "")}});
            `,
              }}
            />
          </Helmet>

          <div className="bg-mainBackground">
            <div className="relative overflow-hidden">
              {/* <Slider /> */}
              <Header locationOptions={locationOptions} selectedLocation={location} />

              <main className="mt-32 sm:mt-20">
                {/* First Section */}
                <div className="pt-10 sm:pt-16 lg:pt-8 lg:pb-4">
                  <div className="mx-auto max-w-7xl px-8 lg:pr-2">
                    <div className="flex flex-wrap">
                      <div className="flex flex-col basis-full lg:basis-2/3 space-y-3">
                        <div>
                          {/* (locationInfo.link
                              // <iframe title={locationInfo.name} width='853' height='480' src='https://my.matterport.com/show/?m=gAqk25jpoDD' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>
                        ? [<iframe
                          title={locationInfo.name}
                          width='853' height='480'
                          className="w-full rounded-lg h-112"
                          src={locationInfo.link}
                          frameBorder='0'
                          allowFullScreen
                          allow='xr-spatial-tracking'
                        />]
                        : []
                        ) */}
                          <Carousel
                            id="SpacePhotosCarousel"
                            note={
                              opening && (
                                <div className="flex flex-col absolute bottom-3 left-3 z-40">
                                  <StatusBadge
                                    className="px-3 pt-0.5 pb-1.5 -mb-0.5 rounded-lg bg-white text-sixth text-2xl md:text-4xl font-medium tracking-wide font-Caveat+Brush"
                                    status={
                                      t("opening") +
                                      " " +
                                      t(getSeason(new Date(opening).getMonth() + 1))
                                    }
                                    noLowerCase={true}
                                  />
                                  <StatusBadge
                                    className="p-3 rounded-lg bg-brand text-sixth text-3xl md:text-6xl font-medium tracking-wide font-Caveat+Brush"
                                    status={moment(opening).format("YYYY")}
                                    noLowerCase={true}
                                  />
                                </div>
                              )
                            }
                            activeSlide={activeSlide}
                            onSlideChange={onCarouselSlideChange}
                            slides={
                              locationInfo.spacephotos &&
                                Array.isArray(locationInfo.spacephotos) &&
                                locationInfo.spacephotos.length > 0
                                ? locationInfo.spacephotos.map((spacephoto, index) => (
                                  <Img
                                    key={index}
                                    className="block object-cover h-56 sm:h-112 w-full rounded-lg"
                                    src={
                                      spacephoto.file
                                        ? spacephoto.file.filename
                                          ? `${config.get("cdnUrl")}/${spacephoto.file.filename}`
                                          : spacephoto.file.src
                                            ? spacephoto.file.src
                                            : ""
                                        : ""
                                    }
                                    alt=""
                                  />
                                ))
                                : []
                            }
                          />
                        </div>
                        <div className="flex flex-row overflow-hidden space-x-3">
                          {locationInfo.link && (
                            <a
                              className="flex flex-none my-2 border border-brandNew text-black h-14 w-auto sm:h-24 sm:w-40 rounded-lg p-3 text-base sm:text-lg font-medium place-content-center items-center cursor-pointer space-x-2"
                              href={locationInfo.link}
                              target="_blank"
                              rel="noreferrer"
                            // onClick={
                            //   (e) => {
                            //     if (e) {
                            //       e.stopPropagation();
                            //       e.preventDefault();
                            //     }
                            //     window.open(locationInfo.link)
                            //   }
                            // }
                            >
                              <GrThreeD className="w-6 h-6" aria-hidden="true" />
                              <span>{t("3D View")}</span>
                            </a>
                          )}
                          <div className="relative overflow-hidden">
                            <div
                              className="flex flex-row overflow-x-scroll space-x-3 no-scrollbar"
                              style={{ scrollBehavior: "smooth" }}
                              id={"thumbnailsContainer"}
                            >
                              {thumbnails}
                            </div>
                          </div>
                        </div>
                        {/* Middle section */}
                        <div className="relative pt-4 sm:pt-6 lg:pt-8">
                          <h2 className="text-3xl font-bold  text-black">
                            orangery {locationInfo.name}
                          </h2>
                          <p className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-4 mt-2 text-gray-900">
                            <span className="flex flex-row space-x-2">
                              <Location set="light" />
                              <span>{locationInfo.address}</span>
                            </span>
                            <a href={`tel:${locationInfo.phone}`}>
                              <span className="flex flex-row space-x-2">
                                <Calling set="light" />
                                <span>{locationInfo.phone}</span>
                              </span>
                            </a>
                            <a href={`mailto:${locationInfo.official_email}`}>
                              <span className="flex flex-row space-x-2">
                                <Message set="light" />
                                <span>{locationInfo.official_email}</span>
                              </span>
                            </a>
                          </p>
                        </div>

                        {/* Tabs section */}
                        <div className="relative bg-mainBackground py-4">
                          <div className="mx-auto px-4 text-center sm:px-6 lg:px-8 lg:max-w-7xl">
                            <Tabs tabs={tabs} />
                          </div>
                        </div>
                      </div>
                      <div className="basis-full lg:basis-1/3 p-8 pt-8 lg:pt-0">
                        <div className="w-full ">
                          {locationInfo.communitymanager ? (
                            <ManagerInfoCard
                              className={"bg-eighth p-3 w-auto h-auto"}
                              manager={locationInfo.communitymanager}
                              officialEmail={locationInfo.official_email}
                              locationName={locationInfo.name}
                            />
                          ) : (
                            ""
                          )}

                          {locationInfo.has_tour
                            ? <div className="my-5">
                              <BookATourForm
                                locationId={locationId}
                                tourLink={locationInfo.tour_link}
                              />
                            </div>
                            : <></>
                          }

                          {meetingRooms && (
                            <div className="my-5">
                              <BookAMeetingRoomCard
                                officeUuid={officeUuid}
                                locationName={locationInfo.name}
                                meetingRooms={meetingRooms}
                              />
                            </div>
                          )}

                          {offers && (
                            <OffersList
                              offers={offers}
                              locationName={locationInfo.name}
                              officeUuid={officeUuid}
                              noDate={!!opening}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <Footer locationOptions={locationOptions} />
            </div>
            {showModalEvent && (
              <NewModal
                open={showModalEvent}
                setOpen={setShowModalEvent}
                event={eventResult[0]}
                eventResult={submittedModalEvent}
                urlData={eventUrlData}
              />
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

function OffersList({ offers, locationName, officeUuid, noDate }) {
  const { t } = useTranslation();

  const [offerCardsActive, setOfferCardsActive] = useState(true);

  function onOfferSubmitting(active, offerName) {
    eventTrack(locationName, "book an offer", offerName);
    setOfferCardsActive(!active);
  }

  return offers && Array.isArray(offers) && offers.length > 0 ? (
    <div className="flex flex-col space-y-5 my-5">
      {offers.map((offer) => (
        <OfferCard
          key={offer.uuid}
          offer={offer}
          noDate={noDate}
          ribbon={
            offer.sale_price &&
              parseFloat(offer.sale_price) > 0 &&
              parseFloat(offer.sale_price) < parseFloat(offer.price) ? (
              <Ribbon text={t("Sale")} />
            ) : (
              ""
            )
          }
          officeUuid={officeUuid}
          onSubmitting={onOfferSubmitting}
          active={offerCardsActive}
        />
      ))}
    </div>
  ) : (
    <></>
  );
}

function EventsList({ events, officeUuid }) {
  const [eventsCardsActive, setEventsCardsActive] = useState(false);

  function onEventSubmitting() {
    setEventsCardsActive(true);
  }

  return events && Array.isArray(events) && events.length > 0 ? (
    <div className="flex flex-col gap-y-[10px]">
      {events.map((event) => (
        <EventCard
          key={event.uuid}
          event={event}
          officeUuid={officeUuid}
          onSubmitting={onEventSubmitting}
          active={eventsCardsActive}
        />
      ))}
    </div>
  ) : (
    <></>
  );
}
