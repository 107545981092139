/* This example requires Tailwind CSS & tw-elements */

import { useState, useEffect } from 'react'

export default function Carousel(props) {
  const [activeSlide, setActiveSlide] = useState(props.activeSlide ? props.activeSlide : 0)

  useEffect(() => {
    setActiveSlide(props.activeSlide ? props.activeSlide : 0)
    onSlideChange(props.activeSlide ? props.activeSlide : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeSlide])

  function onSlideChange(slideIndex) {
    setActiveSlide(slideIndex)
    if (props.onSlideChange)
      props.onSlideChange(slideIndex)
  }

  function slide(slideOrder) {
    let newSlideIndex = Number.parseInt(activeSlide) + Number.parseInt(slideOrder);
    if (newSlideIndex < 0)
      newSlideIndex += props.slides.length
    if (newSlideIndex > props.slides.length - 1)
      newSlideIndex -= props.slides.length

    if (props.onSlideChange)
      props.onSlideChange(newSlideIndex)
  }

  return (
    <div
      id={props.id}
      className="relative carousel slide carousel-fade"
    // data-bs-ride="carousel"
    >
      {props.note
        && props.note
      }
      {!props.noIndicators
        ? <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
          {props.slides && Array.isArray(props.slides) &&
            props.slides.map((slide, index) => (
              <button
                key={index}
                type="button"
                data-bs-target={`#${props.id}`}
                data-bs-slide-to={index}
                className={index === activeSlide ? "active" : ""}
                aria-current={index === activeSlide ? "true" : ""}
                aria-label={`Slide ${index}`}

                onClick={(e) => { if (e) e.stopPropagation(); onSlideChange(index) }}
              />
            ))
          }
        </div>
        : ""
      }
      <div className="carousel-inner relative w-full overflow-hidden">
        {props.slides && Array.isArray(props.slides) &&
          props.slides.map((slide, index) => (
            <div
              key={index}
              className={`carousel-item float-left w-full ${index === activeSlide ? "active" : ""}`}
            >
              {slide}
            </div>
          ))
        }
      </div>
      <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0 h-1/4 my-auto"
        type="button"
        data-bs-target={`#${props.id}`}
        data-bs-slide="prev"
        onClick={(e) => { if (e) e.stopPropagation(); slide(-1) }}
      >
        <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0 h-1/4 my-auto"
        type="button"
        data-bs-target={`#${props.id}`}
        data-bs-slide="next"
        onClick={(e) => { if (e) e.stopPropagation(); slide(1) }}
      >
        <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}