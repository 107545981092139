/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import config from "react-global-configuration";

import { Img } from "../index";

export default function ProfileAvatar({ name, image, size, useInGroup = false }) {
  return image ? (
    <Img
      src={image ? `${config.get("cdnUrl")}/${image}.jpg` : ""}
      className={`inline-flex relative ${
        size === "small" ? `w-[24px] h-[24px]` : `w-[30px] h-[30px]`
      } rounded-full ${useInGroup && `ring-2 ring-white`}`}
    />
  ) : (
    <div
      className={`relative inline-flex ${
        size === "small" ? `w-[24px] h-[24px]` : `w-[30px] h-[30px]`
      } items-center justify-center overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 ${
        useInGroup && `ring-2 ring-white`
      }`}
    >
      <span
        className={`font-medium ${
          size === "small" ? `text-[12px]` : `text-sm`
        } text-gray-600 dark:text-gray-300`}
      >
        {name
          ? name?.length > 1
            ? `${name[0].toUpperCase()}${name[1].toUpperCase()}`
            : `${name[0].toUpperCase()}`
          : ""}
      </span>
    </div>
  );
}
