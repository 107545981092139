/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import config from "react-global-configuration";
import { AuthContext } from "../../context/authContext";
import i18next from "i18next";

import { useState, useContext, useEffect } from "react";
import axios from "axios";
// import { set } from "date-fns";
import moment from "moment";

import {
  Modal,
  MyDatePicker,
  PlaceHolder,
  MeetingRoomCard,
  HorizontalMeetingRoomCard,
  PaginationContainer,
  Button,
  DropDownContainer,
  // OrangeryTimeRange,
  OrangeryTimeSelector,
} from "../../components";

import { Calendar, ChevronLeft } from "react-iconly";

const generals = require("../../generals");

export function loadMeetingRooms(officeUuid, pickedDate, setMeetingRooms, accessToken) {
  // /office/{{office_uuid}}/meeting-room/24-02-2022
  let url = `${config.get("osApiUrl")}/office/${officeUuid}/meeting-room/${pickedDate}`;
  let configs = {
    headers: {
      "x-access-token": accessToken,
      timeout: config.get("apiCallTimeout"),
    },
    params: {
      // page: f + 1
    },
  };
  axios
    .get(url, configs)
    .then((response) => {
      let data = response.data?.data;
      let meetingRooms = data?.meeting_rooms?.filter(meetingRoom => meetingRoom.public);
      setMeetingRooms?.(meetingRooms ?? []);
    })
    .catch((error) => {
      console.warn(error.message);
    })
    .then(function () {
      // always executed
    });
}

export default function BookAMeetingRoomForm(props) {
  const { accessToken } = useContext(AuthContext);

  const [modalShow, setModalShow] = useState(false);
  const [modalText] = useState("");
  const [modalSubmitButtonText] = useState("");
  const [modalOnSubmit] = useState(() => { });

  const [waiting, setWaiting] = useState(false);

  const [pickedDate, setPickedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  // const [selectedPageIndex, setSelectedPageIndex] = useState(props.selectedPageIndex)
  // useEffect(() => {
  //   setSelectedPageIndex(props.selectedPageIndex)
  // }, [props.selectedPageIndex])

  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState(null);

  const [meetingRooms, setMeetingRooms] = useState(props.meetingRooms || []);

  useEffect(() => {
    setMeetingRooms(props.meetingRooms);
  }, [props.meetingRooms]);

  const [dropDownContainerShow, setDropDownContainerShow] = useState(false);

  // let myDatePickerRef = createRef();
  // let dropDownContainerRef = createRef();

  function dropDownContainerSetShow(show) {
    // dropDownContainerRef.current.setShow(show)
    setDropDownContainerShow(show);
    // eslint-disable-next-line react/no-direct-mutation-state
    // setDropDownContainerShow(show);
  }

  function onPickedDateChange(value) {
    setPickedDate(value);

    // dropDownContainerRef.current.setShow(false)
    // dropDownContainerSetShow(false)

    loadMeetingRooms(props.officeUuid, moment(value).format("DD-MM-YYYY"), setMeetingRooms, accessToken);
  }

  function toggleModal(show) {
    setModalShow(show);
  }

  function handleSubmit(e) {
    if (e !== null) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!error) {
      setWaiting(true);

      selectedMeetingRoom &&
        generals.goTo(
          // eslint-disable-next-line no-useless-concat
          `${config.get("osCheckoutUrl")}/meeting-room/?id=${selectedMeetingRoom.uuid
          }&from=${selectedMeetingRoom.time_slots[startIndex].repr +
          "-" +
          moment(pickedDate).format("DD-MM-YYYY")
          }&until=${selectedMeetingRoom.time_slots[endIndex].repr !== "23:00"
            ? selectedMeetingRoom.time_slots[endIndex + 2].repr +
            "-" +
            moment(pickedDate).format("DD-MM-YYYY")
            : "00:00" +
            "-" +
            moment(
              new Date(
                new Date(pickedDate).setDate(
                  new Date(pickedDate).getDate() + 1
                )
              )
            ).format("DD-MM-YYYY")
          }&start=${selectedMeetingRoom.time_slots[startIndex].repr}&end=${endIndex === selectedMeetingRoom.time_slots.length - 1 ||
            endIndex === selectedMeetingRoom.time_slots.length - 2
            ? "24:00"
            : selectedMeetingRoom.time_slots[endIndex + 2].repr
          }&returnUrl=${window.location.href}&l=${props.locationName}`
        );
    }
  }

  function onPickATimeButtonClick(pageIndex, value) {
    props.setSelectedPageIndex(pageIndex);
    setSelectedMeetingRoom(value);
  }

  function modalOnClose() {
    props.setSelectedPageIndex(0);
  }

  // const now = new Date();
  // const stepMinutes = 60;
  // let tempStart = {
  //   hour: now.getHours(),
  //   minutes: Math.ceil(now.getMinutes() / stepMinutes) * stepMinutes
  // }
  // const getTodayAtSpecificHour = (hour = 12, minutes = 0) =>
  //   set(now, { hours: hour, minutes: minutes, seconds: 0, milliseconds: 0 });
  // const [startIndex, setStartIndex] = useState(getTodayAtSpecificHour(tempStart.hour, tempStart.minutes))
  // const [endIndex, setEndIndex] = useState(getTodayAtSpecificHour(tempStart.hour, tempStart.minutes + stepMinutes))
  const [startIndex, setStartIndex] = useState(undefined);
  const [endIndex, setEndIndex] = useState(undefined);

  // function onSelectedHoursChange(values) {
  //   setStartIndex(values[0])
  //   setEndIndex(values[1])
  // }

  const [error, setError] = useState(false);

  const pages = [
    <div className="h-full pb-4 flex flex-col sm:flex-row">
      <div className="inline-block md:hidden px-0 pr-1 pb-4 basis-full">
        <DropDownContainer
          // ref={el => { dropDownContainerRef.current = el; }}

          show={dropDownContainerShow}
          setShow={dropDownContainerSetShow}
          icon={<Calendar set="bold" size={22} />}
          field={{
            label: "",
            id: "date",
            name: "date",
            type: "select",
            placeholder: pickedDate ? pickedDate : i18next.t("Select date"),

            valid: true,
            value: "",

            key: "date",
            trimmable: false,
            required: false,

            inputClassName:
              "text-left w-full h-12 rounded-lg pl-3 border border-border",
            menuClassName: "w-full h-12 rounded-lg",
            className: "w-full h-12",
          }}
        >
          <MyDatePicker
            // ref={el => { myDatePickerRef.current = el; }}

            onChange={onPickedDateChange}
            field={{
              value: pickedDate,
            }}
          />
        </DropDownContainer>
      </div>

      <div className="hidden md:inline-block basis-full px-4 max-w-xs">
        <MyDatePicker
          // ref={el => { myDatePickerRef.current = el; }}

          onChange={onPickedDateChange}
          field={{
            value: pickedDate,
          }}
        />
      </div>

      <div className="flex grow flex-wrap h-full overflow-y-auto gap-2 pr-1">
        {meetingRooms &&
          Array.isArray(meetingRooms) &&
          meetingRooms.length > 0 ? (
          meetingRooms.map((meetingRoom, index) => (
            <div key={index + moment().toString()} className={"basis-full"}>
              <MeetingRoomCard
                meetingRoom={meetingRoom}
                locationName={props.locationName}
                onPickATimeButtonClick={onPickATimeButtonClick}
              />
            </div>
          ))
        ) : (
          <PlaceHolder text={i18next.t("No meeting room available")} />
        )}
      </div>
    </div>,
    <div className="h-full">
      {props.selectedPageIndex > 0 && (
        <Button
          onClick={(e) =>
            props.setSelectedPageIndex(props.selectedPageIndex - 1)
          }
          className="hidden md:inline-flex w-8 h-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto items-center absolute left-4 top-6"
          text={<ChevronLeft set="light" size={22} />}
        />
      )}
      <div
        className="pl-0 md:pl-4 text-left flex flex-col overflow-y-auto pr-1"
        style={{
          height: "calc(100% - 80px)",
        }}
      >
        <div className="hidden md:flex flex-col space-y-4 basis-full p-2 w-full">
          {selectedMeetingRoom && (
            <HorizontalMeetingRoomCard
              meetingRoom={selectedMeetingRoom}
              selectedDate={pickedDate}
              noButton={true}
              daily={
                selectedMeetingRoom.daily_price &&
                (endIndex - startIndex) / 2 + 1 >=
                (selectedMeetingRoom.number_of_hours_to_daily_price
                  ? selectedMeetingRoom.number_of_hours_to_daily_price
                  : 4)
              } ////////!!!!!!!! /2!?
            />
          )}
        </div>

        <div className={"basis-full block md:hidden mb-3"}>
          <MeetingRoomCard
            meetingRoom={selectedMeetingRoom}
            locationName={props.locationName}
            onPickATimeButtonClick={onPickATimeButtonClick}
            selectedDate={pickedDate}
            noButton
          />
        </div>

        <div className="pl-2 mr-2 text-left flex flex-wrap">
          <p className="text-[#FF3D00] font-semibold text-[17px]">
            {i18next.t(
              "Please note that if you pick more than 3 hours, we consider your order a Full Day reservation."
            )}
          </p>
        </div>

        <div className="flex space-y-2 flex-col basis-full p-2 mt-2 pb-18">
          {/* <h3 className="text-base font-semibold">
            {i18next.t("Please select your time")}:
          </h3> */}
          <div className={"flex flex-1"}>
            {selectedMeetingRoom && (
              <div className="w-full border-none p-0 pb-2 overflow-x-auto overflow-y-hidden">
                <OrangeryTimeSelector
                  error={error}
                  setError={setError}
                  timeSlots={selectedMeetingRoom.time_slots}
                  startIndex={startIndex}
                  setStartIndex={setStartIndex}
                  endIndex={endIndex}
                  setEndIndex={setEndIndex}
                // onChange={onSelectedHoursChange}
                // selectedDate={new Date(pickedDate)}
                // disabledIntervals={this.state.disabledIntervals}
                />
                {/* <OrangeryTimeRange
                  onChange={onSelectedHoursChange}
                  error={error}
                  setError={setError}
                  selectedInterval={[startIndex, endIndex]}
                  // selectedDate={new Date("6/26/2022")}
                  timeSlots={selectedMeetingRoom.time_slots}
                /> */}
              </div>
            )}
          </div>
          <div className="w-full h-16 relative md:absolute bottom-0 right-0 mt-3 px-0 md:px-2 flex justify-endIndex bg-white z-[999]">
            <Button
              onClick={handleSubmit}
              className={`w-full md:w-[389px] h-12 text-black ${error
                  ? "cursor-not-allowed text-white bg-gray-500 hover:bg-gray-500"
                  : "cursor-pointer text-black bg-brand hover:bg-brand-900"
                } font-medium rounded mx-auto`}
              text={i18next.t("Next2")}
              waiting={waiting}
              disabled={error}
            />
          </div>
        </div>
      </div>
    </div>,
  ];

  return (
    <>
      <Modal
        id="modalMeetingRoom"
        text={modalText}
        show={modalShow}
        toggleModal={toggleModal}
        modalSubmitButtonText={modalSubmitButtonText}
        onSubmit={modalOnSubmit}
        onClose={modalOnClose}
      />
      <PaginationContainer
        pages={pages}
        selectedPageIndex={props.selectedPageIndex}
      />
    </>
  );
}
