export default function FeatureCard(props) {
  return (
    <div className="w-full">
      <div
        className={
          "relative bg-brandNew px-8 py-8 lg:px-2 xl:px-8 w-auto rounded flex flex-row text-left place-items-center h-28"
        }
      >
        <div className="mr-3 w-7 h-7">{props.feature.icon}</div>
        <div className="">
          <p className="text-base text-eighth whitespace-normal">
            {props.feature.label}
          </p>
        </div>
      </div>
    </div>
  );
}
