// import config from 'react-global-configuration';

import React, { useState, createContext } from "react";
// import axios from "axios";
import Cookie from 'js-cookie';

const cookieAccessTokenWildCard = `orangery-${process.env.REACT_APP_ENVIRONMENT}-access-token`;
const localStorageUseCookiesWildCard = `orangery-${process.env.REACT_APP_ENVIRONMENT}-use-cookies`;

function readCookie(cookieWildCard) {
  var thisCookieValue = null;

  var allCookies = Cookie.get();
  if (allCookies[cookieWildCard])
    thisCookieValue = allCookies[cookieWildCard];

  try {
    return JSON.parse(thisCookieValue)
  } catch (err) {
    return thisCookieValue
  }
}

const AuthContext = createContext();
export { AuthContext };

const AuthContextProvider = (props) => {
  // const [accessToken, setAccessToken] = useState(null);
  const [accessToken, ] = useState(readCookie(cookieAccessTokenWildCard));  ////////!!!!!!!! readCookie(cookieAccessTokenWildCard) // setAccessToken
  const [user, setUser] = useState(null)

  const [useCookies, setUseCookies] = useState(localStorage.getItem(localStorageUseCookiesWildCard))

  function writeUseCookies(useCookies) {
    setUseCookies(useCookies)
    localStorage.setItem(localStorageUseCookiesWildCard, useCookies);
  }

  // function isAuth(tempToken = null, referer = null) {
  //   tempToken = tempToken !== null ? tempToken : readCookie(cookieAccessTokenWildCard);

  //   let url = `${config.get('apiUrl')}/istokenvalid`;
  //   let data = {}
  //   let headers = {
  //     'x-access-token': tempToken,
  //     timeout: config.get('apiCallTimeout')
  //   }
  //   axios.post(url, data, { headers })
  //     .then((response) => {
  //       if (response.data.result) {
  //         setAccessToken(tempToken)

  //         url = `${config.get('apiUrl')}/getuserbytoken`;

  //         let configs = {
  //           headers: {
  //             'x-access-token': tempToken,
  //             timeout: config.get('apiCallTimeout')
  //           },
  //           params: {
  //             // page: f + 1
  //           },
  //         }
  //         axios.get(url, configs)
  //           .then((response) => {
  //             if (response.data.result)
  //               setUser(response.data.data);
  //           })
  //           .catch((error) => {
  //             console.error(error);
  //           })
  //           .then(function () {
  //             // always executed
  //           });
  //       } else {
  //         setAccessToken(null)
  //       }
  //     })
  //     .catch((error) => {
  //       setAccessToken(null)
  //       console.error(error);
  //     })
  // }

  // useEffect(() => {
  //   isAuth();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        accessToken: accessToken,
        user: user,
        setUser: setUser,
        // isAuth: isAuth,
        useCookies: useCookies,
        writeUseCookies: writeUseCookies,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
