import config from "react-global-configuration";
import { useTranslation } from "react-i18next";

import { Suspense, useEffect } from "react";
// import moment from 'moment';

import { DefaultLoader } from "../../components";

import Cookie from "js-cookie";

const cookieLanguageWildCard = `orangery-language`;

function readCookie(cookieWildCard) {
  var thisCookieValue = null;

  var allCookies = Cookie.get();
  if (allCookies[cookieWildCard]) thisCookieValue = allCookies[cookieWildCard];

  try {
    return JSON.parse(thisCookieValue);
  } catch (err) {
    return thisCookieValue;
  }
}

export default function ChangeLanguageButton() {
  const { i18n } = useTranslation();

  // const languages defines because i18n.languages has variable length when language changes!!!
  const languages = ["de", "en"];

  // i18n.on('languageChanged', function (lng) {
  //   moment.locale(lng);
  // });

  const changeLanguage = (lng) => {
    Cookie.set(cookieLanguageWildCard, lng, {
      domain: config.get("mainDomain"),
    });
    i18n.changeLanguage(lng);
  };

  function getLanguageButtons(languages, currentLanguage) {
    return languages.map((language) => (
      <button
        key={language}
        className={
          "px-2" +
          (language === currentLanguage ? " text-brandNew" : " text-white")
        }
        type="button"
        onClick={() => changeLanguage(language)}
      >
        {language.toUpperCase()}
      </button>
    ));
  }

  useEffect(() => {
    if (!readCookie(cookieLanguageWildCard))
      if (
        !i18n.language ||
        i18n.language === "de-DE" ||
        i18n.language === "en-US"
      )
        changeLanguage("de");
      else changeLanguage("en");
    else changeLanguage(readCookie(cookieLanguageWildCard));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<DefaultLoader />}>
      <div className="flex flex-row text-base uppercase divide-white divide-x-2">
        {getLanguageButtons(languages, i18n.language)}
      </div>
    </Suspense>
  );
}
