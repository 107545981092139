// const DefaultLoader = require("./components/utilities/defaultLoader")

const cdnUrl = process.env.REACT_APP_CDN_URL
const publicUrl = process.env.REACT_APP_PUBLIC_URL !== "." ? process.env.REACT_APP_PUBLIC_URL : "";
const apiUrl = process.env.REACT_APP_API_URL
const osApiUrl = process.env.REACT_APP_OS_API_URL
const osCheckoutUrl = process.env.REACT_APP_OS_CHECKOUT_URL
const appUrl = process.env.REACT_APP_APP_URL
const homeUrl = `${window.location.protocol}//${window.location.host}${publicUrl}`
const mainDomain = process.env.REACT_APP_MAIN_DOMAIN
const buildEnv = process.env.REACT_APP_BUILD_ENV
const hubSpotAccessToken = process.env.REACT_APP_HUBSPOT_ACCESS_TOKEN

module.exports = {
  cdnUrl: cdnUrl,
  apiUrl: apiUrl,
  osApiUrl: osApiUrl,
  osCheckoutUrl: osCheckoutUrl,
  baseUrl: publicUrl,
  homeUrl: homeUrl,
  appUrl: appUrl,
  mainDomain: mainDomain,
  buildEnv: buildEnv,
  hubSpotAccessToken: hubSpotAccessToken,

  orangery: {
    slogan: "we shape the way you work",
    address: "Am Alten Wasserwerk 1, 31135 Hildesheim",
    phone: "0800 - 00 55 0 77",
    official_email: "info@orangery.io",
    imprint: `${homeUrl}/impressum`,
    privacyPolicy: `${homeUrl}/privacy-policy`,
    termsAndConditions: `${homeUrl}/agb`,
  },

  apiCallTimeout: 8000,

  genders: [
    {
      value: "1",
      label: "male"
    },
    {
      value: "2",
      label: "female"
    },
  ],

  // openings: {
  //   1: "2022-06-01",
  //   2: "2022-06-01",
  //   8: "2022-07-01",
  //   9: "2022-09-01",
  // },

  defaults: {
    hours: [
      6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
    ],
    // user: {
    //   name: "",
    //   last_name: "",
    //   gender: "1",
    //   company: "",
    //   email: "",
    //   // password: "",
    //   tax_number: "",
    //   address: "",
    //   created_at: "2011/4/10",
    //   file: {
    //     filename: "",
    //     // src: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    //   },
    //   location: {
    //     name: ""
    //   },
    // },
    // file: {
    //   filename: "",
    //   src: DefaultLoader
    // },
    // plan: {
    //   id: -1,
    //   active: null,
    //   officeplan: {
    //     id: -1,
    //     file: {
    //       filename: "",
    //       src: DefaultLoader
    //     },
    //     location_id: -1,
    //   }
    // },
    // locationInfo: {
    //   spacephotos: [
    //     {
    //       file: {
    //         src: DefaultLoader
    //       }
    //     }
    //   ],
    //   communitymanager: {
    //     name: "",
    //     last_name: "",
    //     file: {
    //       src: DefaultLoader
    //     }
    //   }
    // }
  }
};
