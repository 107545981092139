import { useEffect, useState } from "react";

export default function ModalContainer({
  show: outerShow,
  toggleShow: outerToggleShow,
  onClose,
  id,
  className,
  title,
  containerClassName,
  children
}) {

  const [show, setShow] = useState(outerShow);
  useEffect(() => {
    setShow(outerShow)
  }, [outerShow])

  const toggleShow = (show) => {
    outerToggleShow?.(show);

    setShow(show);
  }

  const handleClose = e => {
    toggleShow(false)

    onClose?.()

    setShow(false);
  }

  return (
    <div className='absolute z-100 place-items-center'>
      <div id={`${id}-backdrop`} className={(show ? "flex " : "hidden ") + "bg-gray-900 bg-opacity-50 fixed inset-0 z-20"}></div>
      <div
        id={id}
        className={(show ? "flex " : "hidden ")
          + "overflow-y-auto overflow-x-hidden my-auto mx-auto fixed right-0 left-0 top-4 z-30 justify-center items-center md:inset-0 h-modal"
          + (className ? " " + className : "")}
        aria-hidden="true"
      >
        <div className="w-full md:w-fit max-h-full relative px-4">
          <div className="pl-4 mp:pl-0 relative bg-white rounded shadow">
            <div className="flex justify-between p-2 pl-0 md:pl-10">
              {title
                ? <h1 className="py-2 md:py-4 text-left text-lg font-bold text-gray-900 w-full">{title}</h1>
                : <></>
              }
              <button onClick={handleClose} type="button" className="absolute top-2 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle={id}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
            <div className={"text-center " + (containerClassName ?? "")}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}